import { Add, Search } from '@mui/icons-material'
import { Avatar, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputBase, InputLabel, MenuItem, Modal, OutlinedInput, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import backendUrl from '../../configmain';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Zoom } from '@mui/material';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Key from '@mui/icons-material/VpnKeyOutlined';
import Delete from '@mui/icons-material/DeleteOutlineOutlined';
import Person from '@mui/icons-material/PersonOutlineOutlined';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import toast, { Toaster } from 'react-hot-toast';
import CancelIcon from '@mui/icons-material/Cancel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const headers = ['Serial', 'Avatar', 'Name', 'Email', 'FB id', 'Status', 'Action', 'Expiry Date', 'LastLogin', 'last ip'];
const url = `${backendUrl}/api/user`;
const updateUrl = `${backendUrl}/api/user/update`
const deleteUrl = `${backendUrl}/api/user/delete`

export default function UserManager() {
  const [userData, setUserData] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showComponentA, setShowComponentA] = useState(true);
  const [personName, setPersonName] = React.useState([]);
  const [expiryDate, setExpiryDate] = useState(null);
  const [dataFetched, setDataFetched] = useState(false); // Track whether data was a
  const [id, setId]=useState("")

  // console.log(id)
  

  let token = localStorage.getItem('authorization');


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url
          , {
            headers: {
              Authorization: `${token}`,
            },
          })

          // console.log(response.data)
        if (response) {
          setUserData(response?.data?.data);

        } else {
          alert("NOT fetched")
        }
        if (response.data.status === true) {
          setDataFetched(true)
        }

      } catch (error) {
        console.error(error);
        // Handle the error here
      }
    };

    fetchData();
  }, []);

  // useEffect(()=> {console.log(isChecked)}, [isChecked])

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = (d) => {
    console.log(d._id)
    setId(d._id)
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const passwordUpdateUrl = `${backendUrl}/api/user/password`
  const handleUpdatePassword = async () => {
    const data = {
      currentPassword: currentPassword,
      password: newPassword,
    };
    try {
      const response = await axios.put(`${passwordUpdateUrl}/${id}`, data, {
        headers: {
          Authorization: `${token}`,
        },
      });
      if(response.status === 200){
        setModalOpen(false)
        setDialogOpen(false);
        toast.success(`Password updated successfully:`);
      }
        
     
    } catch (error) {
      toast.error(`Failed to update password. ${error}`, { position: 'bottom-center' });
      console.error(error);
      setModalOpen(false)
    }
  };
  const [isDialogOpen, setDialogOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleUpdateUser = () => {
    // handleCloseModal()
    axios
      .put(`${updateUrl}/${selectedUser._id}`, { ...selectedUser, expiryDate: expiryDate, }, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then(response => {
        toast.success(`User details updated`, { duration: 4000 })
        console.log('User details updated:', response.data);
        setIsModalOpen(false);
        window.location.reload();
      })
      .catch(error => {
        toast.error(`Error updating user details : ${error}`)
        console.error('Error updating user details:', error);
      });
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleDeleteUser = () => {
    axios.delete(`${deleteUrl}/${selectedUser._id}`, {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then(response => {
        toast.success(`User Deleted`);
        // console.log('User details updated:', response.data);
        setUserData(prevCampaigns => prevCampaigns.filter(campaign => campaign._id !== selectedUser._id));
        setIsModalOpen(false);
        setDialogOpen(false);
      })
      .catch(error => {
        toast.error(`User not deleted`, error);
        console.error('Error updating user details:', error);
      });
  };
  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleUserChange = () => { }
  const toggleComponent = () => {
    setShowComponentA((prevShowComponentA) => !prevShowComponentA);
  };


  return (
    <>
      {dataFetched ? (
        <Box >
          <Box sx={{ p: 1, overflow: "hidden", }}>
            <Box sx={{ mb: 2, mt: 2}} >
              <Box sx={{ display: "flex", justifyContent: "flex-start", textAlign: "center", alignItems: "center" }}>
                <Box sx={{ display: "flex", gap: 3, textAlign: "center", alignItems: "center", }}>
                  <Box sx={{ display: "flex" }}>
                    <div style={{
                      display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '4px', '&:focus-within': {
                        borderColor: "#279d85",
                      },
                    }}>
                      <Box
                        component="form"
                        style={{
                          p: '2px 4px', display: 'flex', alignItems: 'center', width: 300,


                        }}>
                        <InputBase size="small"

                          sx={{ ml: 1, flex: 1, }}
                          placeholder="search"
                          onChange={handleUserChange} />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" >
                          <Search />
                        </IconButton>
                      </Box>
                    </div>
                  </Box>
                  <Box>
                    <Link to="/addUser">
                      <Button className="" variant="contained" style={{ borderRadius: '50px', backgroundColor: "#279d85", }}>
                        <Add/> Add New User
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{


                mt: 3,

              }}
            >

              <TableContainer component={Paper}
                sx={{
                  "::-webkit-scrollbar": { display: 'block' }, "&::-webkit-scrollbar": {
                    height: '.4rem'
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "RGB(217 240 240)"
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#279d85",
                    borderRadius: 2
                  },

                }}
              >
                <Table >
                  <TableHead sx={{ background: "#111827", color: "#fff" }}>
                    <TableRow sx={{ color: "#fff" }}>
                      {headers.slice(1).map((header, index) => (
                        <TableCell sx={{ textAlign: "center" }} key={index}>
                          <Typography sx={{ fontWeight: "bold", margin: 'auto', color: "#fff"}}>
                            {header}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody  >
                    {userData && userData.map((item, index) => (
                      <TableRow key={index} tabIndex={-1}>
                        <TableCell >
                          <Avatar
                            alt={item.name}
                            src="/path-to-your-avatar-image.jpg"
                            sx={{ margin: "auto" }}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center" }}  >{item.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center" }}>{item.email}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center" }}>{item.facebookUserId}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center" }}> {item.isActive === true ? <Typography sx={{ color: "green" }}>Active</Typography> : <Typography sx={{ color: "red" }}>Inactive</Typography>}</Typography>
                        </TableCell>

                        <TableCell sx={{ textAlign: "center" }}>
                          <Typography sx={{ textAlign: "center" }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, textAlign: "center" }}>
                              <Tooltip title="Edit" arrow TransitionComponent={Zoom} onClick={() => handleEditClick(item)}>
                                <Box sx={{cursor:"pointer" }}>
                                  <EditOutlinedIcon />
                                </Box>
                              </Tooltip>
                              <Tooltip title="Change Password" arrow TransitionComponent={Zoom}>
                                <Box sx={{cursor:"pointer" }} onClick={() => handleOpenModal(item)}>
                                  <Key />
                                </Box>
                              </Tooltip>

                              <Tooltip title="Delete" arrow TransitionComponent={Zoom} onClick={() => handleDeleteClick(item)}>
                                <Box sx={{cursor:"pointer"}}>
                                  <Delete />
                                </Box>
                              </Tooltip>
                              {/* <Tooltip title="Activity" arrow TransitionComponent={Zoom}>
                                <Box sx={{cursor:"pointer" }}>
                                  <Person />
                                </Box>
                              </Tooltip> */}
                            </Box>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center", justifyContent: "center", }}>
                            {item.expiryDate
                              ? new Date(item.expiryDate).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                              : 'No packages'}
                          </Typography>

                        </TableCell>
                        <TableCell>
                          <Typography sx={{ textAlign: "center", justifyContent: "center", }}>
                            {item.lastLoginAt
                              ? new Date(item.lastLoginAt).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                              : 'User has not logged in'}
                          </Typography>

                        </TableCell>
                        <TableCell >
                          <Box sx={{ textAlign: "center", justifyContent: "center", }}>
                            <Typography sx={{ textAlign: "center" }}>{item.lastLoginIp}</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

          </Box>
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}>
            <Box sx={{ borderRadius:"20px ", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "50%", bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
              {selectedUser && (
                <Box sx={{ p: 2 }}>
                  <Box
                    sx={{
                      p: 2,
                      alignItems: "center",
                      "& > :not(style)": { m: 1 },
                      width: "100%",
                    }}>

                    <Box >
                      <Typography variant='h5' style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}> Edit user details </Typography>
                    </Box>
                    <Box>
                      <TextField
                        size='small'
                        fullWidth
                        helperText=" "
                        id="demo-helper-text-aligned-no-helper"
                        label="Full name"
                        value={selectedUser.name}
                        onChange={(e) => setSelectedUser({ ...selectedUser, name: e.target.value })}
                        sx={{
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#279d85",
                          },
                        }}

                        InputLabelProps={{
                          sx: {
                            '&.Mui-focused': {
                              color: '#279d85',

                            },
                          },
                        }}

                      />
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <TextField
                        size='small'
                        sx={{
                          flex: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#279d85",
                          },
                        }}

                        InputLabelProps={{
                          sx: {
                            '&.Mui-focused': {
                              color: '#279d85',

                            },
                          },
                        }} // Use mr: 1 to add horizontal margin (gap) between the input fields
                        fullWidth
                        helperText=" "
                        id="demo-helper-text-aligned-no-helper"
                        label="Email"
                        value={selectedUser.email}
                        onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                      />
                      <Box sx={{ width: 20 }} />
                      <TextField
                        size='small'
                        sx={{
                          flex: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#279d85",
                          },
                        }}

                        InputLabelProps={{
                          sx: {
                            '&.Mui-focused': {
                              color: '#279d85',

                            },
                          },
                        }} // Use flex: 1 to make the input fields share equal width
                        fullWidth
                        helperText=" "
                        id="demo-helper-text-aligned-no-helper"
                        label="Mobile"
                        value={selectedUser.mobile}
                        onChange={(e) => setSelectedUser({ ...selectedUser, mobile: e.target.value })}
                      />
                    </Box>
                    <Box>
                      <TextField
                        size='small'
                        fullWidth
                        helperText=" "
                        id="demo-helper-text-aligned-no-helper"
                        label="Address"
                        value={selectedUser.address}
                        onChange={(e) => setSelectedUser({ ...selectedUser, address: e.target.value })}
                        sx={{
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#279d85",
                          },
                        }}

                        InputLabelProps={{
                          sx: {
                            '&.Mui-focused': {
                              color: '#279d85',

                            },
                          },
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography> User Type </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={showComponentA}
                                onChange={toggleComponent}
                                defaultChecked
                                size="medium"
                                name="Member"
                              />
                            }
                            label="Member" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!showComponentA}
                                onChange={toggleComponent}
                                defaultChecked
                                size="medium"
                                name="Admin"
                              />
                            }
                            label="Admin"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControlLabel
                            control={<Switch defaultChecked size="medium" name="Active" />}
                            label="Active"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {showComponentA ?
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormControl sx={{
                              width: "100%", '& .MuiInputLabel-root.Mui-focused': {
                                color: '#279d85',
                              },
                              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#279d85",
                              },
                            }}>
                              <InputLabel size='small' id="demo-multiple-name-label">
                                Package
                              </InputLabel>
                              <Select
                                size='small'
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                value={personName}
                                onChange={handleChange}
                                input={<OutlinedInput label="Name" />}
                                MenuProps={MenuProps}
                              >
                                {names.map((name) => (
                                  <MenuItem
                                    size='small'
                                    key={name}
                                    value={name}

                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                sx={{
                                  width: "100%", '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#279d85',
                                  },
                                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#279d85",
                                  },
                                }}
                                label="Expiry Date"
                                slotProps={{ textField: { size: "small" } }}
                                value={expiryDate}
                                onChange={(newValue) => {
                                  setExpiryDate(newValue);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Box>
                      : null}
                    <Box sx={{ display: "flex", justifyContent: "space-between" }} >
                      <Button variant="outlined"
                       color="success" onClick={handleUpdateUser}>
                        Update
                      </Button>
                      <Button 
                       variant="outlined"
                       color="error"
                      //  startIcon=
                       onClick={handleCloseModal}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>
          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "30%",
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,

               borderRadius:"20px"
              }}
            >
              <Box>
                <Typography variant='h5' style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}> Change your password </Typography>
              </Box>
              <Box sx={{ mt: 3, }}>
                <TextField
                  size='small'
                  fullWidth
                  helperText=" "
                  label="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}

                  sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },
                }}

                InputLabelProps={{
                  sx: {
                    '&.Mui-focused': {
                      color: '#279d85',

                    },
                  },
                }}


                />
                <TextField
                  size='small'
                  fullWidth
                  helperText=" "
                  label="New password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}

                  sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },
                }}

                InputLabelProps={{
                  sx: {
                    '&.Mui-focused': {
                      color: '#279d85',

                    },
                  },
                }}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                <Box>
                  <Button variant="outlined"
                       color="success" onClick={handleUpdatePassword} sx={{ mt: 3 }}>
                    Update
                  </Button>
                </Box>
                <Box>
                  <Button 
                  variant="outlined"
                       color="error" 
                       onClick={closeModal} sx={{ mt: 3 }}>
                    Close
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal >
          {/* Confirmation Dialog */}
          <Dialog sx={{ borderRadius:"20px"}} open={isDialogOpen} onClose={handleCloseDialog} >
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this user?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              

              <Button  variant="outlined"
                       color="error"  onClick={handleCloseDialog} >
                Cancel
              </Button>
              <Button
               variant="outlined" 
               onClick={handleDeleteUser}>
                Delete
              </Button>
             
            </DialogActions>
          </Dialog >
        </Box>
      )
        : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              alignItems: 'center',    // Center vertically
              height: '100vh',        // Set a specific height for centering vertically
            }}
          >
            <CircularProgress disableShrink />
          </Box>
        )
      }
    </>
  )
}
