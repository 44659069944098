import {
  Typography,
  Box,
  TextField,
  Switch,
  FormControlLabel,
 
} from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

import toast, { Toaster } from "react-hot-toast";

// import useState from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LinkIcon from "@mui/icons-material/Link";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import VideocamIcon from "@mui/icons-material/Videocam";
import Paper from "@mui/material/Paper";
import ListItemText from '@mui/material/ListItemText';
import { Link } from "react-router-dom"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Button } from "@mui/material";
// import TelegramIcon from "@mui/icons-material/Telegram";
import CancelIcon from '@mui/icons-material/Cancel';
import Checkbox from "@mui/material/Checkbox";
// import Autocomplete from "@mui/material/Autocomplete";
import { Input } from '@mui/material';

import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import backendUrl from "../../../../src/configmain";

const pagesUrl = '/api/user/getPages ';
const urlAutoreply = '/api/autoReplyTemplate/getAllTemplate';

const token = localStorage.getItem('authorization');


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const TabPanel = ({ children, value, index }) => {
  return (
    <div hidden={value !== index}>
      {value === index && <Typography component="div">{children}</Typography>}
    </div>
  );
};

const timeZones = [
  { value: '', label: 'None' },
  { value: 'Etc/GMT+1', label: 'GMT +1:00' },
  { value: 'Etc/GMT+2', label: 'GMT +2:00' },
  { value: 'Etc/GMT+3', label: 'GMT +3:00' },
  { value: 'Etc/GMT+4', label: 'GMT +4:00' },
  { value: 'Etc/GMT+5', label: 'GMT +5:00' },
  { value: 'Etc/GMT+6', label: 'GMT +6:00' },
  { value: 'Etc/GMT+7', label: 'GMT +7:00' },
  { value: 'Etc/GMT+8', label: 'GMT +8:00' },
  { value: 'Etc/GMT+9', label: 'GMT +9:00' },
  { value: 'Etc/GMT+10', label: 'GMT +10:00' },
  { value: 'Etc/GMT+11', label: 'GMT +11:00' },
  { value: 'Etc/GMT+12', label: 'GMT +12:00' },
  { value: 'Etc/GMT+13', label: 'GMT +13:00' },
];
export default function CreateNewMultimediaPost() {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectPages, setSelectPages] = React.useState("")
  const [pageOptions, setPageOptions] = React.useState("")
  const [autoReply, setAutoReply] = React.useState("")
  const [selectedDateTime, setSelectedDateTime] = React.useState(dayjs("2022-04-17T15:30"));
  const [isSwitchOn, setIsSwitchOn] = React.useState(true);
  const [personName, setPersonName] = React.useState([]);
  const [messageContent, setMessageContent] = React.useState("");
  const [pasteLink, setPasteLink] = React.useState("");
  const [fileLink, setFileLink] = React.useState('');
  const [selectedTimeZone, setSelectedTimeZone] = React.useState("")
  const [timeInterval, setTimeInterval] = React.useState("")
  const [postReport, setPostReport] = React.useState("")
  const [mediaType, setMediaType] = React.useState("")
  const [imageUrl, setImageUrl] = React.useState("")
  const [socialMedia, setSocialMedia] = React.useState('facebook');
  const [videoUrl, setVideoUrl] = React.useState("")
  const [tabData, setTabData] = React.useState("");
  const [imgUrl, setImgUrl] = React.useState("");
  const [vdoUrl, setVdoUrl] = React.useState("");
  const [campaignName, setCampaignName] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [isCapmNameErr, setIscampNameErr]=React.useState(false)
  const [isMsgErr, setIsMsgErr]= React.useState(false)



  const navigate = useNavigate("")


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (newValue === 0) {
      setTabData("Text post");
    } else if (newValue === 1) {
      setTabData("Link post");
    } else if (newValue === 2) {
      setTabData("Image post");
    } else if (newValue === 3) {
      setTabData("Video post");
    }
  };
  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  const handleAutoReply = (event) => {
    setAutoReply(event.target.value)
  }
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handlePageChange = (event) => {
    setSelectPages(event.target.value)
  }
  React.useEffect(() => {
    const headers = {
      'Authorization': `${token}`,
      'Content-Type': 'application/json',
    };
    // Fetch data using Axios from your API (replace with your actual API endpoint)
    axios.get(`${backendUrl}${pagesUrl}`, {
      headers
    })
      .then(response => {
        // console.log(response.data)
        setPageOptions([response.data.getPage]);

      })
      .catch(error => {
        console.error('Error fetching data:', error);

      });
  }, []);
  // console.log(pageOptions)

  const handleCancelClick = () => {
    navigate(-1)
  }

  // auto reply  api 

  React.useEffect(() => {
    const headers = {
      'Authorization': `${token}`,
      'Content-Type': 'application/json',
    };
    axios.get(`${backendUrl}${urlAutoreply}`, {
      headers
    })
      .then(response => {
        // console.log(response?.data?.data)
        setAutoReply(response?.data?.data)

      })
      .catch(error => {
        console.error('Error fetching page options:', error);
      });
  }, [])
  // console.log(autoReply[0]?.pageName)
  // select pages
  const [checked, setChecked] = React.useState([true, false]);

  const children = pageOptions && pageOptions.map((child, index) => (
    <FormControlLabel
      key={index}
      label={child.name}
      control={
        <Checkbox checked={child.checked} />
      }
    />
  ));
  const handleCampaignNameChange = (event) => {
    setCampaignName(event.target.value);
    setIscampNameErr(false)
  };
  const handleMessageChange = (event) => {
    setIsMsgErr(false)
    setMessageContent(event.target.value)
  }
  const handleDateChange = (newDate) => {
    setSelectedDateTime(newDate);
    // console.log("Selected Date:", newDate.format());
  };
  const handleTimeZoneChange = (event) => {
    const newValue = event.target.value;
    setSelectedTimeZone(newValue);
    // Display the selected value on the console
    // console.log("Selected Time Zone:", newValue);
  };

  const handleReportChange = (event) => {
    const newValue = event.target.value;
    setPostReport(newValue);
    // Display the input value on the console
    // console.log("Input Value:", newValue);
  };

  const handleTimeChange = (event) => {
    const newValue = event.target.value;
    setTimeInterval(newValue);
    // Display the input value on the console
    // console.log("Input Value:", newValue);
  };
  const handleUrlMediaType = (event) => {
    setImgUrl(event.target.value)
    setVdoUrl(event.target.value)
  }

  const handleImageChange = (event) => {
    setImageUrl(event.target.value)
  }

  const handleVideoChange = (event) => {
    setVideoUrl(event.target.value)
  }
  // ==============upload image videos =================
  const handleImgFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = () => {
    const formData = new FormData();
    let token = localStorage.getItem("authorization");
    let headers = {
      Authorization: token,
    };

    formData.append('file', selectedFile);
    axios.post(`${backendUrl}/api/user/upload`, formData, { headers })
      .then(response => {
        const uploadedLink = response.data.link
        setFileLink(uploadedLink);
      
      })
      .catch(error => {
        // console.error('Error uploading file:', error);
      });
  };
  const handleTabPanelSubmit = () => {
    const token = localStorage.getItem('authorization');
    const apiUrl = '/api/multimedia/create';

    if(!campaignName){
      setIscampNameErr(true)
      return toast.error("please fill the campaign name !")
    }
    
    if(!messageContent){
      setIsMsgErr(true)
      return toast.error("please Enter Message !")
    }

    const formData = {
      campaignName: campaignName,
      messege: messageContent,
      secheduledTime: selectedDateTime,
      timezone: selectedTimeZone,
      repostThisPost: postReport,
      timeInterval: timeInterval,
      mediaType: tabData,
      platform: socialMedia,
      postingTime: isSwitchOn,
      imageUploadUrl: fileLink,
      videoUploadUrl: fileLink
    }
    console.log(formData)
    const headers = {
      'Authorization': `${token}`,
      'Content-Type': 'application/json',
    };
    axios.post(`${backendUrl}${apiUrl}`, formData, {
      headers
    })
      .then((response) => {
        // Process the API response data
        // console.log("API Response:", response.data);
        // console.log(response.data.success)
        if (response.data.success == true) {
          // window.location.reload();
          // navigate(-1)
           console.log(response)
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        toast.error(error.message)
      });
  };


  const handleSwitchChangefb = (event) => {
    const newSocialMedia = event.target.checked ? 'instagram' : 'facebook';
    setSocialMedia(newSocialMedia);
    // console.log(`Selected social media: ${newSocialMedia}`);
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Toaster/>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", }} style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <TelegramIcon />
              Multimedia Posting
            </Typography>
          </Box>


        </Box>
        <Box sx={{ p: 2 }}>
          <Grid container>
            <Grid item xs={3} md={3}>
              <Paper square sx={{ p: 2 }}>
                <Box >
                  <Typography
                    variant="body1"
                    component="div"
                    display="flex"
                    alignItems="center"
                    sx={{ color: "#279d85", fontWeight: "bold" }}
                  >
                    <ReplyIcon sx={{ marginRight: "0.5rem" }} />
                    Publish
                  </Typography>
                  <Box mt={2}>
                    <TextField
                      placeholder="search..."
                      sx={{
                        width: "100%", mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: "#279d85",
                        },
                      }}
                      size="small"
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography >Auto reply template</Typography>
                    <FormControl size="small" variant="outlined" sx={{
                      width: "100%", mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: "#279d85",
                      },
                    }}>
                      <Select labelId="select-label-2" id="select-2" value={autoReply} onChange={handleAutoReply}>
                        <MenuItem value="">Select</MenuItem>
                        {Array.isArray(autoReply) && autoReply.length > 0 ? (
                          autoReply.map((item, index) => (
                            <MenuItem key={index} value={item.messegeForCommentReply}>
                              {item.messegeForCommentReply}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="no-auto-reply">No Auto Reply Available </MenuItem>
                        )}
                      </Select>
                    </FormControl>

                    <div>
                      <FormControlLabel
                        label="Select Page"
                        control={
                          <Checkbox />
                        }
                      />
                      {children}
                    </div>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={6} md={6}>
              <Paper square sx={{ p: 1 }}>
                <Box>
                  <Tabs value={selectedTab} onChange={handleTabChange} sx={{
                    borderColor: 'transparent', // Change the bottom line color
                    // Control letter casing of tab labels
                  }}>
                    <Tab
                      label={
                        <Typography variant="subtitle1" style={{ display: "flex", alignItems: "center", gap: "3px", fontSize: "15px", color: selectedTab === 0 ? '#279d85' : 'black', }}>
                          <TextSnippetIcon />
                          <strong>Text</strong>
                        </Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography variant="subtitle1" style={{ display: "flex", alignItems: "center", gap: "3px", fontSize: "15px", color: selectedTab === 1 ? '#279d85' : 'black' }}>
                          <LinkIcon />
                          <strong>Link</strong>
                        </Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography variant="subtitle1" style={{ display: "flex", alignItems: "center", gap: "3px", fontSize: "15px", color: selectedTab === 2 ? '#279d85' : 'black', }}>
                          <CropOriginalIcon />
                          <strong>Image/Carousel</strong>
                        </Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography variant="subtitle1" style={{ display: "flex", alignItems: "center", gap: "3px", fontSize: "15px", color: selectedTab === 3 ? '#279d85' : 'black', }}>
                          <VideocamIcon />
                          <strong>Video</strong>
                        </Typography>
                      }
                    />
                  </Tabs>
                  <Box sx={{ p: 2 }}>
                    <TabPanel value={selectedTab} index={0}>
                      <Box>
                        <Typography>Campaign Name </Typography>
                        <TextField sx={{
                          mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: isCapmNameErr ? "red" : "#279d85",

                          },
                          border : isCapmNameErr ? "1px solid red" :"",
                          borderRadius: isCapmNameErr ? "5px" :"",
                        }} size="small" fullWidth value={campaignName}
                          onChange={handleCampaignNameChange} />

                      </Box>
                      {
                        isCapmNameErr ? (<Typography sx={{color:"red", fontSize:"13px"}} >Please enter campaign name !</Typography>):("")
                      }
                      <Box sx={{ mt: 2 }}>
                        <Typography>Text</Typography>
                        <TextField
                          sx={{
                            mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: isMsgErr ? "red" : "#279d85",
                            },

                            border : isMsgErr ? "1px solid red" :"",
                            borderRadius: isMsgErr ? "5px" :"",
                          }}
                          fullWidth
                          multiline
                          rows={4}
                          placeholder="message "
                          value={messageContent}
                          onChange={handleMessageChange}
                        />
                      </Box>
                      {
                        isMsgErr ? (<Typography sx={{color:"red", fontSize:"13px"}} >Please enter message !</Typography>):("")
                      }
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                      <Box>
                        <Typography>Campaign name</Typography>
                        <TextField sx={{
                          mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#279d85",
                          },
                        }} size="small" fullWidth value={campaignName}
                          onChange={handleCampaignNameChange} />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Typography>Text</Typography>

                        <TextField
                          sx={{
                            mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: "#279d85",
                            },
                          }}
                          fullWidth
                          multiline
                          rows={4}
                          placeholder="message "
                          value={messageContent}
                          onChange={handleMessageChange}
                        />
                      </Box>
                      <Box mt={2}>
                        <Typography>Paste Link</Typography>
                        <TextField sx={{
                          mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#279d85",
                          },
                        }} size="small" fullWidth />
                      </Box>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                      <Box sx={{ display: "flex" }}>

                        <MenuItem component={Link} role="button" variant="outlined" sx={{ border: "1px solid #D3D3D3" }}>
                          <ListItemText primary="Paste Url" />
                        </MenuItem>
                        <FormControl fullWidth size="small" sx={{
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#279d85",
                          },
                        }}>
                          <TextField size="small" fullWidth
                            value={imgUrl}
                            onChange={handleUrlMediaType}
                          />
                        </FormControl>
                        <MenuItem component={Link} role="button" variant="outlined" sx={{ border: "1px solid #D3D3D3" }}>
                          <ListItemText primary="lg media manual " />
                        </MenuItem>
                      </Box>
                      <Box mt={1}>
                        <Box>
                          <Input type="file"
                            onChange={handleImgFileChange}
                          />
                          <Button style={{
                            color: '#279d85',
                            borderColor: '#279d85',
                          }} variant="outlined"
                            onClick={handleUpload}
                          >Upload</Button>
                          {fileLink && <p>Uploaded File Link: {fileLink}</p>}
                        </Box>
                      </Box>
                      <Box mt={2}>
                        <Box>
                          <Typography>Campaign name</Typography>
                          <TextField sx={{
                            mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: "#279d85",
                            },
                          }} size="small" fullWidth value={campaignName}
                            onChange={handleCampaignNameChange} />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <Typography>Text</Typography>
                          <TextField
                            sx={{
                              mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#279d85",
                              },
                            }}
                            fullWidth
                            multiline
                            rows={4}
                            placeholder="message "
                            value={messageContent}
                            onChange={handleMessageChange}
                          />
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={3}>
                      <Box sx={{ display: "flex" }}>
                        <MenuItem component={Link} role="button" variant="outlined" sx={{ border: "1px solid #D3D3D3" }}>
                          <ListItemText primary="Paste Url" />
                        </MenuItem>

                        <FormControl fullWidth size="small">
                          <TextField size="small" fullWidth sx={{
                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: "#279d85",
                            },
                          }}
                            value={vdoUrl} onChange={handleUrlMediaType}
                          />

                        </FormControl>
                      </Box>
                      <Box mt={1}>
                        {/* <Input
                          value={videoUrl}
                          onChange={handleVideoChange}
                          type="file">
                        </Input> */}
                        <Box>
                          <Input type="file"
                            onChange={handleImgFileChange}
                          />
                          <Button style={{
                            color: '#279d85',
                            borderColor: '#279d85',
                          }} variant="outlined"
                            onClick={handleUpload}
                          >Upload</Button>
                          {fileLink && <p  style={{color:"green"}} >File Uploaded {fileLink}</p>}
                        </Box>
                      </Box>
                      <Box mt={2}>
                        <Box>
                          <Typography>Campaign name</Typography>
                          <TextField sx={{
                            mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: "#279d85",
                            },
                          }} size="small" fullWidth value={campaignName}
                            onChange={handleCampaignNameChange} />
                        </Box>

                        <Box sx={{ mt: 2 }}>
                          <Typography>Text</Typography>

                          <TextField
                            sx={{
                              mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#279d85",
                              },
                            }}
                            fullWidth
                            multiline
                            rows={4}
                            placeholder="message "
                            value={messageContent}
                            onChange={handleMessageChange}
                          />
                        </Box>
                      </Box>
                    </TabPanel>
                    <Box mt={2}>
                      <Typography>Posting time</Typography>

                      <FormControlLabel
                        control={
                          <Switch
                            style={{
                              color: '#279d85',
                              // Color: '#279d85',
                            }}
                            checked={isSwitchOn}
                            onChange={handleSwitchChange}
                          />
                        }
                        label="Post later "
                      />
                      {isSwitchOn && (
                        <Box>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Box sx={{ width: "50%" }}>
                              <Typography>Schedule time</Typography>
                              <FormControl size="small" sx={{
                                width: "100%", '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: "#279d85",
                                },
                              }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={[
                                      "DateTimePicker",
                                      "MobileDateTimePicker",
                                      "DesktopDateTimePicker",
                                      "StaticDateTimePicker",
                                    ]}
                                  >
                                    <DemoItem>
                                      <MobileDateTimePicker
                                        size="small"
                                        defaultValue={dayjs("2022-04-17T15:30")}
                                        value={setSelectedDateTime}
                                        onChange={handleDateChange}
                                      />
                                    </DemoItem>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </FormControl>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                              <Typography> Time Zone</Typography>

                              <FormControl sx={{ width: "100%", mt: 1 , '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                                <FormControl fullWidth variant="outlined">

                                  <Select
                                    id="gmt-timezone"
                                    value={selectedTimeZone}
                                    onChange={handleTimeZoneChange}
                                  >
                                    {timeZones.map((timezone) => (
                                      <MenuItem
                                        key={timezone.value}
                                        value={timezone.value}
                                      >
                                        {timezone.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </FormControl>
                            </Box>
                          </Box>

                          <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                            <Box sx={{ width: "50%" }}>
                              <Typography>Repost this post</Typography>

                              <FormControl
                              sx={{ width: "100%", mt: 1 ,'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#279d85",
                              },}}
                                size="small"
                                variant="outlined"
                              >
                                <TextField
                                  size="small"
                                  type="number"
                                  variant="outlined"
                                  value={postReport}
                                  onChange={handleReportChange}
                                  inputProps={{
                                    step: 1,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        Times
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </FormControl>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                              <Typography>Time interval</Typography>
                              <FormControl
                                size="small"
                                sx={{ width: "100%", mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: "#279d85",
                                },}}
                              >
                                <Select value={timeInterval}
                                  onChange={handleTimeChange}>
                                  <MenuItem value="every 1 months">every 1 months</MenuItem>
                                  <MenuItem value="every 2 months">every 2 months</MenuItem>
                                  <MenuItem value="every 6 months">every 6 months</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                      
                      <Button
                        variant="outlined"
                        color="error"
                     
                        onClick={handleCancelClick}>
                           <CancelIcon  style={{ marginRight:"7px", fontSize:'20px'}}/>
                        cancel
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        startIcon={<TelegramIcon />}
                        onClick={handleTabPanelSubmit}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={3} md={3}>


              <Paper square sx={{ p: 1 }}>
                <Box
                  mt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Switch
                    onChange={handleSwitchChangefb}
                  />
                  <Typography>{socialMedia}</Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
