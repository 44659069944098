import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import backendUrl from "../../configmain";
import axios from "axios";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
// import { Link } from "react-router-dom";

let url = `${backendUrl}/api/user/me`;
export default function HeaderContent() {
  const [details, setDetails] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const open = Boolean(anchorEl);

  const handleClickBackground = () => {
    setIsClicked(true);
  };

  const backgroundColor = isClicked ? "rgb(54, 65, 82);" : "RGB(217 240 240)";
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsClicked(false);
  };


  const handleLogOut = () => {
   try {

    const response = axios.delete(`${backendUrl}/api/auth/logout`, {
      headers: {
        Authorization: localStorage.getItem("authorization"),

      },
    });

    if(response){
      localStorage.removeItem("authorization");
      window.location.reload();
    }else{
      console.error("Logout failed. Please try again later.")
    }
    
   } catch (error) {
    console.error("An error occurred while logging out:", error);

   }
  };


  useEffect(() => {
    const fetchData = async () => {
      // console.log(url, "url")
      // console.log(token, "token")
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: localStorage.getItem("authorization"),
          },
        });
        setDetails(response.data.data);
      } catch (error) {
        console.error(error);
        if (error.message) {
          console.error(error.message);
        }
      }
    };

    fetchData();
  }, []);
  return (
    <Box sx={{display:"flex",  marginRight: 7}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <NotificationsOutlinedIcon
          sx={{ fontSize: "30px", color: "gray", cursor: "pointer" }}
        />
        <ListItem
          onClick={handleClick}
          size="small"
          sx={{ color: isClicked ? "#E5E8E2" : "rgb(54, 65, 82);" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <ListItemButton
            sx={{
              borderRadius: "50px",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              fontWeight: "bolder",
              background: backgroundColor,
              padding: "5px",
              paddingRight: "10px",
              "&:hover": {
                color: isClicked ? "rgb(54, 65, 82);" : "#E5E8E2",
                background: isClicked ? "#E5E8E2" : "rgb(54, 65, 82)",
              },
            }}
            onClick={handleClickBackground}
          >
            <Avatar
              alt={details.name}
              src="/path-to-your-avatar-image.jpg"
              sx={{ backgroundColor: "#ffba00", width:"50px" , height:"50px" }}
            />
            <ListItemText sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  textAlign: "right",
                }}
                style={{ fontWeight: "bolder" }}
              >
                {details.name}
                <Typography style={{textAlign:"left"}} >
                  {details.role}
                </Typography>
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>

        

        <React.Fragment>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              component={Link}
              to="/editprofile"
              onClick={handleClose}
            >
              <Avatar /> Edit Profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Avatar /> Activities
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Api key
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Sign out
            </MenuItem>
          </Menu>
        </React.Fragment>
      </Box>
     
    </Box>
  );
}