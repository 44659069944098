import React from 'react'
import { Stack, ThemeProvider } from "@mui/material";
import { Chat_History } from './newData/NewData';
import { NewLinkMsg, NewMediaMsg, NewReplyMsg, NewTextMsg, NewTimeline ,NewDocMsg} from './NewMsgType';
const NewMessages = () => {
    console.log(Chat_History)
    return (
        <>
            <Stack spacing={3}>
                {
                    Chat_History.map((el) => {
                        switch (el.type) {
                            case "divider":
                                {/* timeline */ }
                                return <NewTimeline el={el} />;
                            case "msg":
                                switch (el.subtype) {
                                    case "img":
                                        {/* img */ }
                                        return <NewMediaMsg el={el} />
                                    case "doc":
                                        {/* doc */ }
                                         return <NewDocMsg el={el} /> 

                                    case "link":
                                        {/* link */ }
                                        return <NewLinkMsg el={el} />
                                    case "reply":
                                        return <NewReplyMsg el={el} /> 
                                    default:
                                        //text Message
                                        return <NewTextMsg el={el} />
                                }
                            default:
                                return <></>
                        }
                    })
                }
            </Stack>
        </>
    )
}

export default NewMessages
