import { Box, Stack, Button, TextField, InputAdornment, IconButton, Grow, Popper, Paper, ClickAwayListener, MenuList, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import React from 'react'
import Fab from '@mui/material/Fab';
import data from '@emoji-mart/data'
import { Chat_History } from "./newData/NewData";

import {
  Camera,
  File,
  Image,
  LinkSimple,
  Sticker,
  User,
  PaperPlaneTilt,
  Smiley
} from "phosphor-react";
import Picker from '@emoji-mart/react'
import { useDispatch } from 'react-redux';
import { sendMessage } from '../../../redux/slices/app'; // make sure this path is correct
const Actions = [
  {
    color: "#4da5fe",
    icon: <Image size={24} />,
    y: 102,
    title: "Photo/Video",
  },
  {
    color: "#1b8cfe",
    icon: <Sticker size={24} />,
    y: 172,
    title: "Stickers",
  },
  {
    color: "#0172e4",
    icon: <Camera size={24} />,
    y: 242,
    title: "Image",
  },
  {
    color: "#0159b2",
    icon: <File size={24} />,
    y: 312,
    title: "Document",
  },
  {
    color: "#013f7f",
    icon: <User size={24} />,
    y: 382,
    title: "Contact",
  },
];


const NewFooter = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [openPicker, setOpenPicker] = useState(false)
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  console.log(Chat_History)
  console.log(message)
  const handleSendClick = () => {
    if (message.trim()) { // Check if the message is not just whitespace
      console.log('Sending message:', message); // Log the message here

        let payload={
          type: "msg",
        message: message,
        incoming: false,
        outgoing: true,
        }

        Chat_History.push(payload)

      
        
      // dispatch(sendMessage(message));
       // Dispatch the sendMessage action with the message
      setMessage(''); // Clear the input after sending the message
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <Box style={{ padding: '10px', display: 'flex' ,}}>
        <Box sx={{
          display: openPicker ? "inline" : "none",
          width: "100px",
          transform: "translateY(-100%)",
          position: "absolute",
        }}>
          <Picker data={data} onEmojiSelect={console.log} />
        </Box>
        <Stack sx={{ position: "relative" }}>
        </Stack>
        <TextField 
          type="text"
          value={message}
          onChange={handleInputChange}
          style={{ flex: 1 }}
          placeholder='Type a message...'
          InputProps={{
            startAdornment: (
              <Stack sx={{ width: "max-content" }}>
                <InputAdornment position="start" m={1}>
                  <IconButton
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <LinkSimple size={32} />
                  </IconButton>
                  <IconButton onClick={() => setOpenPicker((prev) => !prev)}>
                    <Smiley size={32} />
                  </IconButton>
                </InputAdornment>
              </Stack>
            ),
            endAdornment: (
              <InputAdornment position="end" >
                <IconButton onClick={handleSendClick}>
                  <PaperPlaneTilt size={32} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="top-start"
        transition
        disablePortal
      
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'top-start' ? 'left top' : 'left bottom',
            }}
          >
            <Box>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ display: "flex", gap: 1, flexDirection: "column", }}
                >
                  {
                    Actions.map((item, index) => {
                      return (
                        <Tooltip placement="right" title={<span style={{ fontSize: '14px' }}>{item.title}</span>}>
                          <Fab sx={{
                            backgroundColor: item.color, '&:hover': {
                              backgroundColor: '#d9f0f0',
                            },
                          }}>
                            <MenuItem onClick={handleClose} >{item.icon}</MenuItem>
                          </Fab>
                        </Tooltip>
                      )
                    })
                  }
                </MenuList>
              </ClickAwayListener>
            </Box>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default NewFooter
