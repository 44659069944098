import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Select, TextField, Switch, Grid, RadioGroup, Radio, FormControl, MenuItem, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip, Button, InputLabel, Card, CardContent } from '@mui/material';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';// import Papa from 'papaparse';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/system';
import chat from "../../icons/chat.png"
import backendUrl from '../../../../configmain';
import { Link } from "react-router-dom";
// import { indexOf } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { useRef } from 'react';
import imgs from "../../icons/imgs.png";
import dox from "../../icons/dox.png";
import media from "../../icons/media.png";


export const CSVmain = () => {
  const [text, setText] = useState("");
  const [campaignName, setCampaignName] = useState(''); // this state is set for the campaign name
  const [searchQuery, setSearchQuery] = useState(''); // this state os for saving the name query for searching the template
  const [searchResults, setSearchResults] = useState([]);
  const [previewData, setPreviewData] = useState("")
  const [selectedResult, setSelectedResult] = useState(null);  // this state is for selecting templates from search templates
  const [scheduled, setscheduled] = useState(false); // this state is for true or false value for scheduled time 
  const [exclude, setexclude] = useState('') // this state is for exclude option 
  const [selectedDate, setSelectedDate] = useState(null); // this is for selecting date and time for scheduling
  const [csvData, setCsvData] = useState([]); // this is for setting the data extracted from csv file
  const [showSearchResultPaper, setShowSearchResultPaper] = useState(true); // this is for to show and hide the result in templates
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);// this state manages the success dialog
  const [error, setError] = useState(null);
  // const [selectedFileName, setSelectedFileName] = useState('');
  const [inputFields, setInputFields] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([])
  const [bodyText, setBodyText] = useState('');
  const [numbers, setNumbers] = useState([])
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(['']);
  const [successMessage, setSuccessMessage] = useState("");
  const [mediaName, setMediaName] = useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);

  const [selectedValue1, setSelectedValue1] = useState(['']);
  const [selectedValue2, setSelectedValue2] = useState(['']); // theses are the states to show value that is selected in select
  const [selectedValue3, setSelectedValue3] = useState(['']);
  const [selectedValue4, setSelectedValue4] = useState(['']);
  const [selectedValue5, setSelectedValue5] = useState(['']);


  const [sendValue1, setsendValue1] = useState([''])
  const [sendValue2, setsendValue2] = useState([''])
  const [sendValue3, setsendValue3] = useState([''])  // these are paramters that will send in backend to meta
  const [sendValue4, setsendValue4] = useState([''])
  const [sendValue5, setsendValue5] = useState([''])

  const [selectedGroup, setSelectedGroup] = React.useState('');
  const [groups, setGroups] = useState([]);


  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  let url = `${backendUrl}/api/whatsapp/`;
  let token = localStorage.getItem('authorization');
  const navigate = useNavigate();

  const hiddenFileInputRef = useRef(null);
  const [fileLink, setFileLink] = React.useState('');
  const [selectedMedia, setSelectedMedia] = useState("");
  const nameOptions = [
    { label: "NONE" },
    { label: "IMAGE" },
    { label: "VIDEO" },
    { label: "DOCUMENT" },
  ];
 
  // choose Media functions 

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleCardClick = (mediaType) => {
    hiddenFileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    setSelectedMedia(selectedFile);
  };

  const handleUpload = () => {
    const formData = new FormData();
    let token = localStorage.getItem("authorization");
    let headers = {
      Authorization: token,
    };

    formData.append('file',  selectedFile);
    axios.post(`${backendUrl}/api/user/upload`, formData, { headers })
      .then(response => {
        console.log(response)
        const uploadedLink = response.data.link
        setFileLink(uploadedLink);
        console.log(uploadedLink)
      
      })
      .catch(error => {
        console.error('Error uploading file:', error);
      });
  };

  //========================== these are the function to handle input its not optimized but will do it later==========//
  const handleSelectedValue1 = async (e) => {
    const value = e.target.value;
    setSelectedValue1(value)

    const extractValues = csvData.map(item => item[value])
    setsendValue1(extractValues)

  }
  const handleSelectedValue2 = async (e) => {
    const value = e.target.value;
    setSelectedValue2(value)
    const extractValues = csvData.map(item => item[value])
    setsendValue2(extractValues)
  }
  const handleSelectedValue3 = async (e) => {
    const value = e.target.value;
    setSelectedValue3(value)
    const extractValues = csvData.map(item => item[value])
    setsendValue3(extractValues)
  }
  const handleSelectedValue4 = async (e) => {
    const value = e.target.value;
    setSelectedValue4(value)
    const extractValues = csvData.map(item => item[value])
    setsendValue4(extractValues)
  }
  const handleSelectedValue5 = async (e) => {
    const value = e.target.value;
    setSelectedValue5(value)
    const extractValues = csvData.map(item => item[value])
    setsendValue5(extractValues)
  }

  //======================================================================================================================//


  const showSuccessDialog = (message) => {
    setSuccessDialogOpen(true);
    setSuccessMessage(message);

  };
  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);
    setSuccessMessage("");
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleScheduled = () => {
    setscheduled(!scheduled);
    setSelectedDate(null);
  };
  // const handleExcludedOption = () => {
  //   setexclude(!exclude);
  // };



  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const requestUrl = `${url}notDeleted`;
        const response = await axios.get(requestUrl, {
          headers: {
            Authorization: `${token}`,
          },
        });

     if (response.status >= 200 && response.status < 300) {
          setSearchResults(response.data);
          // let data= response.data.map((el)=> el.components)
          // setPreviewData(response.data[0].components);
        } else {
          setErrorMessage(response.data.message || 'An error occurred.');
          setErrorDialogOpen(true);
        }
      } catch (error) {
        if (error.response && error.response.data.status === 404) {
          // console.log(error.response.statusText)
          setErrorMessage(error.response.statusText);
        } else {
          setErrorMessage('Network error occurred.');
          setErrorDialogOpen(true);
        }
      }
    };

    // Call the fetchSearchResults function
    fetchSearchResults();

  }, []); // Make sure to pass an empty dependency array if you want this effect to run only once





  const postCampaign = async () => {

    try {
      const postData = {
        campaignName: campaignName,
        to: numbers,
        template: selectedResult,
        isScheduled: scheduled,
        scheduledTime: selectedDate,
        sendValue1: sendValue1,
        sendValue2: sendValue2,
        sendValue3: sendValue3,
        sendValue4: sendValue4,
        sendValue5: sendValue5,
        url:fileLink
      };

      console.log(postData)

      const postUrl = `${url}/messege`;
      const response = await axios.post(postUrl, postData, {
        headers: {
          Authorization: ` ${token}`,
        },
      });

      if (response.status >= 200 && response.status < 300) {
        if (response.data.message === "Campaign scheduled successfully") {
          showSuccessDialog("Campaign scheduled successfully");
        } else {
          showSuccessDialog("Campaign Created Successfully");
        } setTimeout(() => {
          navigate(-1);
        }, 2000);

      }
    } catch (error) {
      handleError(error);
    }
  };



  const handleError = (error) => {
    if (error.response) {
      const { status, data } = error.response;
      console.log(error.response, "Response")
      if (status === 400 || status === 500) {
        setErrorMessage(data.message.message);
        setIsErrorDialogOpen(true);
      }
    } else {
      // Network error
      setErrorMessage('Network Error: Unable to connect to the server.');
      setIsErrorDialogOpen(true);
    }
  };

  const closeErrorDialog = () => {
    setIsErrorDialogOpen(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleCampaignNameChange = (event) => {
    setCampaignName(event.target.value);
  };
  const handleSelectResult = (result) => {
    setShowSearchResultPaper(false);
    setSelectedResult(result);
    setSearchQuery(result.name);
    setPreviewData(result.components)
  };




  const handleChange = async (event) => {
    console.log(event.target.value)
    setSelectedGroup(event.target.value);

    try {

      const response = await axios.get(`${backendUrl}/api/contactGroup/${event.target.value}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      if (response) {
        console.log("RESPONSE::::", response)
        const contacts = response.data.groupData.contacts
        const allNumbers = contacts.map(item => item.phone).filter(Boolean);
        setNumbers(allNumbers);
        setCsvHeaders(response.data.groupData.headers)
        console.log(response.data.groupData.headers, "ixhwidhwioh")
        setCsvData(response.data.groupData.contacts)
      }

    } catch (error) {
      console.error("Failed to fetch groups:", error);

    }
  };
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/contactGroup/`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if (response) {
          setGroups(response.data.groupData);
        }
      } catch (error) {
        console.error("Failed to fetch groups:", error);
      }
    };

    fetchGroups();
  }, []);

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const handleMediaChange = (event) => {
    setMediaName(event.target.value);
   
  };

  console.log(selectedFile)



  const [contentsState, setContentsState] = useState([]);
  useEffect(() => {
    if (previewData) {
      let text = '';
      let inputFieldsArray = [];
      previewData.forEach((item) => {
        if (item.type === "BODY") {
          text += item.text;
        }
      });
      const inputFieldContents = text.match(/{{\d+}}/g) || [];
      const newContentsState = [];
      inputFieldContents.forEach((content, index) => {
        newContentsState.push(content);
        const inputField = (
          <Box>
            {
              {/* bodyText && (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box>
                    <InputLabel sx={{ marginBottom: "10px" }}>{selected option of ${content} : ${selectedHeader}}</InputLabel>
                    <TextField
                      key={index}
                      select
                      // label={Choose for ${content}}
                      value={selectedValue}
                      onChange={handleSelectChange}
                      sx={{ width: "200px" }}
                    >
                      {header && header.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option} onClick={() => selectDataColumn(option)} >
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box sx={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                    <Typography> OR</Typography>
                  </Box>
                  <Box sx={{ mr: 2 }}>
                    <InputLabel sx={{ marginBottom: "10px" }}>{Your text for index ${index}}</InputLabel>
                    <TextField
                      
                    />
                  </Box>
                </Box>
              ) */}
            }
          </Box>
        );
        inputFieldsArray.push(inputField);
      });
      setContentsState(newContentsState);
      console.log(newContentsState);
      setBodyText(text);
      setInputFields(inputFieldsArray);
    }
  }, [previewData]);









  return (
    <>
      <Box p={2}>
        <h1>Create Campaign</h1>
        <Paper elevation={1} sx={{ p: 2, mt: 3 }}>
          <Typography variant="h6" component="div">
            Campaign Name
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Pick something that describes your audience & goals.
          </Typography>
          <TextField
            id="campaign-name"
            label="Campaign Name"
            variant="outlined"
            fullWidth
            sx={{ mt: 2, }}
            InputProps={{
              sx: {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#279d85",
                },
              },
            }}
            InputLabelProps={{
              sx: {
                "&.Mui-focused": {
                  color: "#279d85",
                },
              },
            }}
            value={campaignName}
            onChange={handleCampaignNameChange}
          />
        </Paper>
        <div>
          <Paper
            elevation={1} sx={{ p: 2, mt: 3 }}
          >
            <Typography variant="h6" component="div" sx={{}} >Choose Contact Groups</Typography>

            <FormControl variant="outlined" style={{ minWidth: 200 }} sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#279d85",
              },
              mt: 2
            }}  >
              <InputLabel id="contact-group-label" sx={{
                "&.Mui-focused": {
                  color: "#279d85", // Change the label color when focused
                },
              }}>Contact Group</InputLabel>
              <Select
                labelId="contact-group-label"
                value={selectedGroup}
                onChange={handleChange}
                label="Contact Group"
                sx={{
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },
                }}

              >
                {groups.map(group => (
                  <MenuItem key={group._id} value={group._id}>
                    {group.groupName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Paper>
        </div>

        <Paper elevation={1} sx={{ p: 2, mt: 2, height: '150px' }}>
          <Typography variant="h6" component="div">
            Choose Templates
          </Typography>

          <Autocomplete
            id="message-search"
            options={searchResults}
            getOptionLabel={(result) => result.name}
            fullWidth
            value={searchResults.find((result) => result.name === searchQuery) || null}
            // value={selectedResult || null}
            onChange={(event, newValue) => handleSelectResult(newValue)}
            onInputChange={(event, newQuery) => setSearchQuery(newQuery)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search your template"
                variant="outlined"
                fullWidth
                sx={{
                  mt: 2,
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#279d85',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    '&.Mui-focused': {
                      color: '#279d85',
                    },
                  },
                }}
              />
            )}

            renderOption={(props, result, state) => {
              // Check if "child" is defined
              console.log(props, result, state)
              if (props && result) {
                return (
                  <li
                    {...props}
                    style={{
                      cursor: 'pointer',
                      padding: '8px',
                      backgroundColor: state.selected ? '#279d85' : '#eeeeee',
                      color: state.selected ? 'white' : 'black',
                    }}
                  >
                    {result.name}
                  </li>
                );
              }
              // Provide a default option when "child" is not defined
              return <li>No results found</li>;
            }}
            // Set the maximum height for the dropdown list
            PopperComponent={({ children, ...other }) => (
              <Popper {...other}>
                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                  {children}
                </div>
              </Popper>
            )}
          />
        </Paper>


        {
          searchQuery && (
            <Box>
              <Paper elevation={1} sx={{ p: 2, mt: 2, display: 'flex', justifyContent: 'space-evenly' }}>

                <Box mt={2}>

                  {
                    // Conditionally render the TextField
                    contentsState.includes("{{1}}") && (
                      <Box>
                        <InputLabel id="contact-group-label" sx={{
                          "&.Mui-focused": {
                            color: "#279d85",
                            // Change the label color when focused
                          },
                        }} >{`Text for variable: ${contentsState[0]} `}</InputLabel>
                        <TextField
                          select
                          value={selectedValue1}
                          onChange={handleSelectedValue1}
                          sx={{ width: "200px", marginBottom: "10px" }}
                        >
                          {csvHeaders && csvHeaders.map((option, optionIndex) => (
                            <MenuItem key={optionIndex} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    )

                  }
                  {
                    contentsState.includes("{{2}}") && (
                      <Box mt={2}>
                        <InputLabel sx={{ marginBottom: "10px" }}>{`Text for variable: ${contentsState[1]} `}</InputLabel>
                        <TextField
                          select
                          value={selectedValue2}
                          onChange={handleSelectedValue2}
                          sx={{ width: "200px", marginBottom: "10px" }}
                        >
                          {csvHeaders.map((columnName, index) => (
                            <MenuItem key={index} value={columnName}>{columnName}</MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    )
                  }
                  {
                    contentsState.includes("{{3}}") && (
                      <Box mt={2}>
                        <Box>
                          <InputLabel sx={{ marginBottom: "10px" }}>{`Text for variable: ${contentsState[2]} `}</InputLabel>
                          <TextField
                            select
                            value={selectedValue3}
                            onChange={handleSelectedValue3}
                            sx={{ width: "200px", marginBottom: "10px" }}

                          >

                            {csvHeaders.map((columnName, index) => (
                              <MenuItem key={index} value={columnName}>{columnName}</MenuItem>
                            ))}

                          </TextField>
                        </Box>
                      </Box>
                    )
                  }
                  {
                    contentsState.includes("{{4}}") && (
                      <Box mt={2}>
                        <Box>
                          <InputLabel sx={{ marginBottom: "10px" }}>{`Text for variable: ${contentsState[3]} `}</InputLabel>
                          <TextField
                            select
                            value={selectedValue4}
                            onChange={handleSelectedValue4}
                            sx={{ width: "200px", marginBottom: "10px" }}


                          >
                            {csvHeaders.map((columnName, index) => (
                              <MenuItem key={index} value={columnName}>{columnName}</MenuItem>
                            ))}
                          </TextField>
                        </Box>
                      </Box>
                    )
                  }
                  {
                    contentsState.includes("{{5}}") && (
                      <Box mt={2}>
                        <Box>
                          <InputLabel sx={{ marginBottom: "10px" }}>{`Text for variable: ${contentsState[4]} `}</InputLabel>
                          <TextField
                            select
                            value={selectedValue5}
                            onChange={handleSelectedValue5}
                            sx={{ width: "200px", marginBottom: "10px" }}


                          >
                            {csvHeaders.map((columnName, index) => (
                              <MenuItem key={index} value={columnName}>{columnName}</MenuItem>
                            ))}
                          </TextField>
                        </Box>
                      </Box>
                    )


                  }

                  {
                    previewData && previewData.map((item)=>{
                      if(item.format === "IMAGE" || item.format==="VIDEO" || item.format==="DOCUMENTS"){
                        return (
                          <Box>
                            <br />
                  <lable  >Choose Media</lable>
                  <FormControl sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#279d85",
                    },
                    marginTop: "10px"
                  }} variant="outlined" fullWidth>

                    <InputLabel sx={{
                      "&.Mui-focused": {
                        color: "#279d85", // Change the label color when focused
                      },
                    }}>Media Name</InputLabel>
                    <Select
                      size="small"
                      value={mediaName}
                      onChange={(e)=>handleMediaChange(e)}
                      label="Media Name"
                      accept="image"
                    >
                      {nameOptions.map((option, index) => (
                        <MenuItem key={index} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                   <Box sx={{ mt: 2 }}>
                    {mediaName && mediaName === "IMAGE" ? (
                      <Box>
                        <Card onClick={() => handleCardClick("IMAGE")}>
                          <CardContent>
                            <Box  >
                              <input
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                ref={hiddenFileInputRef}
                                onChange={handleFileChange}
                              />

                            </Box>
                            <Box style={centerMediaStyle}>
                              <img src={imgs} alt="Your Image" />
                            </Box>
                          </CardContent>
                        </Card>
                        {/* {
                          fileLink ? (<p  style={{color:"green"}} >File Uploaded</p>) : (<Button onClick={handleUpload} >Upload</Button>)
                        } */}
                        <Button onClick={handleUpload} >Upload</Button>
                        {fileLink && <p  style={{color:"green"}} >Image Uploaded</p>}
                      </Box>
                    ) : mediaName && mediaName === "DOCUMENT" ? (
                      <Box>
                      <Card onClick={() => handleCardClick("DOCUMENT")}>
                        <CardContent>
                          <Box>
                            <input
                              type="file"
                              accept="image/*,video/*,.pdf"
                              style={{ display: "none" }}
                              ref={hiddenFileInputRef}
                              onChange={handleFileChange}
                            />
                          </Box>
                          <Box style={centerMediaStyle}>
                            <img src={dox} alt="Media" />
                          </Box>
                        </CardContent>
                      </Card>
                      {/* {
                          fileLink ? (<p  style={{color:"green"}} >File Uploaded</p>) : (<Button onClick={handleUpload} >Upload</Button>)
                        } */}
                        <Button onClick={handleUpload} >Upload</Button>
                        {fileLink && <p  style={{color:"green"}} >Document Uploaded</p>}
                        </Box>
                    ) : mediaName && mediaName === "VIDEO" ? (
                      <Box>
                      <Card onClick={() => handleCardClick("VIDEO")}>
                        <CardContent>
                          <Box>
                            <input
                              type="file"
                              accept="video/*"
                              style={{ display: "none" }}
                              ref={hiddenFileInputRef}
                              onChange={handleFileChange}
                            />
                          </Box>
                          <Box style={centerMediaStyle}>
                            <img src={media} alt="Your Image" />
                          </Box>
                        </CardContent>
                      </Card>
                      {/* {
                          fileLink ? (<p  style={{color:"green"}} >File Uploaded</p>) : (<Button onClick={handleUpload} >Upload</Button>)
                        } */}
                     <Button onClick={handleUpload} >Upload</Button>
                        {fileLink && <p  style={{color:"green"}} >Video Uploaded</p>}
                        </Box>
                    ) : null}
                  </Box>
                          </Box>
                        )
                      }
                    })
                  }

                  
                  

                </Box>


                <Box p={2} sx={{ backgroundImage: `url(${chat})`, backgroundPosition: 'center', backgroundSize: 'cover', maxWidth: '500px', width: '100%' }}>
                  <Box>
                    <MessageBubblePaper elevation={1} sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                      <Box>
                        {
                          previewData &&
                          previewData.map((item, index) => {
                            // Check if the item type is "header"
                            console.log(item)
                            if (item.type === "HEADER") {
                              return (
                                <Box key={index}>
                                  <img src={item.example?.header_handle} style={{ backgroundColor: "red", width: "335px" }} alt="" />
                                  <Typography variant="body1" sx={{ fontWeight: "bold" }} >
                                    {item.text}
                                  </Typography>
                                </Box>
                              );
                            }
                            else if (item.type === "BODY") {

                              return (
                                <Box mt={2} key={index}>
                                  <Typography>
                                    {item.text}
                                  </Typography>
                                </Box>
                              )
                            } else if (item.type === "FOOTER") {
                              return (
                                <Box>
                                  <Typography>
                                    {item.text}
                                  </Typography>
                                </Box>
                              )
                            }
                          })
                        }
                      </Box>
                    </MessageBubblePaper>
                    <Box>
                      {
                        previewData &&
                        previewData.map((item, index) => {
                          if (item.type === "BUTTONS") {
                            return (
                              <Box key={index}>
                                <Box sx={{ display: 'flex', gap: "1%" }}>

                                  {item.buttons.map((button, index) => (
                                    button.type === "PHONE_NUMBER" ? (
                                      <Paper elevation={2} sx={{ display: 'flex', alignItems: 'center', width: '37%', borderRadius: '2px', fontSize: '10px', mt: 1 }} key={index}>
                                        <Typography component={Link} role="button" sx={{ p: 2, textAlign: "center", justifyContent: "center", alignItems: "center", wordWrap: 'break-word', whiteSpace: 'pre-wrap', width: '100%', borderRadius: '5px', color: 'blue', fontSize: '12px' }}>
                                          {button.text}
                                        </Typography>
                                      </Paper>
                                    ) : (
                                      <Box>
                                        <Paper elevation={2} sx={{ display: 'flex', alignItems: 'center', width: '75%', borderRadius: '2px', fontSize: '10px', mt: 1 }}>
                                          <Typography component={Link} role="button" sx={{ p: 2, textAlign: "center", justifyContent: "center", alignItems: "center", wordWrap: 'break-word', whiteSpace: 'pre-wrap', width: '100%', borderRadius: '5px', color: 'blue', fontSize: '12px' }}>
                                            {button.text}
                                          </Typography>
                                        </Paper>
                                      </Box>
                                    )
                                  ))}
                                </Box>
                              </Box>
                            );
                          }
                        }
                        )}
                    </Box>
                  </Box>
                </Box>
                <Box>

                </Box>
              </Paper>
            </Box>

          )
        }

        <Paper elevation={1} sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6" component="div">
            Schedule Your Campaign
          </Typography>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={scheduled}
                  onChange={handleScheduled}
                  name="preview-switch"
                  color="primary"
                />
              }
              label={
                <Tooltip title="Schedule campaign up to two months from today">
                  Schedule Date and Time
                </Tooltip>
              }
            />

            {scheduled && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}>


                  <DateTimePicker
                    label="Select Date and Time"
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider>
            )}
          </Box>


        </Paper>

        <Paper elevation={1} sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6" component="div">
            Audience :  {csvData.length}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
          </Typography>
          <Button
            variant="outlined"
            color="success"
            sx={{ mt: 1 }}
            onClick={postCampaign}
          >
            Send Now
          </Button>
        </Paper>
      </Box >

      <Dialog open={successDialogOpen} onClose={closeSuccessDialog} aria-labelledby="success-dialog-title">
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {successMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSuccessDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isErrorDialogOpen} onClose={closeErrorDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const MessageBubblePaper = styled(Paper)(({ theme }) => ({
  maxWidth: '75%',
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  borderRadius: '5px',
  position: 'relative',
  '&::after': {
    // content: '""',
    position: 'absolute',
    top: '50%',
    left: '10px',
    borderWidth: '10px',
    borderStyle: 'solid',
    borderColor: 'transparent transparent transparent #dcf8c6',
    // transform: 'translateY(-50%)',
  },
}));


const centerMediaStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  cursor: 'pointer'

};