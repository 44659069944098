import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DashboardIconCustom from "../../assets/icons/dashboard.png"
import SchoolIconCustom from "../../assets/icons/onboard.png";
import TextsmsIcon from "@mui/icons-material/Textsms";
import TextsmsIconCustom from "../../assets/icons/messaging.png";
import QueueIconCustom from "../../assets/icons/fb.png"
import Team from "../../assets/icons/team.png";
import Livechat from "../../assets/icons/livechat.png";
import Contact from "../../assets/icons/contect.png";
import SendIcon from "@mui/icons-material/Send";
import SendIconCustom from "../../assets/icons/chatbot.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import WhatsAppIconCustom from "../../assets/icons/wtp.png"
import TaskIcon from "@mui/icons-material/Task";
import TaskIconCustom from "../../assets/icons/template.png";
import CampaignIconCustom from "../../assets/icons/sequence.png"
import SubscriptionsIconCustom from "../../assets/icons/subscription.png";
import BroadcastOnHomeIcon from "@mui/icons-material/BroadcastOnHome";
import GroupsIcon from "@mui/icons-material/Groups";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EmailIconCustom from "../../assets/icons/email.png";
import "./Sidebar.css";
import { useState } from "react";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";
import Home from "../../main/Home";
import HeaderContent from "../Header/HeaderContent";
import { useEffect } from "react";
import ChatBox from "../Message/ChatBox";
const drawerWidth = 240;
function SideNav(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [selectedItem, setSelectedItem] = useState("");
  const [activeItem, setActiveItem] = useState(localStorage.getItem('activeItem') || '');
  // const [activeItem, setActiveItem] = useState(null);
  const [atvbtn, setAtvbtn] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const token = localStorage.getItem("authorization");
  const toggleBox = (item) => {
    setActiveItem(item);
    if (selectedItem === item) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };
  useEffect(() => {
    localStorage.setItem('activeItem', activeItem);
  }, [activeItem]);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.role;
      setUserRole(userRole);
    }
  }, [token]);
  const drawer = (
    <div>
      <Box
        sx={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}>
        <h1>Logo</h1>
      </Box>
      <List gap={2} style={{ padding: "10px" }}>
        <ListItem className={activeItem === "Dashboard" ? "navdashboard" : ""}
          sx={{ padding: "0px", marginBottom: '8px', }}>
          <ListItemButton
            sx={{
              "&:hover": {
                color: "#279d85",
                background: "RGB(217 240 240)",
                borderRadius: "7px"
              },
            }}
            onClick={() => toggleBox("Dashboard")}
            component={Link}
            to="/dashboard/analytics"
          >
            <ListItemIcon style={{ padding: "10px", }}>
              <img src={DashboardIconCustom} alt="" width="18" height="15" style={{ color: "#222" }} />
            </ListItemIcon>
            <ListItemText style={{ margin: " 10px 10px 10px -10px" }} >
              <Typography style={{ fontSize: "1.05rem", fontWeight: "500" }} >Dashboard</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          className={activeItem === "FB & Instagram" ? "navdashboard" : ""}
          sx={{ padding: "0px", marginBottom: '8px' }}
        >
          <ListItemButton
            sx={{
              "&:hover": {
                color: "#279d85",
                background: "RGB(217 240 240)",
                borderRadius: "7px"
              }
            }}
            onClick={() => toggleBox("FB & Instagram")}
            component={Link}
            to="/fbinstagram/accountManagement"
          >
            <ListItemIcon style={{ padding: "10px" }}>
              <img src={QueueIconCustom} alt="" width="18" height="16" />
            </ListItemIcon>
            <ListItemText style={{ margin: " 10px 10px 10px -10px" }} >
              <Typography style={{ fontSize: "1.05rem", fontWeight: "500" }}>
                FB & Instagram
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          className={activeItem === "whatsappbot" ? "navdashboard" : ""}
          sx={{ padding: "0px", marginBottom: '8px' }}>
          <ListItemButton
            sx={{
              "&:hover": {
                color: "#279d85",
                background: "RGB(217 240 240)",
                borderRadius: "7px"
              },
            }}
            onClick={() => toggleBox("whatsappbot")}
            component={Link}
            to="/whatsApp/business">
            <ListItemIcon style={{ padding: "10px" }}>
              <img src={WhatsAppIconCustom} alt="" width="18" height="18" />
            </ListItemIcon>
            <ListItemText style={{ margin: " 10px 10px 10px -10px" }} >
              <Typography style={{ fontSize: "1.05rem", fontWeight: "500" }}>WhatsApp</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          className={activeItem === "contact" ? "navdashboard" : ""}
          sx={{ padding: "0px", marginBottom: '8px' }}>
          <ListItemButton
            sx={{
              "&:hover": {
                color: "#279d85",
                background: "RGB(217 240 240)",
                borderRadius: "7px"
              },
            }}
            onClick={() => toggleBox("contact")}
            component={Link}
            to="/email/contactGroup">
            <ListItemIcon style={{ padding: "10px" }}>
              <img src={Contact} alt="" width="18" height="18" />
            </ListItemIcon>
            <ListItemText style={{ margin: " 10px 10px 10px -10px" }} >
              <Typography style={{ fontSize: "1.05rem", fontWeight: "500" }}>Contact&nbsp;Manager</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          className={activeItem === "sequence" ? "navdashboard" : ""}
          sx={{ padding: "0px", marginBottom: '8px' }}>
          <ListItemButton
            sx={{
              "&:hover": {
                color: "#279d85",
                background: "RGB(217 240 240)",
                borderRadius: "7px"
              },
            }}
            onClick={() => toggleBox("sequence")}
            component={Link}
            to="/sequenceCampaign/viewCampaign"
          >
            <ListItemIcon style={{ padding: "10px" }}>
              <img src={CampaignIconCustom} alt="" width="18" height="18" />
            </ListItemIcon>
            <ListItemText style={{ margin: " 10px 10px 10px -10px" }} >
              <Typography style={{ fontSize: "1.05rem", fontWeight: "500" }}>SequenceCamp</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          className={activeItem === "email" ? "navdashboard" : ""}
          sx={{ padding: "0px", marginBottom: '8px' }}
        >
          <ListItemButton
            sx={{
              "&:hover": {
                color: "#279d85",
                background: "RGB(217 240 240)",
                borderRadius: "7px"
              },
            }}
            onClick={() => toggleBox("email")}
            component={Link}
            to="/email/contactGroup"
          >
            <ListItemIcon style={{ padding: "10px" }}>
              <img src={EmailIconCustom} alt="" width="18" height="18" />
            </ListItemIcon>
            <ListItemText style={{ margin: " 10px 10px 10px -10px" }} >
              <Typography style={{ fontSize: "1.05rem", fontWeight: "500" }}>Email</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem
          className={activeItem === "team" ? "navdashboard" : ""}
          sx={{ padding: "0px", marginBottom: '8px' }}
        >
          <ListItemButton
            sx={{
              "&:hover": {
                color: "#279d85",
                background: "RGB(217 240 240)",
                borderRadius: "7px"
              },
            }}
            onClick={() => toggleBox("team")}
            component={Link}
            to="/teamManager/groupMananger"
          >
            <ListItemIcon style={{ padding: "10px" }}>
              <img src={Team} alt="" width="18" height="18" />
            </ListItemIcon>
            <ListItemText style={{ margin: " 10px 10px 10px -10px" }} >
              <Typography style={{ fontSize: "1.05rem", fontWeight: "500" }}>Team Manager</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem
          className={activeItem === "liveChat" ? "navdashboard" : ""}
          sx={{ padding: "0px", marginBottom: '8px' }}
        >
          <ListItemButton
            sx={{
              "&:hover": {
                color: "#279d85",
                background: "RGB(217 240 240)",
                borderRadius: "7px"
              },
            }}
            onClick={() => toggleBox("liveChat")}
            component={Link}
            to="/newChatBot">
            <ListItemIcon style={{ padding: "10px" }}>
              <img src={Livechat} alt="" width="18" height="18" />
            </ListItemIcon>
            <ListItemText style={{ margin: " 10px 10px 10px -10px" }} >
              <Typography style={{ fontSize: "1.05rem", fontWeight: "500" }}>Live Chat</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {userRole && userRole === "admin" && (
          <ListItem
            className={activeItem === "subscription" ? "navdashboard" : ""}
            sx={{ padding: "0px", marginBottom: '8px' }}
          >
            <ListItemButton
              sx={{
                "&:hover": {
                  color: "#279d85",
                  background: "RGB(217 240 240)",
                  borderRadius: "7px"
                },
              }}
              onClick={() => toggleBox("subscription")}
              component={Link}
              to="/subscription/userManager"
            >
              <ListItemIcon style={{ padding: "10px" }}>
                <img src={SubscriptionsIconCustom} alt="" width="18" height="18" />
              </ListItemIcon>
              <ListItemText style={{ margin: " 10px 10px 10px -10px" }} >
                <Typography style={{ fontSize: "1.05rem", fontWeight: "500" }}>
                  Subscription
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: {
              sm: `calc(100% - ${drawerWidth}px)`,
              background: "#fff",
              color: "black",
            },
            ml: { sm: `${drawerWidth}px ` },
            display: "flex",
            alignItems: "end",
            gap: 2,
            boxShadow: "none",
            zIndex: 10,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            height: 70,
          }}

        >
          <Toolbar>
            <Box>
              <HeaderContent />
            </Box>
            <Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: {
              sm: `calc(100% - ${drawerWidth}px)`,
              height: "100%",
              background: "#f2f3f4",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ display: "flex", zIndex: 9, }}>

            <div style={{
              background: "#fff", position: "sticky", top: 0,
              borderRight: "1px solid rgba(0, 0, 0, 0.12)"
            }}>
              {activeItem === "FB & Instagram" && (
                <Box sx={{ padding: "10px" }}>
                  <div>
                    <List component="nav">
                      <ListItem
                        button
                        onClick={() => setAtvbtn("page")}
                        component={Link}
                        to="/fbinstagram/accountManagement"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "page" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "page" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                          },
                        }}
                      >
                        <ListItemIcon>
                          <SendIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>Account&nbsp;Management</span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => setAtvbtn("ont")}
                        button
                        component={Link}
                        to="/fbinstagram/otnbroadcast"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "ont" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "ont" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                            borderRadius: "7px"
                          },
                        }}
                      >
                        <ListItemIcon>
                          <BroadcastOnHomeIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              OTN&nbsp;Broadcast
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => setAtvbtn("post")}
                        button
                        component={Link}
                        to="/fbinstagram/multimediaPost"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          backgroundColor:
                            atvbtn === "post" ? " RGB(217 240 240)" : "",
                          color: atvbtn === "post" ? " #279d85" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                            borderRadius: "7px",
                          },
                        }}
                      >
                        <ListItemIcon>
                          <TaskIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Multimedia&nbsp;Post
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => setAtvbtn("cta")}
                        button
                        component={Link}
                        to="/fbinstagram/ctaPost"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "cta" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "cta" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                          },
                        }}>
                        <ListItemIcon>
                          <TextsmsIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              CTA&nbsp;Post
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => setAtvbtn("vedio")}
                        component={Link}
                        to="/fbinstagram/videoPost"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "vedio" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "vedio" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                          },
                        }}
                      >
                        <ListItemIcon>
                          <VideoLibraryIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Video&nbsp;Post
                            </span>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </Box>
              )}
              {activeItem === "whatsappbot" && (
                <Box sx={{ padding: "10px" }}>
                  <div>
                    <List component="nav">
                      <ListItem
                        button
                        onClick={() => setAtvbtn("bw")}
                        component={Link}
                        to="/whatsApp/business"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "bw" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "bw" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                            color: "#279d85"
                          },
                        }}
                      >
                        <ListItemIcon>
                          <WhatsAppIcon style={{ fontSize: "1.3rem", color: "#279d85" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              BusinessWhatsApp
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => setAtvbtn("ob")}
                        component={Link}
                        to="/whatsApp/onboarding"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "ob" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "ob" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                            color: "#279d85"
                          },
                        }}
                      >
                        <ListItemIcon>
                          <img src={SchoolIconCustom} alt="" width="18" height="18" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Onboarding
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => setAtvbtn("temp")}
                        component={Link}
                        to="/whatsApp/templates"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "temp" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "temp" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                            color: "#279d85"
                          },
                        }}
                      >
                        <ListItemIcon>
                          <img src={TaskIconCustom} alt="" width="18" height="18" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Templates
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => setAtvbtn("cb")}
                        button
                        component={Link}
                        to="/newChatBot"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "cb" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "cb" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                            color: "#279d85"
                          },
                        }}
                      >
                        <ListItemIcon>
                          <img src={SendIconCustom} alt="" width="18" height="18" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Live&nbsp;Chat
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => setAtvbtn("msg")}
                        component={Link}
                        to="/whatsApp/messaging"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "msg" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "msg" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                            color: "#279d85"
                          },
                        }}
                      >
                        <ListItemIcon>
                          <img src={TextsmsIconCustom} alt="" width="18" height="18" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Messaging
                            </span>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </Box>
              )}

              {activeItem === "email" && (
                <Box sx={{ padding: "10px" }}>
                  <div>
                    <List component="nav">
                      <ListItem
                        onClick={() => setAtvbtn("cg")}
                        button
                        component={Link}
                        to="/email/contactGroup"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "cg" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "cg" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                          },
                        }}
                      >
                        <ListItemIcon>
                          <GroupsIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Contact&nbsp;Group
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => setAtvbtn("ac")}
                        component={Link}
                        to="/email/addContacts"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "ac" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "ac" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                          },
                        }}
                      >
                        <ListItemIcon>
                          <AddIcCallIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Add&nbsp;Contacts
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => setAtvbtn("mt")}
                        component={Link}
                        to="/email/manageTemplate"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "mt" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "mt" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                          },
                        }}
                      >
                        <ListItemIcon>
                          <ManageAccountsIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Manage&nbsp;Template
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => setAtvbtn("cc")}
                        component={Link}
                        to="/email/createCampaign"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "cc" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "cc" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                          },
                        }}
                      >
                        <ListItemIcon>
                          <BorderColorIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Create&nbsp;Campaign
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => setAtvbtn("cr")}
                        component={Link}
                        to="/email/campaignReport"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "cr" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "cr" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                          },
                        }}
                      >
                        <ListItemIcon>
                          <ReportProblemIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              Campaign&nbsp;Report
                            </span>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </Box>)}

              {selectedItem === "message" && (
                <div style={{ padding: "10px" }}>
                  {selectedItem === "message" && (
                    <div>
                      <List component="nav">
                        <ListItem
                          button
                          component={Link}
                          to="sms/group"
                          onClick={() => setAtvbtn("sms")}
                          sx={{
                            borderRadius: "7px",
                            marginBottom: "8px",
                            color: atvbtn === "sms" ? " #279d85" : "",
                            backgroundColor:
                              atvbtn === "sms" ? " RGB(217 240 240)" : "",
                            "&:hover": {
                              background: "RGB(217 240 240)",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <GroupsIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                                SMS&nbsp;Group
                              </span>
                            }
                          />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => setAtvbtn("smsContact")}
                          component={Link}
                          to="sms/contacts"
                          sx={{
                            borderRadius: "7px",
                            marginBottom: "8px",
                            color: atvbtn === "smsContact" ? " #279d85" : "",
                            backgroundColor:
                              atvbtn === "smsContact"
                                ? " RGB(217 240 240)"
                                : "",
                            "&:hover": {
                              background: "RGB(217 240 240)",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <AddIcCallIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                                SMS&nbsp;Contacts
                              </span>
                            }
                          />
                        </ListItem>
                        <ListItem
                          onClick={() => setAtvbtn("smsTemp")}
                          button
                          component={Link}
                          to="sms/template"
                          sx={{
                            borderRadius: "7px",
                            marginBottom: "8px",
                            color: atvbtn === "smsTemp" ? " #279d85" : "",
                            backgroundColor:
                              atvbtn === "smsTemp" ? " RGB(217 240 240)" : "",
                            "&:hover": {
                              background: "RGB(217 240 240)",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <ManageAccountsIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                                SMS&nbsp;Template
                              </span>
                            }
                          />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => setAtvbtn("smscamp")}
                          component={Link}
                          to="sms/campaign"
                          sx={{
                            borderRadius: "7px",
                            marginBottom: "8px",
                            color: atvbtn === "smscamp" ? " #279d85" : "",
                            backgroundColor:
                              atvbtn === "smacamp" ? " RGB(217 240 240)" : "",
                            "&:hover": {
                              background: "RGB(217 240 240)",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <BorderColorIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                                SMS&nbsp;Campaign
                              </span>
                            }
                          />
                        </ListItem>
                      </List>
                    </div>
                  )}
                </div>
              )}
              {activeItem === "sequence" && (
                <Box sx={{ padding: "10px" }}>
                  <div>
                    <List component="nav">
                      <ListItem
                        onClick={() => setAtvbtn("viecamp")}
                        button
                        component={Link}
                        to="/sequenceCampaign/viewCampaign"
                        sx={{
                          borderRadius: "7px",
                          marginBottom: "8px",
                          color: atvbtn === "viecamp" ? " #279d85" : "",
                          backgroundColor:
                            atvbtn === "viecamp" ? " RGB(217 240 240)" : "",
                          "&:hover": {
                            background: "RGB(217 240 240)",
                          },
                        }}
                      >
                        <ListItemIcon>
                          <VisibilityIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                              View&nbsp;Campaign
                            </span>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </Box>
              )}



              {activeItem === "subscription" && (
                <Box sx={{ padding:"10px" }}>
                <div>
                      <List component="nav">
                        <ListItem
                          button
                          onClick={() => setAtvbtn("user")}
                          component={Link}
                          to="/subscription/userManager"
                          sx={{
                            borderRadius: "7px",
                            marginBottom: "8px",
                            color: atvbtn === "user" ? " #279d85" : "",
                            backgroundColor:
                              atvbtn === "user" ? " RGB(217 240 240)" : "",
                            "&:hover": {
                              background: "RGB(217 240 240)",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <AdminPanelSettingsIcon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                                User&nbsp;Manager
                              </span>
                            }
                          />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => setAtvbtn("pm")}
                          component={Link}
                          to="/subscription/packageManager"
                          sx={{
                            borderRadius: "7px",
                            marginBottom: "8px",
                            color: atvbtn === "pm" ? " #279d85" : "",
                            backgroundColor:
                              atvbtn === "pm" ? " RGB(217 240 240)" : "",
                            "&:hover": {
                              background: "RGB(217 240 240)",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <Inventory2Icon className="iconStyle" style={{ fontSize: "1.3rem" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span style={{ fontSize: "1.05rem", fontWeight: "500", margin: "10px 10px 10px -20px" }}>
                                Packager&nbsp;Manager
                              </span>
                            }
                          />
                        </ListItem>
                      </List>
                    </div>
                </Box>
              )}

             
            </div>
            <Box sx={{ width: "100vw", p: 2, }}>
              <Home />
            </Box>
            <Box sx={{ display: { xs: "none", md: "none", lg: "block" } }}  >
              <ChatBox />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
SideNav.propTypes = {
  window: PropTypes.func,
};
export default SideNav;


