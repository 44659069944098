import { Avatar, Box, Stack, TextField, InputAdornment, IconButton, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material'
import React from 'react'
import SendIcon from '@mui/icons-material/Send';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useState } from 'react';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CollectionsIcon from '@mui/icons-material/Collections';
import DescriptionIcon from '@mui/icons-material/Description';



const Footer = () => {
  const [openPicker, setOpenPicker] = useState(false)
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const anchorRef1 = React.useRef(null);
 
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleTogglePicker = () => {
    setOpenPicker((prevOpen) => !prevOpen);
  };

 

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleClosePicker = (event) => {
    if (anchorRef1.current && anchorRef1.current.contains(event.target)) {
      return;
    }

    setOpenPicker(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Stack  >
        <ClickAwayListener onClickAway={handleClosePicker}>
          <Box  sx={{
            display: openPicker ? "inline" : "none", width: "100px", marginBottom: "-20%",
            transform: "translateY(-20%)"
          }}>
            <Picker data={data} onEmojiSelect={console.log} />
          </Box>
          </ClickAwayListener>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type a message"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <ControlPointIcon sx={{ color: 'gray', cursor: 'pointer' }} />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" >
                  <IconButton ref={anchorRef1} onClick={handleTogglePicker} >
                    <SentimentSatisfiedAltIcon sx={{ color: 'gray', cursor: 'pointer' }} />
                  </IconButton>
                  <IconButton>
                    <SendIcon sx={{ color: 'blue', cursor: 'pointer' }} />

                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: "-50px",
              borderRadius: '20px',
              border: '1px solid #E0E0E0',
              backgroundColor: '#FFFFFF',
              // padding: "10px 15px",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
        </Stack>
      </Box>

      <div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="top-start"
          transition
          disablePortal
          sx={{

            boxShadow: "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'top-start' ? 'left top' : 'left bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleClose}> <DescriptionIcon sx={{ color: "blue", marginRight: "5px" }} /> Documents</MenuItem>
                    <MenuItem onClick={handleClose}> <CollectionsIcon sx={{ color: "blue", marginRight: "5px" }} /> Photos & Videos</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>



    </>
  )
}

export default Footer