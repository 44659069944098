import { useState, useEffect } from "react";
import { Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import FacebookLogin from "@greatsumini/react-facebook-login";
import {
  Grid,
  TextField,
} from "@mui/material";
import { Avatar } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import PowerIcon from '@mui/icons-material/Power';
import Assessment from '@mui/icons-material/Assessment';
import WarningIcon from '@mui/icons-material/Warning';
import facebookicon from '../../../assets/facebookicon.png'
import facebook from '../../../assets/facebook.png'
import backendUrl from "../../../configmain";
import toast, { Toaster } from "react-hot-toast";
const URL = `${backendUrl}/api/auth/getAllPages`;
export default function AccountManagement() {
  const [facebookUsersData, setFacebookUsersData] = useState("");
  const [botStatus, setBotStatus] = useState("")
  const [pagesData, setPagesData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [id, setId] = useState('')
  const [dataFetched, setDataFetched] = useState(false); // Track whether data was fetched
  const [loginRequired, setLoginRequired] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userLoggedIn, setUserLoggedIn] = useState(false);


  // console.log(pagesData)

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleChangePage(event, value) {
    setPage(value);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  let token = localStorage.getItem("authorization");

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [data, setData] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [warning, setWarning] = useState(false)


  const handleLoginSuccess = async (response) => {
    try {
      const headers = {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      };

      const { accessToken, ...userData } = response;
      const url = `${backendUrl}/api/auth/login/facebook`;

      const loginResponse = await axios.post(
        url,
        {
          accessToken: response.accessToken,
          ...userData,
        },
        {
          headers,
        }
      );

      if (loginResponse) {
        const userData = loginResponse.data;
        setIsLoggedIn(true);
        setData(userData);
        setUserLoggedIn(true);
        fetchData();

        // window.location.reload();
      } else {
        setErrorModalOpen(true);
        // console.log("Error");
      }
    } catch (error) {
      // console.log(error)
      // if (error.response && (error.response.status === 500 || error.response.status === 400)) {
      //   setErrorModalOpen(true);

      // }
            const errorMessage = error.response
            ? error.response.data.message
            : 'An error occurred';
       
        toast.error(errorMessage)
    }
  };
  useEffect(() => {
    fetchData(); // Call the fetching function inside useEffect
  }, [token]);

  const handleModalClose = () => {
    setErrorModalOpen(false);
  };

  // Api for botStatus
  const updateBotStatus = async () => {
    try {

      const newStatus = !botStatus;
      const urlBot = `${backendUrl}/api/user/botEnable/${id}`

      const response = await axios.put(urlBot, { botStatus: newStatus }, {
        headers: {
          Authorization: `${token}`
        }
      }
      );
      if (response) {
        handleDialogClose();
        setBotStatus(newStatus);
        // console.log('Update successful:', response.data);

      }
    } catch (error) {
      // console.error('Error updating:', error);
    }
  };

  // Make the GET request
  const fetchData = () => {
    axios
      .get(URL, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        // console.log("Data fetched:", response); // Debugging log
        setPagesData(response?.data?.pages);
        setFacebookUsersData(response?.data?.facebookUsers);
        setBotStatus(response?.data?.users?.botStatus);
        setId(response?.data?.users?._id);
        setDataFetched(true);
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        if (error.response && error.response.status === 404) {
          setLoginRequired(true);
        }
      });
  };








  const handleOpenErrorModal = () => {
    setWarning(true);
  };

  const handleCloseErrorModal = () => {
    setWarning(false);
  };

  const deleteAccountDetails = async () => {
    try {

      const deleteUrl = `${backendUrl}/api/user/deleteAll`

      const response = await axios.delete(deleteUrl, {
        headers: {
          Authorization: `${token}`
        }
      }
      );
      if (response) {
        // console.log('deleted successfully:', response.data);
        setWarning(false);
        window.location.reload();

      }

    } catch (error) {
      // console.error('Error updating:', error);

    }
  }
  // console.log(facebookUsersData,"raja ji")


  const handleDelete=async(id)=>{
    // console.log(id)
    try {

      const deletedata = `${backendUrl}/api/user/${id}`

      const response = await axios.delete(deletedata, {
        headers: {
          Authorization: `${token}`
        } 
      }
      );
      if (response) {
        // console.log('deleted successfully:', response);
        // window.location.reload();
        // setPagesData(response.data)
        toast.success(response.data.message)
      }
    } catch (error) {
      // console.error('Error updating:', error);
    }
  }
  return (
    <>
      <Box sx={{ width: "100%", p: 2 }}>
        <Toaster/>
        <Box
          sx={{
            justifyContent: "flex-end",
            display: "flex",
            marginLeft: "auto",
          }}
        >
          {dataFetched ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenErrorModal}>
              Unlink Account
            </Button>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="70vh"
              width="100%"

            >
              <Card
                sx={{
                  // p: 1,
                  display: "flex",
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',  // This ensures the contents are centered vertically
                  borderRadius: "15px",
                  marginBottom: "60px"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", marginRight: "65px" }}>
                  <div>
                    <img src={facebook} alt="facebook icon" name="myImage" 
                    width={{xs:"200", md:"200", lg:"250"}} 
                     height="120" />
                  </div>
                  <Typography sx={{ display: "flex", flexDirection: "column" }}>

                    <span style={{ fontSize: "22px", fontWeight: "bold", color: "#279d85" }}>Connect</span>
                    <span style={{ fontSize: "22px", fontWeight: "bold", color: "#3b5998" }}>Facebook &</span>
                    <span style={{ fontSize: "22px", fontWeight: "bold", color: "#d62976" }}>Instagram</span>
                  </Typography>
                </div>
                <CardContent>

                  <FacebookLogin
                    appId="1024463575609308"
                    onSuccess={handleLoginSuccess}
                    style={{
                      backgroundColor: '#4267b2',
                      color: '#fff',
                      fontSize: '18px',
                      padding: '13px 11px',
                      border: 'none',
                      borderRadius: '4px',
                      width: "300px"
                    }}
                    onFail={(error) => {
                      console.log('Login Failed!', error);
                    }}
                    onProfileSuccess={(response) => {
                      console.log('Get Profile Success!', response);
                    }}
                  />

                </CardContent>
              </Card>
              <Typography variant="h6" color="textSecondary" align="center" >
                Please log in with Facebook to see details.......
              </Typography>
            </Box>
          )}
          {isLoggedIn}
        </Box>
      </Box>
      <Box p={2}>
        {loginRequired ? (
          // <Typography variant="h6" color="textSecondary" align="center" >
          //   Please log in with Facebook to see details.
          // </Typography>
          console.log("hello")
        ) : (
          <Box
            sx={{
              display: "flex",
        
              justifyContent: "space-between",
            
            }}
          >
            <Box sx={{ display: "flex", }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box>
                  <Avatar
                    alt={facebookUsersData?.name}
                    src={facebookUsersData?.picture?.url}
                    sx={{ width: 50, height: 50, marginBottom: "8px" }}
                  />
                </Box>
                <Box>
                  <Typography variant="h6">{facebookUsersData?.name}</Typography>
                  <Typography variant="body1">
                    {facebookUsersData?.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <TextField
                size="small"
                id="search"
                placeholder="Search..."
                fullWidth
              />
            </Box>
          </Box>
        )}
        {pagesData.length > 0 && (
          <Grid container spacing={1} mt={3}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead sx={{ background: "#111827", }}>
                  <TableRow >
                    <TableCell sx={{ color: "#fff", fontWeight: "bold", textAlign: "center", justifyContent: "center", }}>Photo</TableCell>
                    <TableCell sx={{ color: "#fff", fontWeight: "bold", textAlign: "center", justifyContent: "center", }}> Page Name</TableCell>
                    <TableCell sx={{ color: "#fff", fontWeight: "bold", textAlign: "center", justifyContent: "center", }}>ID</TableCell>
                    <TableCell sx={{ color: "#fff", fontWeight: "bold", textAlign: "center", justifyContent: "center", }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagesData.map((item, index) => (
                    <TableRow key={index}>
                      
                      <TableCell sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: "center" }}>
                        <img src={item.pagePicture?.url} alt="Image" style={{ borderRadius: "50px", display: 'flex', alignItems: 'center', gap: 1, justifyContent: "center" }} />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>{item.name}</TableCell>
                      <TableCell sx={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>{item.id}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: "center" }}>
                          <Button
                            variant="contained"
                            startIcon={<Assessment />}
                            sx={{
                              '&:hover': {
                                backgroundColor: '#000fff',
                                color: "#fff"
                              },
                            }}
                          >
                            Analytics
                          </Button>
                          <Box
                            sx={{
                              background: botStatus ? "#4CAF50" : "#FF5733",
                              color: "#fff",
                              borderRadius: '3px',
                              justifyContent: 'center',
                              padding: '5px 10px',
                              fontSize: '12px',
                              cursor: "pointer",
                              alignItems: "center",
                              textAlign: "center",
                              transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect
                              "&:hover": {
                                background: "transparent",
                                border: "1px solid black",
                                color: "#000",
                              },
                            }}
                            onClick={handleDialogOpen}
                          >
                            <PowerIcon />
                          </Box>
                          <Dialog open={dialogOpen} onClose={handleDialogClose} sx={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                            <DialogTitle>
                              <WarningIcon color="error" style={{ marginRight: '8px' }} />
                              Enable Connection
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Do you really want to change the connection status?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={updateBotStatus} color="primary">
                                Ok
                              </Button>
                              <Button onClick={handleDialogClose} color="primary">
                                Cancel
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Box
                            sx={{
                              borderRadius: '3px',
                              justifyContent: 'center',
                              padding: '5px 10px',
                              fontSize: '12px',
                              color: "#fff",
                              background: "red",
                              cursor: "pointer",
                              textAlign: "center",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <DeleteIcon
                             onClick={()=>handleDelete(item._id)}
                              />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {pagesData.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={pagesData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>

      {/* Error Modal */}
      <Dialog open={errorModalOpen} onClose={handleModalClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failed to log in with Facebook. Please try again later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>


      {/* for permament delete warning */}
      <Dialog open={warning} onClose={handleCloseErrorModal}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your account details and data will be deleted permanently. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteAccountDetails} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}