import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import axios from "axios"
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';

import { format, isValid } from 'date-fns';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import backendUrl from "../../../src/configmain"
// import { values } from 'core-js/core/array';
const tableHeader = ['Member', 'Created By', 'Email', 'Contact', 'Last Logged In', 'Actions']
const url = `${backendUrl}/api/teamManager`

const token = localStorage.getItem('authorization');



export default function GroupList() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [userData, setUserData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const [errorStatus, setErrorStatus] = useState(null);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };




    useEffect(() => {
        // Make the axios GET request when the component mounts

        axios.get(`${url}`, {
            headers: {
                Authorization: token,
            },
        })
            .then(response => {
                console.log("Received data:", response);
                const userDataArray = Array.isArray(response.data.data) ? response.data.data : [];
                setUserData(userDataArray);

                console.log(userDataArray, "dygwdyuw");
                console.log(userDataArray.firstName, "firstName");

            })
            .catch(error => {
                console.error('Error fetching data:', error.message);

                if (error.response) {
                    setErrorStatus(error.response.status);
                } else {
                    setErrorStatus(500);
                }
            });
    }, []);




    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    const handleDeleteClick = (user) => {
        setSelectedUser(user);
        setDialogOpen(true);
    };


    const deleteCampaign = () => {
        const deleteUrl = `${backendUrl}/api/campaign/`
        axios.delete(`${deleteUrl}/${selectedUser._id}`, {
            headers: {
                Authorization: `${token}`,
            },
        })
            .then(response => {
                // console.log('User details updated:', response.data);
                setUserData(prevCampaigns => prevCampaigns.filter(campaign => campaign._id !== selectedUser._id));
                setIsModalOpen(false);
                setDialogOpen(false);
            })
            .catch(error => {
                // console.error('Error updating user details:', error);
            });
    };




    return (
        <>
            <Box sx={{ p: 1 }}>

                <TableContainer component={Paper} >
                    <Table sx={{ borderRadius: "50px" }}>
                        <TableHead sx={{ background: "#111827", color: "#fff" }}>
                            <TableRow sx={{ color: "#fff" }}>
                                <TableCell>#</TableCell>
                                {tableHeader.map((header, index) => (
                                    <TableCell key={index}>
                                        <Typography sx={{ textAlign: "center", justifyContent: "center", color: "#fff" }}>
                                            {header}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {errorStatus === 404 || errorStatus === 500 ? (
                                // Show error message for 404 or 500 errors
                                <TableRow>
                                    <TableCell colSpan={tableHeader.length + 1} align="center">
                                        <Typography variant="h6" color="textSecondary">
                                            {errorStatus === 404
                                                ? "The resource was not found."
                                                : "Kindly login to see the posts"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                // userData &&
                                userData.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.firstName}{" "}{item.lastName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.createBy}{" "}{"( "}{item.role}{" )"}</Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.email}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.mobile}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ textAlign: "center", justifyContent: "center" }}>
                                                {isValid(new Date(item.lastLoginAt)) ? (
                                                    format(new Date(item.lastLoginAt), 'yyyy-MM-dd HH:mm:ss')
                                                ) : (
                                                    'Not available'
                                                )}
                                            </Typography>
                                        </TableCell>



                                        <TableCell>
                                            <Typography sx={{ textAlign: "center", justifyContent: "center" }}>
                                                <Box sx={{ display: 'flex', gap: "10px", justifyContent: "center" }}>
                                                    <Tooltip title="Edit" arrow TransitionComponent={Zoom}>
                                                        <Button variant="contained" style={{ backgroundColor: "#279d85", borderRadius: "3px" }}>
                                                            <EditIcon style={{ color: "white" }} />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" arrow TransitionComponent={Zoom} onClick={() => handleDeleteClick(item)}>
                                                        <Button variant="contained" style={{ backgroundColor: "red", borderRadius: '3px', }}>
                                                            <DeleteIcon style={{ color: "white" }} />


                                                        </Button>
                                                    </Tooltip>
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                        {/* <TableCell>
                                            <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{format(new Date(item.createdAt), 'dd MMM yyyy - HH:mm')}</Typography>
                                        </TableCell> */}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>

                    </Table>
                    {userData.length > 0 && (
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            count={userData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </TableContainer>
            </Box>

            {/* Confirmation Dialog */}
            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteCampaign} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
