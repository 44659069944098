import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TextField, Modal, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, Tooltip, InputLabel } from '@mui/material';
import { Box } from '@mui/system';
import axios from "axios"
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
// import { format, isValid } from 'date-fns';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import FormControl from '@mui/material/FormControl';
import { Select, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EventIcon from '@mui/icons-material/Event'; // Calendar icon
// import { motion } from 'framer-motion';
import { Link } from "react-router-dom"
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import backendUrl from "../../../../src/configmain";
// import { values } from 'core-js/core/array';
const tableHeader = ['Name', 'Campaign type', 'Cta button', 'Action', 'Status', 'Scheduled at', 'Error']
const url = `${backendUrl}/api/cta`
const token = localStorage.getItem('authorization');


export default function Ctacast() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = React.useState([null, null]);
  const [selectedValue, setSelectedValue] = useState('');
  const [campaign, setCampaign] = useState("")
  const [errorStatus, setErrorStatus] = useState(null);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    // Make the axios GET request when the component mounts
    axios.get(`${url}`, {
      headers: {
        Authorization: token,
      },
    })
      .then(response => {
        // Update the userData state with the fetched data
        console.log(response)
        setUserData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        console.error('Error fetching CTA posts:', error);
        if (error.response) {
          setErrorStatus(error.response.status);
        } else {
          setErrorStatus(500);
        }
      });
  }, []);
  // const handleCloseDialog = () => {
  //   setDialogOpen(false);
  // };
  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };
  // const deleteCampaign = () => {
  //   const deleteUrl = `${backendUrl}/api/campaign/`
  //   axios.delete(`${deleteUrl}/${selectedUser._id}`, {
  //     headers: {
  //       Authorization: `${token}`,
  //     },
  //   })
  //     .then(response => {
  //       // console.log('User details updated:', response.data);
  //       setUserData(prevCampaigns => prevCampaigns.filter(campaign => campaign._id !== selectedUser._id));
  //       setIsModalOpen(false);
  //       setDialogOpen(false);
  //     })
  //     .catch(error => {
  //       // console.error('Error updating user details:', error);
  //     });
  // };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    // console.log('Selected value:', newValue);
  };

  const handleCampaignChange = (e) => {
    setCampaign(e.target.value)
  }
  const applyFilter = () => {
    const newUrl = backendUrl + url + "/?status=" + selectedValue + "&campaignName=" + campaign
    console.log(newUrl)
    const token = localStorage.getItem('authorization');
    // Make the axios GET request when the component mounts
    axios.get(newUrl, {
      headers: {
        Authorization: token,
      },
    })
      .then(response => {
        // Update the userData state with the fetched data
        console.log(response)
        setUserData(response.data.data);
        // console.log(response.data.data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }


  return (
    <>
      <Box sx={{ p: 1 }}>
        <Box sx={{ mb: 2, mt: 2, }} >
          <Box sx={{ display: "flex", justifyContent: "space-between", textAlign: "center", alignItems: "center" }}>
            <Box>
              <Button
                onClick={handleOpenModal}
                variant="contained"
                color="secondary"
                style={{
                  padding: '1px 7px',
                  color: '#fff',
                  // borderRadius: '20px',
                  backgroundColor: '#279d85'
                }}>
                <IconButton sx={{ color: "#fff" }}>
                  <EventIcon />
                </IconButton>
                Choose Date
              </Button>
              <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Box sx={{
                  position: 'absolute',
                  top: '20%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  backgroundColor: 'white',
                  boxShadow: 24,
                  p: 4,
                }}>
                  <Typography variant="h6" component="h2">
                    Select date range
                  </Typography>
                  <Box mt={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker
                        calendars={1}
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <TextField {...startProps} sx={{ width: "100px", }} size="small" />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField {...endProps} sx={{ width: "100px", }} size="small" />
                          </React.Fragment>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box mt={2} sx={{ display: "flex", gap: 1 }}>
                    <Button color="error" onClick={handleCloseModal}>close</Button>
                    <Button color="success" onClick={handleCloseModal}>save</Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
            <Box sx={{ display: "flex", gap: 3, textAlign: "center", alignItems: "center" }}>

              <Box>
                <FormControl sx={{ width: 200, "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#279d85",
                  }, }}>
                  <InputLabel sx={{
                    "&.Mui-focused": {
                      color: "#279d85", // Change the label color when focused
                    },
                  }}>Select </InputLabel>
                  <Select label="Status" value={selectedValue} onChange={handleSelectChange} sx={{ height: '50px' }}>
                    <MenuItem value="Pending" >Option 1</MenuItem>
                    <MenuItem value="Processing" >Option 2</MenuItem>
                    <MenuItem value="Completed" >Option 3</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '4px', }}>
                <Box
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300 }}
                >
                  <InputBase size="small"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="campaign name"
                    value={campaign}
                    onChange={handleCampaignChange}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search"
                    onClick={applyFilter}
                  >
                    <SearchIcon />
                  </IconButton>

                </Box>
              </div>
              <Box>
                <Button
                  className=""
                  component={Link}
                  to="/fbinstagram/ctaPost/createCTAPost"
                  variant="contained"
                  color="secondary"
                  style={{
                    padding: '8px 10px',
                    color: '#fff',
                    // borderRadius: '20px',
                    backgroundColor: '#279d85'
                  }}
                >
                  {<AddIcon />}
                  Create New Post
                </Button>
              </Box>
            </Box>

          </Box>



        </Box>
        <TableContainer component={Paper} >
          <Table sx={{ borderRadius: "50px" }}>
            <TableHead sx={{ background: "#111827", color: "#fff" }}>
              <TableRow sx={{ color: "#fff" }}>
                <TableCell sx={{ color: "#fff" }}>#</TableCell>
                {tableHeader.map((header, index) => (
                  <TableCell key={index}>
                    <Typography sx={{ textAlign: "center", justifyContent: "center", color: "#fff" }}>
                      {header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {errorStatus === 404 || errorStatus === 500 ? (
                // Show error message for 404 or 500 errors
                <TableRow>
                  <TableCell colSpan={tableHeader.length + 1} align="center">
                    <Typography variant="h6" color="textSecondary">
                      {errorStatus === 404
                        ? "Data not found."
                        : "Kindly login to see the posts"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                userData &&
                userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.campaignName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.mediaType}</Typography>
                    </TableCell>
                    <TableCell>
                      {/* {isValid */}
                      {/* (new Date(item.secheduledTime)) ? ( */}
                      {/* <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{format(new Date(item.secheduledTime), 'dd MMM yyyy - HH:mm')}</Typography> */}
                      {/* ) : ( */}
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.ctaButtonType}</Typography>
                      {/* )} */}
                    </TableCell>
                    <TableCell>
                    <Typography sx={{ textAlign: "center", justifyContent: "center" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                          <Tooltip title="Edit" arrow TransitionComponent={Zoom}>
                            
                              {/* <FuseSvgIcon size={20}>heroicons-outline:pencil</FuseSvgIcon> */}
                              <EditOutlinedIcon/>
                           
                          </Tooltip>
                          <Tooltip title="Delete" arrow TransitionComponent={Zoom} onClick={() => handleDeleteClick(item)}>
                            
                              <DeleteOutlineOutlinedIcon/>
                  
                          </Tooltip>
                        </Box>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>
                        {item.status === true ? <Typography sx={{ color: "green" }}>Active</Typography> : <Typography sx={{ color: "red" }}>Inactive</Typography>}
                      </Typography>
                    </TableCell>
                    <TableCell>
                    
                    </TableCell>
                    <TableCell>
                      {/* <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{format(new Date(item.createdAt), 'dd MMM yyyy - HH:mm')}</Typography> */}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>

          </Table>
          {userData.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={userData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </Box>
    </>
  )
}
