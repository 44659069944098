import React, { useEffect, useState } from 'react'
import ContactListItem from './ContactListItem'
import { Box, List, Typography } from '@mui/material'
import axios from 'axios';
import backendUrl from '../../../configmain.js';

function ContactList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    let token = localStorage.getItem('authorization');
    let headers = {
        'Authorization': `${token}`,
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(`${backendUrl}/api/contactGroup/`, { headers });
                setData(response?.data?.groupData);
                console.log(response)
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    const handleDeleteSuccess = () => {
        // fetchData();
    };

    if (loading) {
        return (
          <div className="flex flex-1 items-center justify-center h-full">
            <Typography color="text.secondary" variant="h5">
              Loading...
            </Typography>
          </div>
        );
      }
    
      if (data.length === 0) {
        return (
          <div className="flex flex-1 items-center justify-center h-full">
            <Typography color="text.secondary" variant="h5">
              There are no contacts!
            </Typography>
          </div>
        );
      }
    return (
        <>
            <Box className="flex flex-col flex-auto w-full max-h-full">
                <div className="relative">
                    
                    <List className="w-full m-0 p-0">
                        {data?.map((item) => (
                            <ContactListItem key={item._id} contact={item} onDeleteSuccess={handleDeleteSuccess}
                            />
                        ))}
                    </List>
                </div>
            </Box>
        </>
    )
}

export default ContactList
