import { faker } from '@faker-js/faker/locale/af_ZA';
import React from 'react'
import { Box, Stack } from "@mui/material"
import p1 from "../../assets/p1.jpg"
import { DocMsg, LinkMsg, MediaMsg, ReplyMsg, TextMsg, Timeline, } from './MessageType';
const Chat_History = [
    {
        type: "msg",
        message: "Hi 👋🏻, How are ya ?",
        incoming: true,
        outgoing: false,
    },
    {
        type: "divider",
        text: "Today",
    },
    {
        type: "msg",
        message: "Hi 👋 Panda, not bad, u ?",
        incoming: false,
        outgoing: true,
    },
    {
        type: "msg",
        message: "Can you send me an abstarct image?",
        incoming: false,
        outgoing: true,
    },
    {
        type: "msg",
        message: "Ya sure, sending you a pic",
        incoming: true,
        outgoing: false,
    },

    {
        type: "msg",
        subtype: "img",
        message: "Here You Go",
        img: p1,
        incoming: true,
        outgoing: false,
    },
    {
        type: "msg",
        message: "Can you please send this in file format?",
        incoming: false,
        outgoing: true,
    },

    {
        type: "msg",
        subtype: "doc",
        message: "Yes sure, here you go.",
        incoming: true,
        outgoing: false,
    },
    {
        type: "msg",
        subtype: "link",
        preview: faker.image.cats(),
        message: "Yep, I can also do that ",
        incoming: true,
        outgoing: false,
    },
    {
        type: "msg",
        subtype: "reply",
        reply: "This is a reply",
        message: "Yep, I can also do that",
        incoming: false,
        outgoing: true,
    },
];

export default function Message() {
    return (
        <>
            <Box p={3}>
                <Stack spacing={3}>
                    {
                        Chat_History.map((el) => {
                            switch (el.type) {
                                case "divider":
                                    {/* timeline */ }
                                    return <Timeline el={el} />;
                                case "msg":
                                    switch (el.subtype) {
                                        case "img":
                                            {/* img */ }
                                            return <MediaMsg el={el} />
                                        case "doc":
                                            {/* doc */ }
                                            return <DocMsg el={el}/>
                                          
                                        case "link":
                                            {/* link */ }
                                            return <LinkMsg el={el}/>
                                        case "reply":
                                            return <ReplyMsg el={el}/>
                                        default:
                                            //text Message
                                            return <TextMsg el={el} />
                                    }
                                 
                                default:
                                    return <></>
                            }
                        })
                    }
                </Stack>
            </Box>
        </>
    )
}