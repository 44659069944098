const languageOptions = [
    { code: "af", label: "Afrikaans" },
    { code: "en_GB", label: "English (UK)" },
    { code: "en_US", label: "English (US)" },
    { code: "sq", label: "Albanian" },
    { code: "ar", label: "Arabic" },
    { code: "az", label: "Azerbaijani" },
    { code: "bn", label: "Bengali" },
    { code: "bg", label: "Bulgarian" },
    { code: "ca", label: "Catalan" },
    { code: "zh_CN", label: "Chinese (CHN)" },
    { code: "zh_HK", label: "Chinese (HKG)" },
    { code: "zh_TW", label: "Chinese (TAI)" },
    { code: "hr", label: "Croatian" },
    { code: "cs", label: "Czech" },
    { code: "da", label: "Danish" },
    { code: "nl", label: "Dutch" },
    { code: "en", label: "English" },
    { code: "et", label: "Estonian" },
    { code: "fil", label: "Filipino" },
    { code: "fi", label: "Finnish" },
    { code: "fr", label: "French" },
    { code: "de", label: "German" },
    { code: "el", label: "Greek" },
    { code: "gu", label: "Gujarati" },
    { code: "ha", label: "Hausa" },
    { code: "he", label: "Hebrew" },
    { code: "hi", label: "Hindi" },
    { code: "hu", label: "Hungarian" },
    { code: "id", label: "Indonesian" },
    { code: "ga", label: "Irish" },
    { code: "it", label: "Italian" },
    { code: "ja", label: "Japanese" },
    { code: "kn", label: "Kannada" },
    { code: "kk", label: "Kazakh" },
    { code: "ko", label: "Korean" },
    { code: "lo", label: "Lao" },
    { code: "lv", label: "Latvian" },
    { code: "lt", label: "Lithuanian" },
    { code: "mk", label: "Macedonian" },
    { code: "ms", label: "Malay" },
    { code: "ml", label: "Malayalam" },
    { code: "mr", label: "Marathi" },
    { code: "nb", label: "Norwegian" },
    { code: "fa", label: "Persian" },
    { code: "pl", label: "Polish" },
    { code: "pt_BR", label: "Portuguese (BR)" },
    { code: "pt_PT", label: "Portuguese (POR)" },
    { code: "pa", label: "Punjabi" },
    { code: "ro", label: "Romanian" },
    { code: "ru", label: "Russian" },
    { code: "sr", label: "Serbian" },
    { code: "sk", label: "Slovak" },
    { code: "sl", label: "Slovenian" },
    { code: "es", label: "Spanish" },
    { code: "es_AR", label: "Spanish (ARG)" },
    { code: "es_ES", label: "Spanish (SPA)" },
    { code: "es_MX", label: "Spanish (MEX)" },
    { code: "sw", label: "Swahili" },
    { code: "sv", label: "Swedish" },
    { code: "ta", label: "Tamil" },
    { code: "te", label: "Telugu" },
    { code: "th", label: "Thai" },
    { code: "tr", label: "Turkish" },
    { code: "uk", label: "Ukrainian" },
    { code: "ur", label: "Urdu" },
    { code: "uz", label: "Uzbek" },
    { code: "vi", label: "Vietnamese" },
    { code: "zu", label: "Zulu" }
];
module.exports= languageOptions