import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Menu, MenuItem, Button, colors } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import toast, { Toaster } from "react-hot-toast";

import {
  Table,
  TableBody,
  TableCell,
  TextField,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import backendUrl from "../../../../src/configmain";


const useStyles = styled((theme) => ({
  button: {
    borderRadius: "5px",
    border: "1px solid black",

    backgroundColor: "#111827",
    color: "white",
    padding: "6px 16px",
    "& .MuiSvgIcon-root": {
      marginRight: "8px",
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "blue",
    },
  },
}));

export default function Active(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [templates, setTemplates] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Status");
  const [templateName, setTemplateName] = useState("");
  const [status, setStatus] = useState("");
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  let token = localStorage.getItem("authorization");

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleCancelDelete = () => {
    setIsDialogOpen(false);
  };

  const handleMenuItemClick = (value) => {
    setSelectedValue(value);
    setStatus(value);
    setAnchorEl(null);
  };

  const columns = [
    { id: "name", label: "Name", align: "center" },
    { id: "createdby", label: "Created By", align: "center" },
    {
      id: "category",
      label: "Category",

      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "preview",
      label: "Preview",

      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "language",
      label: "Language",

      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "Status",
      label: "Status",

      align: "center",
      format: (value) => value.toFixed(2),
    },

    {
      id: "delete",
      label: "Action",

      align: "center",
      format: (value, row) => (
        <IconButton
          color="error"
          style={{ fontSize: "24px" }}
          onClick={() => handleDeleteClick(row._id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  function createData(
    _id,
    name,
    createdby,
    category,
    preview,
    language,
    Status,
    action
  ) {
    return {
      _id,
      name,
      createdby,
      category,
      preview,
      language,
      Status,
      action,
    };
  }

  let url = `${backendUrl}/api/whatsapp/`;

  useEffect(() => {
    setLoading(true);
    const queryParams = new URLSearchParams();
    if (templateName) {
      queryParams.append("name", templateName);
    }
    if (status) {
      queryParams.append("status", status);
    }

    const requestUrl = `${url}${queryParams.toString() ? "?" + queryParams.toString() : ""}`;

    axios
      .get(requestUrl, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const transformedData = response.data.map((item) =>
          createData(
            item._id,
            item.name,
            item.createdBy,
            item.category,
            item.preview,
            item.language,
            item.status,
            item.action
          )
        );
        setTemplates(transformedData);
        setLoading(false);
        // console.log(transformedData);
      })

      .catch((error) => {
        console.error("Error fetching templates:", error);
        console.error("Error fetching campaigns posts:", error);
        if (error.response) {
          setErrorStatus(error.response.status);
        } else {
          setErrorStatus(500);
        }
        setLoading(false);
      });
  }, [templateName, status]);

  function handleChangePage(event, value) {
    setPage(value);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  const handleDeleteClick = (itemId) => {
    setSelectedTemplate(itemId);
    setIsDialogOpen(true);
  };

  

  const handleConfirmDelete = async(itemId) => {
    axios
      .put(
        `${url}${selectedTemplate}`,
        { deleted: true },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
          if (response.status === 200) {
            // Update the state to remove the deleted item
            setTemplates((prevTemplates) =>
              prevTemplates.filter((template) => template.id !== itemId)
            );
            toast.success("Template deleted Succesfully")
          } else {
            console.error(`Unexpected status code: ${response.status}`);
          }
    
          setIsDialogOpen(false);
        
      })
      .catch((error) => {
        toast.error(`Error: ${error.response.status} - ${error.message}`);
        console.log(error.message);
      })
      .finally(() => {
        setIsDialogOpen(false);
      });
  };

  const refreshData = () => {
    axios.get(`${url}refresh`, {
      headers: {
        Authorization: token, // No need for `${}` here
      }
    })
    .then(response => {
      console.log(response.data)
        const payload = response.data.map((item) =>
          createData(
            item._id,
            item.name,
            item.createdBy,
            item.category,
            item.preview,
            item.language,
            item.status,
            item.action
          )
        );
        setTemplates(payload);
      // setTemplates(response.data)
     
      if (response.status === 200) {
        toast.success("Data refreshed successfully");
      } else {
        toast.error("Failed to refresh data");
      }
    })
    .catch(error => {
      console.error(`Error: ${error.message}`);
      toast.error(`Error: ${error.response ? error.response.status : 'Unknown'} - ${error.message}`);
    });
  };
  

  // const refreshData = () => {
  //   try {
  //     const response = axios.get(`${url}refresh`, {
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //     });

  //     // setTemplates(response)

  //     console.log(response)

  //     if (response) {
  //       // window.location.reload();
  //     } else {
  //      toast.error("Failed to refresh data");
  //     }
  //   }
  //   catch (error) {
  //     toast.error(`Error: ${error.response.status} - ${error.message}`);
  //   }
  // };

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Toaster/>
        <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
          <Button
            className={classes.button}
            variant="content"
            endIcon={<ArrowDropDownIcon style={{color:"#fff"}} />}
            onClick={handleButtonClick}
            style={{ paddingRight: "5px" , backgroundColor:"#111827" , color:"#fff" }}
  
            
          >
            {selectedValue !== null ? selectedValue : "Select Status"}
          </Button>
          <Menu
            id="selector-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              sx={{ margin: "3.5px" }}
              onClick={() => handleMenuItemClick("Approved")}
            >
              Approved
            </MenuItem>
            <MenuItem
              sx={{ margin: "3.5px" }}
              onClick={() => handleMenuItemClick("Pending")}
            >
              Pending
            </MenuItem>
            <MenuItem
              sx={{ margin: "3.5px" }}
              onClick={() => handleMenuItemClick("Rejected")}
            >
              Rejected
            </MenuItem>
          </Menu>
          <Box>
            <TextField
              size="small"
              id="search"
              placeholder="Search..."
              fullWidth
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#279d85",
                  },
              }}
              InputProps={{
                endAdornment: templateName ? (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      
                      onClick={() => setTemplateName("")}
                    >
                      <ClearIcon style={{color:"red"}}  />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Box>
          <IconButton
            size="small"
            onClick={async () => {
              await refreshData();
            }}
          >
            <RefreshSharpIcon />
          </IconButton>
        </Box>
        <Paper
          sx={{
            width: "100%",
           
            mt: 3,
            border: "1px solid grey",
          }}
        >
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{ background: "#111827", color: "#fff" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {errorStatus === 404 || errorStatus === 500 ? (
                  // Show error message for 404 or 500 errors
                  <TableRow>
                    <TableCell colSpan={columns.length + 1} align="center">
                      <Typography  color="textSecondary" style={{color:"#222", fontSize:"14px", padding:"15px 0px"}}>
                        {errorStatus === 404
                          ? "No Templates found "
                          : "Kindly login to see the posts"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  templates &&
                  templates
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.name}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "delete"
                                ? column.format(value, row)
                                : column.format && typeof value === "number"
                                ? column.format(value, row)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            templates.length === 0 && (
              <Box sx={{ p: 2 }}>
                <Typography variant="body1" align="center">
                </Typography>
              </Box>
            )
          )}

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={templates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Dialog
          open={isDialogOpen}
          onClose={handleCancelDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Template"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this template?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              No
            </Button>
            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}