import React, { useState } from "react";
import {
    Box,
    Button,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    InputAdornment,
    FormControlLabel,
    Typography,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import CancelIcon from '@mui/icons-material/Cancel';
import TelegramIcon from "@mui/icons-material/Telegram";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import backendUrl from "../../../../../src/configmain"



const timeZones = [
    { value: '', label: 'None' },
    { value: 'Etc/GMT+1', label: 'GMT +1:00' },
    { value: 'Etc/GMT+2', label: 'GMT +2:00' },
    { value: 'Etc/GMT+3', label: 'GMT +3:00' },
    { value: 'Etc/GMT+4', label: 'GMT +4:00' },
    { value: 'Etc/GMT+5', label: 'GMT +5:00' },
    { value: 'Etc/GMT+6', label: 'GMT +6:00' },
    { value: 'Etc/GMT+7', label: 'GMT +7:00' },
    { value: 'Etc/GMT+8', label: 'GMT +8:00' },
    { value: 'Etc/GMT+9', label: 'GMT +9:00' },
    { value: 'Etc/GMT+10', label: 'GMT +10:00' },
    { value: 'Etc/GMT+11', label: 'GMT +11:00' },
    { value: 'Etc/GMT+12', label: 'GMT +12:00' },
    { value: 'Etc/GMT+13', label: 'GMT +13:00' },
];




const CreateCarouselForm = ({ selectedTab }) => {
    const [carousels, setCarousels] = useState([
        { sliderLink: "", textMessage: "" },
    ]);
    const [isSwitchOn, setIsSwitchOn] = React.useState(true);
    const [selectedTimeZone, setSelectedTimeZone] = useState('');
    const [selectedDateTime, setSelectedDateTime] = useState(dayjs('2022-04-17T15:30'));
    const [timeInterval, setTimeInterval] = useState("")
    const [postReport, setPostReport] = useState('');
    const [pageOptions, setPageOptions] = useState([]);
    const [pages, setPages] = useState([])
    const [sliderLink, setSliderLink] = useState('');
    const [textMessage, setTextMessage] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [fileLink, setFileLink] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState(null);


    //--------------------axios call for getPages to show on selectpages scroll----------------//
    const token = localStorage.getItem('authorization');
    let headers = {
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
    };
    useEffect(() => {
        axios.get(`${backendUrl}/api/user/getPages `, {
            headers
        })
            .then(response => {
                setPageOptions([response.data.getPage]);

                console.log(response.data.getPage)
            })
            .catch(error => {
                console.error('Error fetching page options:', error);
            });
    }, []);

    const handlePages = (event) => {
        setPages(event.target.value);

    }
    const handlesliderLink = (event) => {
        setSliderLink(event.target.value)
    }
    const handleTextMessege = (event) => {
        setTextMessage(event.target.value)
    }
    const handleGoBack = () => {
        window.history.back();
    };

    const handleCampaignNameChange = (event) => {
        setCampaignName(event.target.value)
    }

    const handleDateTimeChange = (newDateTime) => {
        setSelectedDateTime(newDateTime);
        console.log('Selected DateTime:', newDateTime.format()); // Log the selected date and time
    };

    const handleTimeIntervalChange = (event) => {
        setTimeInterval(event.target.value)
    }

    const handlePostReportChange = (event) => {
        setPostReport(event.target.value)
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const carouselData =  carousels.map((carousel, index) => ({
            title: carousel.title,
            actionLink: carousel.actionLink,
            description: carousel.description,
            imageLink: carousel.imageLink,
            imageUrl: carousel.imageUrl,
        }));


        const postCarouselData = {
            campaignName: campaignName,
            postToPages: pages,
            sliderLink: sliderLink,
            messege: textMessage,
            postType: selectedTab === 0 ? "Carousel" : "Video Slide",
            carousel: carouselData,
            postingTime: isSwitchOn,
            ...(!isSwitchOn
                ? {} // If switch is off, no additional fields are included in the payload
                : {
                    repostThisPost: postReport,
                    secheduledTime: selectedDateTime.toISOString(),
                    timeZone: selectedTimeZone,
                    timeInterval: timeInterval,
                }),
        };
        axios.post(`${backendUrl}/api/carousel `, postCarouselData, {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                console.log("Post request successful:", response.data);
                // Handle success if needed
            })
            .catch(error => {
                console.error('Error posting data:', error);
                // Handle error if needed
            });
    };
    const handleTimeZoneChange = (event) => {
        const newTimeZone = event.target.value;
        setSelectedTimeZone(newTimeZone);
        const selectedTimeZoneObj = timeZones.find((zone) => zone.value === newTimeZone);

        // Log the selected time zone's data
        if (selectedTimeZoneObj) {
            console.log('Selected Time Zone:', selectedTimeZoneObj.label);
            console.log('Offset:', selectedTimeZoneObj.offset);
        }
    };

    const addCarousel = () => {
        setCarousels([...carousels, { sliderLink: "", textMessage: "" }]);
    };

    const handleCarouselChange = (index, field, value) => {
        // const updatedCarousels = [...carousels];
        // updatedCarousels[index][field] = value;
        // setCarousels(updatedCarousels);
        setSelectedFile(value.target.files[0]);
        console.log(value.target.files[0])
    };



    const handleSwitchChange = (event) => {
        setIsSwitchOn(!isSwitchOn);

    };



    const handleUpload = () => {
        const formData = new FormData();
        let token = localStorage.getItem("authorization");
        let headers = {
          Authorization: token,
        };
    
        formData.append('file',  selectedFile);
        axios.post(`${backendUrl}/api/user/upload`, formData, { headers })
          .then(response => {
            const uploadedLink = response.data.link
            setFileLink(uploadedLink);
          
          })
          .catch(error => {
            // console.error('Error uploading file:', error);
          });
      };




    return (
        <Box>
            <Typography variant="h6">Carousel</Typography>

            <Divider sx={{ mt: 1, mb: 2 }} />
            <Box display="flex" flexDirection="row" sx={{ gap: 1 }} >
                <Box sx={{width: "100%" , '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                    <InputLabel sx={{ mb: 1 }} >Campaign name</InputLabel>
                    <TextField value={campaignName} onChange={handleCampaignNameChange} fullWidth sx={{ mr: 2 }} />
                </Box>
                <Box sx={{width: "100%" , '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                    <InputLabel sx={{ mb: 1 }} id="demo-simple-select-helper-label">Post to pages</InputLabel>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <Select

                            value={pages}
                            onChange={handlePages}
                        // label="Select pages"
                        >
                            <MenuItem value="">Select</MenuItem>
                            {pageOptions.map(option => (
                                <MenuItem key={option._id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Box sx={{ mt: 3, display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'flex-end', justifyContent: "center", alignItems: "center" }}>
                <Box sx={{width: "100%" , '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                    <InputLabel sx={{ mb: 1 }} id="demo-simple-select-helper-label">Slider Link</InputLabel>
                    <TextField
                        // label="Slider Link"
                        fullWidth
                        value={sliderLink}
                        onChange={handlesliderLink}
                    />
                </Box>
                <Box sx={{width: "100%" , '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                    <InputLabel sx={{ mb: 1 }} id="demo-simple-select-helper-label">Message</InputLabel>
                    <TextField
                        // label="Message"
                        fullWidth
                        multiline
                        rows={4}
                        value={textMessage}
                        onChange={handleTextMessege}
                    />
                </Box>
            </Box>


            {carousels.map((carousel, index) => (
                <Box key={index}>
                    <Typography variant="h6">Slider {index + 1}</Typography>
                    <Box sx={{ mt: 3, display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>

                        <div>
                            <InputLabel sx={{ mb: 1 }} id="demo-simple-select-helper-label">Title</InputLabel>

                            <TextField
                                fullWidth
                                sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#279d85",
                                  },}}
                                value={carousel.title}
                                onChange={(e) =>
                                    handleCarouselChange(index, "title", e.target.value)
                                }

                            />
                        </div>
                        <div>
                            <InputLabel sx={{ mb: 1 }} id="demo-simple-select-helper-label">Action Link</InputLabel>

                            <TextField
                                sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#279d85",
                                  },}}
                                fullWidth
                                value={carousel.actionLink}
                                onChange={(e) =>
                                    handleCarouselChange(index, "actionLink", e.target.value)
                                }

                            />
                        </div>
                        <div>
                            <InputLabel sx={{ mb: 1 }} id="demo-simple-select-helper-label">Description</InputLabel>

                            <TextField
                               sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#279d85",
                              },}}
                                fullWidth
                                multiline
                                rows={4}
                                value={carousel.description}
                                onChange={(e) =>
                                    handleCarouselChange(index, "description", e.target.value)
                                }

                            />
                        </div>
                        <div>
                            <InputLabel sx={{ mb: 1 }}  id="demo-simple-select-helper-label">Image Link</InputLabel>

                            <TextField
                                sx={{'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#279d85",
                                  },}}
                                fullWidth
                                value={carousel.imageLink}
                                onChange={(e) =>
                                    handleCarouselChange(index, "imageLink", e.target.value)
                                }

                            />
                            <Box>

                            <input
                                style={{ marginTop: "3%" }}
                                type="file"
                                onChange={(e) => handleCarouselChange(index, "file", e)}
                                accept="image/*"

                                
                            />
                            <Button style={{
                            color: '#279d85',
                            borderColor: '#279d85',
                          }} variant="outlined"
                            onClick={handleUpload}
                          >Upload</Button>
                          {fileLink && <p style={{color:"green"}} > File Uploaded</p>}
                            </Box>
                        </div>
                    </Box>
                </Box>
            ))}
            <Button
                variant="outlined"
                style={{
                    color: '#279d85',
                    borderColor: '#279d85',
                }}
                onClick={addCarousel}
                sx={{
                    mt: 2,
                    marginLeft: "80%",
                }}
            >
                + Add more content
            </Button>
            <Box mt={2}>
                <Typography>Posting time</Typography>

                <FormControlLabel
                    control={
                        <Switch
                            checked={!isSwitchOn}
                            onChange={handleSwitchChange}
                            style={{ color: "#279d85" }}

                        />
                    }
                    label="Post now"
                />
                {isSwitchOn && (
                    <Box mt={2}>
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <Box sx={{ width: "50%" }}>
                                <Typography>Schedule time</Typography>
                                <FormControl sx={{ width: "100%", '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                "DateTimePicker",
                                                "MobileDateTimePicker",
                                                "DesktopDateTimePicker",
                                                "StaticDateTimePicker",
                                            ]}
                                        >
                                            <DemoItem>
                                                <MobileDateTimePicker
                                                    value={selectedDateTime}
                                                    onChange={handleDateTimeChange} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                                <Typography>Time zone</Typography>
                                <FormControl  sx={{ width: "100%", mt: 1 ,  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                                    <Select value={selectedTimeZone} onChange={handleTimeZoneChange}>
                                        {timeZones.map((item, index) => (<MenuItem key={index} value={item.value}>{item.label}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                            <Box sx={{width: "50%", '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                                <Typography>Repost this post</Typography>

                                <FormControl
                                    sx={{ width: "100%", mt: 1 }}
                                    // size="small"
                                    variant="outlined"
                                >
                                    <TextField
                                        // size="small"
                                        type="number"
                                        variant="outlined"
                                        value={postReport}
                                        onChange={handlePostReportChange}
                                        inputProps={{
                                            step: 1,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    Times
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                                <Typography>Time interval</Typography>
                                <FormControl
                                    // size="small"
                                    sx={{ width: "100%", mt: 1 ,  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                      },}}
                                >
                                    <Select value={timeInterval} onChange={handleTimeIntervalChange}>
                                        <MenuItem value="every 1 months">every 1 months</MenuItem>
                                        <MenuItem value="every 2 months">every 2 months</MenuItem>
                                        <MenuItem value="every 6 months">every 6 months</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>

            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', marginTop: "20px" }}>
                <Button
                    variant="outlined"
                    color="success"
                    startIcon={<TelegramIcon />}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    // startIcon={<CancelIcon />}
                    onClick={handleGoBack}
                >
                     <CancelIcon  style={{ marginRight:"7px", fontSize:'20px'}}/>
                    Cancel
                </Button>
            </div>


        </Box>
    );
};

export default CreateCarouselForm;
