import React from "react";
import { Box,} from "@mui/material";
import AllCampaign from "./campaign/AllCampaign";
import Messegesheader from './Campaignheader';

const Messaging = () => {
  return (
    <>
      <Box p={2}>
        <Messegesheader />
        <Box sx={{ width: '100%' }}>
          <AllCampaign />
        </Box>
      </Box>
    </>
  );
}

export default Messaging;
