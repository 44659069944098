import React from 'react'
import GroupHeader from './GroupHeader'
import GroupList from './GroupList'

export default function ContactGroup() {
  return (
    <>
    <GroupHeader/>
    <GroupList/>
    </>
  )
}
