import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Icon, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import backendUrl from "../../../configmain.js"
import { Telegram } from '@mui/icons-material';

export default function EditGroup() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groupName, setGroupName] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${backendUrl}/api/contactGroup/`, { headers });
        setData(response.data.groupData);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  let token = localStorage.getItem('authorization');
    let headers = {
        'Authorization': `${token}`,
    };

    if (loading) {
      return (
        <div className="flex flex-1 items-center justify-center h-full">
          <Typography color="text.secondary" variant="h5">
            Loading...
          </Typography>
        </div>
      );
    }

    const createNewGroup = async (event) => {
        event.preventDefault();

        try {
            const payload = { groupName };
            const response = await axios.post(`${backendUrl}/api/contactGroup/`, payload, { headers });

            if (response) {
                console.log(response.data);
                setDialogMessage('Group created successfully!');
                setDialogOpen(true);
                navigate("/Email/ContactGroup");            }
        } catch (error) {
            console.error('Failed to create group:', error);
            setDialogMessage('Failed to create group!');
            setDialogOpen(true);
        }
    };
  return (
    <>
    <Container>
      <Box display="flex" alignItems="center" gap=".75rem" mb={3}>
        <Icon>
          <AccountBoxIcon />
        </Icon>
        <Typography variant="h5" fontWeight="900">
          Contacts
        </Typography>
      </Box>

      <Box bgcolor="#fff" py={4}>
        <Typography variant="h6" px={4} mb={4} fontWeight="700">
          Update
        </Typography>
        <Divider />

        <Box px={4} mt={4}>
          <FormControl>
            <FormLabel>
              <Typography color="initial" component="p" fontWeight="600">
                Status
              </Typography>
            </FormLabel>

            <RadioGroup row aria-labelledby="status">
              <FormControlLabel
                value="Enable"
                control={<Radio sx={{
                  color: '#2ca58d', '&.Mui-checked': {
                    color: '#2ca58d',
                  }}} />}
                label="Enable"
              />
              <FormControlLabel
                value="Disable"
                control={<Radio sx={{
                  color: '#2ca58d', '&.Mui-checked': {
                    color: '#2ca58d',
                  }}} />}
                label="Disable"
              />
            </RadioGroup>
          </FormControl>
          <Box my={4}>
            <Typography fontWeight="600">Group contact name</Typography>
            <TextField value={groupName} sx={{"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{borderColor: "#279d85"}}} required fullWidth label="" id="group-contact-name" onChange={(e) => setGroupName(e.target.value)} />
          </Box>
        </Box>

        <Divider />

        <Box display="flex" px={4} mt={4} justifyContent="space-between">
          <Button sx={{borderColor: '#000', color: '#000', '&:hover': {borderColor: '#000'}}}
            onClick={() => navigate(-1)}
            variant="outlined"
            
          >
            Back
          </Button>
          <Button color='success'
            onClick={createNewGroup}
            variant="outlined"
          >
            <Telegram/>
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
    <Dialog
    open={dialogOpen}
    onClose={() => setDialogOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
>
    <DialogTitle id="alert-dialog-title">{"Notification"}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {dialogMessage}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => setDialogOpen(false)} color="primary">
            Close
        </Button>
    </DialogActions>
</Dialog>
</>
  );
}