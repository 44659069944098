import React, { useState } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Divider

} from "@mui/material";
import { styled } from "@mui/system";
import CreateCarouselForm from "./CreateCarouselForm";
import CreateVideoSlideForm from "./CreateVideoSlideForm";
const CustomTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#279d85",
    color: "white",
    borderRadius: "0px 0px 0 0",
  },
}));
const CarouselMain = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <>
      <Box>
        <AppBar position="static">
          <Tabs value={selectedTab} onChange={handleTabChange} sx={{ backgroundColor: "white" }}>
            <CustomTab label="Carousel" />
            <CustomTab label="Video Slide" />
          </Tabs>
        </AppBar>
        <Divider sx={{ mt: 2, mb: 2 }} />


        <Box p={3}>
          {selectedTab === 0 && <CreateCarouselForm selectedTab={selectedTab} />}
          {selectedTab === 1 && <CreateVideoSlideForm selectedTab={selectedTab} />}
        </Box>

      </Box>

    </>
  );
};

export default CarouselMain;
