import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TextField, Modal, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, Tooltip, InputLabel } from '@mui/material';
import { Box } from '@mui/system';
import axios from "axios"
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import AddIcon from '@mui/icons-material/Add';
import { format } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { Select, MenuItem } from "@mui/material";
// import Input from '@mui/material/Input';
import EventIcon from '@mui/icons-material/Event'; // Calendar icon
// import { motion } from 'framer-motion';
import { Link } from "react-router-dom"
// import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import backendUrl from "../../../../src/configmain";
// import { values } from 'core-js/core/array';
const headerItem = ['Name', 'Campaign type', 'Publisher', 'Post type', 'Status', 'Scheduled at', 'Action']

const url = `${backendUrl}/api/multimedia`
const token = localStorage.getItem('authorization');


export default function MultimediaPost() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [multimediaData, setmultimediaData] = useState([])
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = React.useState([null, null]);
  const [selectedValue, setSelectedValue] = useState('');
  const [campaign, setCampaign] = useState("")
  const [campaignUrl, setCampaignUrl] = useState("")

  const [errorStatus, setErrorStatus] = useState(null);
  const [pages, setPages] = useState([])
  const [pageOptions, setPageOptions] = useState([]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event?.target?.value);
    setPage(0);
  };


  const handlePages = (event) => {
    setPages(event?.target?.value);

  }
  useEffect(() => {
    axios.get(`${url}`, {
      headers: {
        Authorization: token,
      },
    })
      .then(response => {
        // Update the userData state with the fetched data
        setmultimediaData(response.data.data);
        // console.log(response.data.data)
      })
      .catch(error => {
        console.error('Error fetching Multimedia posts:', error);
        if (error.response) {
          setErrorStatus(error.response.status);
        } else {
          setErrorStatus(500);
        }
      });
  }, []);

  const pageUrl = '/api/user/getPages'
  const headers = {
    'Authorization': `${token}`,
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    axios.get(`${backendUrl}${pageUrl}`, {
      headers
    })
      .then(response => {
        setPageOptions([response.data.getPage]);
      })
      .catch(error => {
        console.error('Error fetching page options:', error.message);
      });
  }, []);


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };

  const deleteMultimediaPost = () => {
    const deleteUrl = "/api/multimedia/"
    axios.delete(`${backendUrl}${deleteUrl}/${selectedUser._id}`, {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then(response => {
        // console.log('User details updated:', response.data);

        setmultimediaData(prevCampaigns => prevCampaigns.filter(campaign => campaign._id !== selectedUser._id));
        // console.log("multipledata", multimediaData)
        setIsModalOpen(false);
        setDialogOpen(false);
      })
      .catch(error => {
        console.error('Error updating user details:', error);
      });
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    // console.log('Selected value:', newValue);
  };

  const handleCampaignChange = (e) => {
    setCampaign(e.target.value)
  }

  // const handleCampaignName = (e) => {
  //   setCampaignUrl(e.target.value)
  // }
  const applyFilter = () => {
    const newUrl = backendUrl + url + "/?mediaType=" + campaignUrl + "&page=" + pages
    // console.log(newUrl)
    const token = localStorage.getItem('authorization');
    axios.get(newUrl, {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then(response => {
        setmultimediaData(response.data.data);
        console.log("response.data.data", multimediaData)
      })
      .catch(error => {
        console.error('Error fetching CTA posts:', error);
      });
  }
  return (
    <>
      <Box sx={{ p: 1 }}>
        <Box sx={{ mb: 2, mt: 2, }} >
          <Box sx={{ display: "flex", justifyContent: "space-between", textAlign: "center", alignItems: "center" }}>
            <Box>
              <Button
                onClick={handleOpenModal}
                variant="contained"
                color="secondary"
                style={{
                  padding: '1px 7px',
                  color: '#fff',
                  // borderRadius: '20px',
                  backgroundColor: '#279d85'
                }}>
                <IconButton sx={{ color: "#fff" }}>
                  <EventIcon />
                </IconButton>
                Choose Date
              </Button>
              <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Box sx={{
                  position: 'absolute',
                  top: '20%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  backgroundColor: 'white',
                  boxShadow: 24,
                  p: 4,
                }}>
                  <Typography variant="h6" component="h2">
                    Select date range
                  </Typography>
                  <Box mt={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker
                        calendars={1}
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <TextField {...startProps} sx={{ width: "100px", }} size="small" />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField {...endProps} sx={{ width: "100px", }} size="small" />
                          </React.Fragment>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box mt={2} sx={{ display: "flex", gap: 1 }}>
                    <Button color="error" onClick={handleCloseModal}>close</Button>
                    <Button color="success" onClick={handleCloseModal}>save</Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
            <Box sx={{ display: "flex", gap: 3, textAlign: "center", alignItems: "center" }}>

              <Box>
                <FormControl sx={{
                  width: 100, "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#279d85",
                  },
                }}>
                  <InputLabel sx={{
                    "&.Mui-focused": {
                      color: "#279d85", // Change the label color when focused
                    },
                  }}>Any type</InputLabel>
                  <Select label="Any type"
                    value={selectedValue} onChange={handleSelectChange} sx={{ height: '50px' }}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="text">Text</MenuItem>
                    <MenuItem value="image">Image</MenuItem>
                    <MenuItem value="video">Video</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={{
                  width: 100, "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#279d85",
                  },
                }}>
                  <InputLabel sx={{
                    "&.Mui-focused": {
                      color: "#279d85", // Change the label color when focused
                    },
                  }}>All page</InputLabel>
                  <Select label="All page" value={pages} onChange={handlePages} sx={{ height: '50px' }}>
                 
                    {Array.isArray(pageOptions) && pageOptions.map((option) => (
                      <MenuItem key={option?._id} value={option?.name}>
                        {option?.name}
                      </MenuItem>
                    ))}

                  </Select>

                </FormControl>
              </Box>


              <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '4px', }}>
                <Box
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300 }}
                >
                  <InputBase size="small"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="campaign name"
                    value={campaign}
                    onChange={handleCampaignChange}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search"
                    onClick={applyFilter}
                  >
                    <SearchIcon />
                  </IconButton>

                </Box>
              </div>
              <Box>
                <Button
                  className=""
                  component={Link}
                  to="/fbinstagram/multimediaPost/CreateNewMultimediaPost"
                  variant="contained"
                  color="secondary"
                  style={{
                    padding: '8px 10px',
                    color: '#fff',
                    // borderRadius: '20px',
                    backgroundColor: '#279d85'
                  }}
                >
                  {<AddIcon />}
                  Create New Post
                </Button>
              </Box>
            </Box>

          </Box>



        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ background: "#111827", color: "#fff" }}>
              <TableRow sx={{ color: "#fff" }}>
                <TableCell>#</TableCell>
                {headerItem.map((header, index) => (
                  <TableCell key={index}>
                    <Typography sx={{ fontWeight: "bold", textAlign: "center", justifyContent: "center", color: "#fff" }}>
                      {header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {errorStatus === 404 || errorStatus === 500 ? (
                // Show error message for 404 or 500 errors
                <TableRow>
                  <TableCell colSpan={headerItem.length + 1} align="center">
                    <Typography variant="h6" color="textSecondary">
                      {errorStatus === 404
                        ? "Data not found."
                        : "Kindly login to see the posts"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                multimediaData &&
                multimediaData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (

                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.campaignName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.mediaType}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.publisher}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{item.mediaType}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>
                        {item.isActive === true ? (
                          <Typography sx={{ color: "green" }}>Active</Typography>
                        ) : (
                          <Typography sx={{ color: "red" }}>Inactive</Typography>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>{format(new Date(item.createdAt), 'dd MMM yyyy - HH:mm')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                          <Tooltip title="Edit" arrow TransitionComponent={Zoom}>
                            <Button variant="contained" style={{ backgroundColor: "#d8e2f0", borderRadius: 15 }}>
                              <EditIcon style={{ color: "black" }} />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete" arrow TransitionComponent={Zoom} onClick={() => handleDeleteClick(item)}>
                            <Button variant="contained" style={{ backgroundColor: "#d8e2f0", borderRadius: 15 }}>
                              <DeleteOutlineIcon style={{ color: "black" }} />
                            </Button>
                          </Tooltip>
                        </Box>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          {multimediaData?.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={multimediaData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteMultimediaPost} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
