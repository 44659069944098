import {
  Typography,
  Box,
  TextField,
  Grid,
  Paper,
  FormControlLabel,
  Switch,
  InputAdornment,
} from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import * as React from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Campaign, TouchApp } from "@mui/icons-material";
import { Divider } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { Avatar } from "@mui/material";
// import { TextareaAutosize } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Input } from '@mui/material';
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
// import FacebookIcon from "@material-ui/icons/Facebook";
import { useState, useEffect } from "react";
import axios from "axios";
import backendUrl from "../../../../../src/configmain";
import toast, { Toaster } from "react-hot-toast";
const URLPreview = "/api/auth/getAllPages";


const timeZones = [
  { value: '', label: 'None' },
  { value: 'Etc/GMT+1', label: 'GMT +1:00' },
  { value: 'Etc/GMT+2', label: 'GMT +2:00' },
  { value: 'Etc/GMT+3', label: 'GMT +3:00' },
  { value: 'Etc/GMT+4', label: 'GMT +4:00' },
  { value: 'Etc/GMT+5', label: 'GMT +5:00' },
  { value: 'Etc/GMT+6', label: 'GMT +6:00' },
  { value: 'Etc/GMT+7', label: 'GMT +7:00' },
  { value: 'Etc/GMT+8', label: 'GMT +8:00' },
  { value: 'Etc/GMT+9', label: 'GMT +9:00' },
  { value: 'Etc/GMT+10', label: 'GMT +10:00' },
  { value: 'Etc/GMT+11', label: 'GMT +11:00' },
  { value: 'Etc/GMT+12', label: 'GMT +12:00' },
  { value: 'Etc/GMT+13', label: 'GMT +13:00' },
];
export default function CtaPostMain() {
  const [isSwitchOn, setIsSwitchOn] = React.useState(true);
  const [pageOptions, setPageOptions] = React.useState([]);
  const [pages, setPages] = React.useState([])
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [selectedMessageType, setSelectedMessageType] = useState('Text')
  const [autoReply, setAutoReply] = useState("")
  const [facebookUsersData, setFacebookUsersData] = useState("");
  const [campaignName, setCampaignName] = useState('');
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [pasteLink, setPasteLink] = useState("")
  const [ctaButton, setCtaButton] = useState("")
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs('2022-04-17T15:30'));
  const [timeInterval, setTimeInterval] = useState("")
  const [isCapmNameErr, setIscampNameErr]=useState(false)
  const [isMsgErr, setIsMsgErr]=useState(false)


  //  for number time 
  const [postReport, setPostReport] = useState('');
  const [apiResponse, setApiResponse] = useState('');

  const handleCampaignNameChange = (event) => {
    setCampaignName(event.target.value);
    // console.log(campaignName)
    setIscampNameErr(false)
  };


  //------------------------------------handle pages-------------------------------------------//

  const handlePages = (event) => {
    setPages(event.target.value);

  }



  const handleMessageChange = (event) => {
    setMessageContent(event.target.value);
    setIsMsgErr(false)
  };


  const handlePasteLink = (event) => {
    setPasteLink(event.target.value)
  }

  const handleCtaButton = (event) => {
    setCtaButton(event.target.value)

  }
  const handleDateTimeChange = (newDateTime) => {
    setSelectedDateTime(newDateTime);
    // console.log('Selected DateTime:', newDateTime.format()); // Log the selected date and time
  };

  const handleTimeIntervalChange = (event) => {
    setTimeInterval(event.target.value)
  }

  const handleAutoReply = (event) => {
    setAutoReply(event.target.value)
  }

  const renderMessageContent = () => {
    if (selectedMessageType === 'Text') {
      return (
        <TextareaAutosize
          rowsMin={5}
          placeholder="Write your message here..."
          value={messageContent}
          onChange={handleMessageChange}
          style={{
            marginTop: '2%',
            width: '100%',
            borderRadius: '4px',
            padding: '8px',
            border : isMsgErr ? "1px solid red" :"1px solid gray",
            borderRadius: isMsgErr ? "5px" :"",
            
          }}
          sx={{
            
            
          }}
        />
      );
    } else if (selectedMessageType === 'Image' || selectedMessageType === 'Video' || selectedMessageType === 'Audio') {
      return (
        <Input type="file" />
      );
    }
  };



  const handleSwitchChange = (event) => {
    setIsSwitchOn(!isSwitchOn);

  };
  const handleGoBack = () => {
    window.history.back();
  };

  //--------------------timezone select ----------------//

  const handleTimeZoneChange = (event) => {
    const newTimeZone = event.target.value;
    setSelectedTimeZone(newTimeZone);

    // Find the selected time zone object
    const selectedTimeZoneObj = timeZones.find((zone) => zone.value === newTimeZone);

    // Log the selected time zone's data
    if (selectedTimeZoneObj) {
      // console.log('Selected Time Zone:', selectedTimeZoneObj.label);
      // console.log('Offset:', selectedTimeZoneObj.offset);
      // You can log any other data associated with the time zone here
    }
  };

  //--------------------axios call for getPages to show on selectpages scroll----------------//

  const token = localStorage.getItem('authorization');
  const url = '/api/user/getPages ';
  const urlAutoreply = '/api/autoReplyTemplate/getAllTemplate';
  const timeNumber = "/api/cta";

  const headers = {
    'Authorization': `${token}`,
    'Content-Type': 'application/json',
  };
  let formData = {
    campaignName: campaignName,
    messege: messageContent,
    pasteLink: pasteLink,
    ctaButtonType: ctaButton,
    postToPages: pages,
    autoReplyTemplate: autoReply,
    postingTime: isSwitchOn,


  }

  if (isSwitchOn) {
    formData = {
      ...formData,
      postingTime: isSwitchOn,
      secheduledTime: selectedDateTime,
      repostThisPost: postReport,
      timeInterval: timeInterval,
    };
  }
  useEffect(() => {
    axios.get(`${backendUrl}${url}`, {
      headers
    })
      .then(response => {
        setPageOptions([response.data.getPage]);
      })
      .catch(error => {
        console.error('Error fetching page options:', error);
      });



  }, []);


  // auto reply  api 

  useEffect(() => {
    axios.get(`${backendUrl}${urlAutoreply}`, {
      headers
    })
      .then(response => {
        // console.log(response?.data?.data)
        setAutoReply(response?.data?.data)

      })
      .catch(error => {
        console.error('Error fetching page options:', error);
      });
  }, [])
  // console.log(autoReply[0]?.pageName)




  //--------------------axios call for preview----------------//
  useEffect(() => {
    // Retrieve the token from local storage
    const token = localStorage.getItem("authorization");

    if (token) {
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };


      axios
        .get(`${backendUrl}${URLPreview}`, config)
        .then((response) => {
          // console.log(response?.data);
          setFacebookUsersData(response?.data?.facebookUsers);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);
  // console.log(pagesData[0]?.category);

  const showSuccessDialog = () => {
    setSuccessDialogOpen(true);
  };

  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);
    window.location.reload()
  };


  // ------------- axios call for the number of the tiem ---------------//


  const handlePostReportChange = (event) => {
    // console.log(event.target.value);
    setPostReport(event.target.value)
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if(!campaignName){
      setIscampNameErr(true);
      return toast.error("Please enter the campaign name !")
    }
    if(!messageContent){
      setIsMsgErr(true);
      return toast.error("Please Enter the message !")
    }

    console.log(formData)

    axios.post(`${backendUrl}${timeNumber}`, formData, {
      headers
    })
      .then(response => {
        console.log(response)
        setApiResponse(response.data);

        if(response.status===200){
          toast.success("Post created successfully")
          // setSuccessDialogOpen(true);
        }

      })
      .catch(error => {
        console.error('Error posting data:', error);
        if(error.response.data.error.status===500){

          return toast.error("CTA validation failed")
        }if(error.response.data.error.status===404){
         return  toast.error("Server Error")
        }else{
          return  toast.error(error.response.data.error.status.messege)
        }
      });
  };

  return (
    <>
      <Box>

        <Box sx={{ p: 2 }}>
          <Toaster/>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  // color: "#000fff",
                  fontSize: "16px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TouchApp sx={{ fontSize: "20px", marginRight: "8px" }} />
                Add cta post
              </Typography>
            </Box>
          </Box>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Paper sx={{ p: 2, borderRadius: 0 }}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "#279d85",
                    }}
                  >
                    <FormatListBulletedIcon
                      sx={{ fontSize: "20px", marginRight: "8px" }}
                    />
                    Campaign Form
                  </Typography>
                  <Divider />
                  <Box mt={2}>
                    <Box>
                      <Typography>Campaign name</Typography>
                      <TextField sx={{
                        mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: isCapmNameErr ? "red" : "#279d85",                          
                        },
                        border : isCapmNameErr ? "1px solid red" :"",
                        borderRadius: isCapmNameErr ? "5px" :"",
                      }} size="small" fullWidth value={campaignName} onChange={handleCampaignNameChange} />
                    </Box>
                    {
                        isCapmNameErr ? (<Typography sx={{color:"red", fontSize:"13px"}} >Please enter campaign name !</Typography>):("")
                      }
                    <Box sx={{ mt: 2 }}>
                      <Typography sx={{
                       
                        
                      }}>Message</Typography>
                      {renderMessageContent()}
                    </Box>
                    {
                        isMsgErr ? (<Typography sx={{color:"red", fontSize:"13px"}} >Please Enter the message !</Typography>):("")
                      }
                    <Box mt={2}>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Box sx={{ width: "50%" }}>
                          <Typography>Paste link</Typography>
                          <FormControl size="small" sx={{
                            width: "100%", mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: "#279d85",
                            },
                          }}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              size="small"
                              value={pasteLink}
                              onChange={handlePasteLink}
                            />
                          </FormControl>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>Cta button type</Typography>

                          <FormControl size="small" sx={{ width: "100%", mt: 1 , '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                            <Select value={ctaButton} onChange={handleCtaButton}>
                              <MenuItem value="Like Page">Like Page</MenuItem>
                              <MenuItem value="Listen Music">Listen Music</MenuItem>
                              <MenuItem value="Message Page">Message Page</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                        <Box sx={{ width: "50%" }}>
                          <Typography>Paste to pages</Typography>


                          <FormControl size="small" variant="outlined" sx={{ width: "100%", mt: 1 ,  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                            <Select labelId="select-label-2" id="select-2" value={pages} onChange={handlePages}>
                              <MenuItem value="">Select</MenuItem>
                              {pageOptions.map(option => (
                                <MenuItem key={option._id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography>Auto reply template</Typography>


                          <FormControl size="small" variant="outlined" sx={{ width: "100%", mt: 1 ,  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                            <Select labelId="select-label-2" id="select-2" value={autoReply} onChange={handleAutoReply}>
                              <MenuItem value="">Select</MenuItem>
                              {Array.isArray(autoReply) && autoReply.length > 0 ? (
                                autoReply.map((item, index) => (
                                  <MenuItem key={index} value={item.messegeForCommentReply}>
                                    {item.messegeForCommentReply}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="no-auto-reply">No Auto Reply Available </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>

                    <Box mt={2}>
                      <Typography>Posting time</Typography>

                      <FormControlLabel
                        control={
                          <Switch
                            style={{
                              color: '#279d85',
                              borderColor: '#279d85',
                            }}
                            checked={isSwitchOn}
                            onChange={handleSwitchChange}
                          />
                        }
                        label="Post later"
                      />
                      {isSwitchOn && (
                        <Box mt={2}>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Box sx={{ width: "50%" }}>
                              <Typography>Schedule time</Typography>
                              <FormControl sx={{ width: "100%", '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DemoContainer
                                    components={[
                                      "DateTimePicker",
                                      "MobileDateTimePicker",
                                      "DesktopDateTimePicker",
                                      "StaticDateTimePicker",
                                    ]}
                                  >
                                    <DemoItem>
                                      <MobileDateTimePicker
                                        value={selectedDateTime}
                                        onChange={handleDateTimeChange}
                                      />
                                    </DemoItem>
                                  </DemoContainer>
                                </LocalizationProvider>
                              </FormControl>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                              <Typography>Time zone</Typography>



                              <FormControl sx={{ width: "100%", mt: 1 ,  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#279d85",
                  },}}>
                                <Select value={selectedTimeZone} onChange={handleTimeZoneChange}>
                                  {timeZones.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                            <Box sx={{ width: "50%" }}>
                              <Typography>Repost this post</Typography>

                              <FormControl
                                sx={{ width: "100%", mt: 1 ,  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: "#279d85",
                                },}}
                                size="small"
                                variant="outlined"
                              >
                                <TextField
                                  size="small"
                                  type="number"
                                  variant="outlined"
                                  value={postReport}
                                  onChange={handlePostReportChange}
                                  inputProps={{
                                    step: 1,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        Times
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </FormControl>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                              <Typography>Time interval</Typography>
                              <FormControl
                                size="small"
                                sx={{ width: "100%", mt: 1 ,  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: "#279d85",
                                },}}
                              >
                                <Select value={timeInterval} onChange={handleTimeIntervalChange}>
                                  <MenuItem value="every 1 months">every 1 months</MenuItem>
                                  <MenuItem value="every 2 months">every 2 months</MenuItem>
                                  <MenuItem value="every 6 months">every 6 months</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                     
                      <Button
                        variant="outlined"
                        color="error"
                        // startIcon={<CancelIcon />}
                        onClick={handleGoBack}
                      >
                         <CancelIcon  style={{ marginRight:"7px", fontSize:'20px'}}/>
                        cancel
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        startIcon={<TelegramIcon />}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper sx={{ p: 2, borderRadius: 0 }}>
                  <Box>
                    <Typography>

                      Preview
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Box>
                        <Avatar
                          alt="Profile Picture"
                          src={facebookUsersData?.picture?.url}
                          sx={{ width: 50, height: 50, marginBottom: "8px" }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="h6">{facebookUsersData?.name}</Typography>
                        <Typography variant="body1">
                          {facebookUsersData?.email}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Dialog open={successDialogOpen} onClose={closeSuccessDialog} aria-labelledby="success-dialog-title">
          <DialogTitle id="success-dialog-title">Success</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Campaign Created Successfully
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeSuccessDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
