import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, Zoom } from '@mui/material'
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import backendUrl from '../../configmain';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const headers = ['Package name', 'Price', 'Validaty', 'Action',];
let token = localStorage.getItem('authorization');
export default function PackageManager() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false); 
  const [packageValue, setPackageValue] = useState("");
  const handlePackageChange = (e) => {
    setPackageValue(e.target.value)
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/package `, {
          headers: {
            Authorization: `${token}`,
          },
        });
        if(response){
          setPackageData(response?.data?.data);
        } else {
          alert("Not fetched")
        }
        if (response.data.status === true) {
          setDataFetched(true)
        }
        // Handle the response data here
      } catch (error) {
        console.error(error);
        // Handle the error here
      }
    };
    fetchData();
  }, []);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };
  const deletePackage = () => {
    const deleteUrl = `${backendUrl}/api/package/`
    axios.delete(`${deleteUrl}/${selectedUser._id}`, {
      headers: {
        Authorization: `${token}`,
      },
    }).then(response => {
        setPackageData(prevCampaigns => prevCampaigns.filter(campaign => campaign._id !== selectedUser._id));
        setIsModalOpen(false);
        setDialogOpen(false);
      })
      .catch(error => {
        console.error('Error updating user details:', error);
      });
  };
  const applyFilter = () => {
    const url = `${backendUrl}/api/package`
    const newUrl = url + "?packageName=" + packageValue
    console.log(newUrl)
    // Make the axios GET request when the component mounts
    axios.get(newUrl, {
      headers: {
        Authorization: token,
      },
    })
      .then(response => {
        // Update the userData state with the fetched data
        setPackageData(response?.data?.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  return (
    <>
      {dataFetched ? (
        <Box sx={{ p: 1 }}>
          <Box sx={{ mb: 2, mt: 2, }} >
            <Box sx={{ display: "flex", textAlign: "center", alignItems: "center", justifyContent: "flex-end", }}>
              <Box sx={{ display: "flex", gap: 3, textAlign: "center", alignItems: "center", }}>
                <Box style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '4px', }}>
                  <Box component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300 }}>
                    <InputBase size="small"
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="search"
                      value={packageValue}
                      onChange={handlePackageChange}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={applyFilter}>
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box>
                  <Link to="/package">
                    <Button
                      className=""
                      style={{ borderRadius: '50px', backgroundColor: "#279d85" }}
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                    >
                      Create Package
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
          <TableContainer component={Paper} >
            <Table>
              <TableHead sx={{ background: "#111827", color: "#fff" }}>
                <TableRow sx={{ color: "#fff" }}>
                  {headers.map((header, index) => (
                    <TableCell key={index}>
                      <Typography sx={{ fontWeight: "bold", textAlign: "center", justifyContent: "center", color: "#fff" }}>
                        {header}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {packageData && packageData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center", }}>{item.packageName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center", }}>{item.price}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center", }}>{item.validity}</Typography>
                    </TableCell>


                    <TableCell>
                      <Typography sx={{ textAlign: "center", justifyContent: "center", }}>
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: "center" }}>
                          <Tooltip title="Edit" arrow TransitionComponent={Zoom}>
                            <Button
                            // onClick={() => handleEditClick(item)}
                            >
                              <EditIcon sx={{ color: '#222' }} />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete" arrow TransitionComponent={Zoom} onClick={() => handleDeleteClick(item)}>
                            <Button>
                              <DeleteIcon sx={{ color: "#d32f2f" }} />
                            </Button>
                          </Tooltip>

                        </Box>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </TableContainer>
          {/* Confirmation Dialog */}
          <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this user?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={deletePackage} color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      ) : (

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center',    // Center vertically
            height: '100vh',        // Set a specific height for centering vertically
          }}
        >
          <CircularProgress disableShrink />
        </Box>
      )
      }
    </>
  )
}
