import React from 'react'
import { Route, Routes, } from 'react-router-dom'
import Onboarding from '../component/WhatsApp/Onboarding/Onboarding'
import Analytics from '../component/Dashboard/Analytics'
import OtnBroadcast from '../component/FB&Instagram/OTNbroadcast/OtnBroadcast'
import CampaignPage from '../component/FB&Instagram/OTNbroadcast/campaign/CampaignPage'
import MultimediaPost from '../component/FB&Instagram/MultimediaPost/MultimediaPost'
import CreateNewMultimediaPost from '../component/FB&Instagram/MultimediaPost/CreateNewMultimediaPost'
import AccountManagement from '../component/FB&Instagram/Pages/AccountManagement'
import VideoPost from '../component/FB&Instagram/Videopost/VideoPost'
import CarouselMain from '../component/FB&Instagram/Videopost/createCarousel/CarouselMain'
import CtaPost from '../component/FB&Instagram/CTAPost/CtaPost'
import CreateCtaPost from '../component/FB&Instagram/CTAPost/createCtaPost/CreateCtaPost'
import Templates from '../component/WhatsApp/Templates/Templates'
import Messaging from "../component/WhatsApp/Messaging/Messaging"
import { CSVmain } from '../component/WhatsApp/Messaging/CreateCSV/CSVmain';
import ViewCampaign from "../component/Campaign/ViewCampaign"
import UserManager from "../component/Subscription/UserManager"
import PackageManager from "../component/Subscription/PackageManager"
import ContactGroup from "../component/Email/ContactGroup/ContactGroup"
import AddContacts from '../component/Email/AddContacts/AddContacts'
import ManageTemplate from '../component/Email/ManageTemplate/ManageTemplate'
import CreateCampaign from '../component/Email/CreateCampaign/CreateCampaign'
import CampaignReport from '../component/Email/CampaignReport/CampaignReport'
import SMSGroup from "../component/SMS/SMSGroup/SMSGroup"
import SMSContacts from '../component/SMS/SMSContacts/SMSContacts'
import SMSTemplate from '../component/SMS/SMSTemplate/SMSTemplate'
import SMSCampaign from '../component/SMS/SMSCampaign/SMSCampaign.js'
import { Box } from '@mui/material'
import BusinessWhatsApp from '../component/WhatsApp/BusinessWhatsApp/BusinessWhatsApp'
import AddUser from '../component/Subscription/AddUser'
import PackageMain from '../component/Subscription/PackageMain'
import CreateTemplates from '../component/WhatsApp/Templates/CreateTemplates'
import EditGroup from '../component/Email/ContactGroup/EditGroup'
import EditProfile from '../component/Header/EditProfile'
import Chatbot from '../component/Chats/Chats'
import GroupManager from '../component/TeamManager/GroupManager'
import NewChatBot from '../component/WhatsApp/NewChatBot/NewChatBot'
import PrivacyPolicy from '../component/privacyPolicy/PrivacyPolicy.js'
import Terms from '../component/privacyPolicy/Terms.js'

export default function Home() {
    return (
        <>
          {/* <Chatbot /> */}
            <Routes>
                <Route path="/dashboard/analytics" element={<Analytics />} />
                <Route path="/" element={<Analytics />} />
                <Route path="/fbinstagram/otnbroadcast" element={<OtnBroadcast />} />
                <Route path="/fbinstagram/otnbroadcast/CreateNewOTNPost" element={<CampaignPage />} />
                <Route path="/fbinstagram/multimediaPost" element={<MultimediaPost />} />
                <Route path="/fbinstagram/multimediaPost/CreateNewMultimediaPost" element={<CreateNewMultimediaPost />} />
                <Route path="/fbinstagram/accountManagement" element={<AccountManagement/>} />
                <Route path="/fbinstagram/videoPost" element={<VideoPost />} />
                <Route path="/fbinstagram/videoPost/createVideoPost" element={<CarouselMain />} />
                <Route path="/fbinstagram/ctaPost" element={<CtaPost />} />
                <Route path="/fbinstagram/ctaPost/createCTAPost" element={<CreateCtaPost />} />
                <Route path="/whatsApp/business" element={<BusinessWhatsApp />} />
                <Route path="/whatsApp/onboarding" element={<Onboarding />} />
                <Route path="/whatsApp/templates" element={<Templates />} />
                <Route path="/newChatBot" element={<NewChatBot />} />
                <Route path="/whatsApp/messaging" element={<Messaging />} />
                <Route path="/whatsApp/CSVmain" element={<CSVmain />} />
                <Route path="/email/contactGroup" element={<ContactGroup />} />
                <Route path="/email/addContacts" element={<AddContacts />} />
                <Route path="/email/manageTemplate" element={<ManageTemplate />} />
                <Route path="/email/createCampaign" element={<CreateCampaign />} />
                <Route path="/email/campaignReport" element={<CampaignReport />} />
                <Route path="/sms/group" element={<SMSGroup />} />
                <Route path="/privacyPolicy" element={<PrivacyPolicy/>} />
                <Route path="/termsCondition" element={<Terms/>} />
                <Route path="/sms/contacts" element={<SMSContacts />} />
                <Route path="/sms/template" element={<SMSTemplate />} />
                <Route path="/sms/campaign" element={<SMSCampaign />} />
                <Route path="/teamManager/groupMananger" element={<GroupManager />} />
                <Route path="sequenceCampaign/viewCampaign" element={<ViewCampaign />} />
                <Route path="subscription/userManager" element={<UserManager />} />
                <Route path="subscription/packageManager" element={<PackageManager />} />
                <Route path="/createTemplate" element={<CreateTemplates />} />
                <Route path="/editprofile" element={<EditProfile />} />
                <Route path="/addUser" element={<AddUser />} />
                <Route path="/package" element={<PackageMain />} />
                <Route path="/email/contactGroup/new/editGroup" element={<EditGroup />} />
                <Route path="*" element={<Box><h1> Page not found</h1></Box>} />
            </Routes>


        </>
    );
}