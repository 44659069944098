import React, { useState } from 'react';
import facebook from "../logo_icons/facebook.png"
import google from '../logo_icons/google.png';
import {
    Container,
    Card,
    CardContent,
    Typography,
    TextField,
    Button,
    Box,
    FormHelperText,
    Image,
    InputLabel,
    Input,
    FormControlLabel,
    FormGroup,
    Checkbox
} from '@mui/material';
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CellTowerIcon from '@mui/icons-material/CellTower';
import backendUrl from '../../configmain';


export default function SignIn({ onLogin }) {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [userError, setUserError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => event.preventDefault();
    const navigate = useNavigate();


    const validateUser = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@(wtf\.digital|[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})$/;
        if (!emailRegex.test(email)) {
            setUserError("Invalid email format.");
            return false;
        }
        setUserError("");
        return true;
    };


    const validatePassword = (pwd) => {
        if (pwd.length < 6) {
            setPasswordError("Password should be at least 6 characters long.");
            return false;
        }
        setPasswordError("");
        return true;
    };

    const handleLogin = (e) => {
        e.preventDefault();

        const isUserValid = validateUser(user);
        const isPasswordValid = validatePassword(password);

        if (!isUserValid || !isPasswordValid) {
            return;
        }

        const data = {
            email: user,
            password: password
        };

        axios.post(`${backendUrl}/api/auth/login`, data)
            .then((response) => {
                console.log(response)
                if (response.data.success === true) {
                    const userTokenTosave = response.data.accessToken;
                    localStorage.setItem('authorization', userTokenTosave);
                    onLogin();
                    navigate("/dashboard/analytics");
                }
            })
            .catch((error) => {
                console.log(error)
                const errorMessage = error.response
                    ? error.response.data.message
                    : 'An error occurred';
                console.error(errorMessage);

                toast.error(errorMessage)

            });
    };

    return (
        <Container style={containerStyle}>
            <Toaster />
            <Card style={cardStyle}>
                <CardContent>
                    <Box mb={2}>
                        <Typography
                            variant="h5"
                            align="center"
                            gutterBottom
                            sx={{ fontWeight: 700, color: '#279d85' }}
                        >
                            Log in
                        </Typography>
                    </Box>
                    <form style={formStyle} onSubmit={handleLogin}>


                        <TextField
                            placeholder="Email"
                            fullWidth
                            value={user}
                            error={Boolean(userError)}
                            helperText={userError}
                            onChange={(e) => {
                                setUser(e.target.value);
                                validateUser(e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#279d85",
                                },
                            }}
                        />
                        <FormControl sx={{
                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#279d85",
                            },
                        }} variant="outlined" error={Boolean(passwordError)}>
                            <OutlinedInput
                                placeholder='password'
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    validatePassword(e.target.value);
                                }}

                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>{passwordError}</FormHelperText>
                        </FormControl>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox defaultChecked />} label="Keep me logged in" />
                            </FormGroup>
                            <Link to="/resetPassword" underline="none" style={{ textAlign: "end", fontWeight: "bold", color: '#279d85', }}>Forgot Password</Link>
                        </Box>
                        <Button
                            type='submit'
                            variant="contained"
                            onClick={handleLogin}
                            fullWidth
                            sx={{
                                background: "#279d85",
                                // 'linear-gradient(310deg, rgb(20, 23, 39), rgb(58, 65, 111))',
                                color: 'white',
                                padding: '0.75rem 1.5rem',
                                boxShadow:
                                    'rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem',
                                '&:hover': {
                                    background: "#279d85", // Explicitly set the hover background color to be the same as the default
                                    boxShadow:
                                        'rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem', // If you want the same shadow on hover
                                },
                            }}
                        >
                            Login
                        </Button>

                        <Box sx={{ textAlign: 'center' }}>Or continue with</Box>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: "center", gap: '5%' }} >
                            <Box sx={{ padding: '5px 20px', cursor: "pointer", border: '1px solid gray', borderRadius: '30px', "&:hover": { background: "#F8F8F8", } }}>
                                <img src={google} width="20px" />

                            </Box>

                            <Box sx={{ padding: '5px 20px', border: '1px solid gray', cursor: "pointer", borderRadius: '30px', "&:hover": { background: "#F8F8F8", } }}>
                                <img src={facebook} width="20px" />

                            </Box>

                        </Box>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography>Don't have an account? <Link to="/signup"> <span style={{ fontWeight: "bold", color: 'rgb(52, 71, 103)' }}> Sign up </span> </Link></Typography>
                        </Box>
                    </form>
                </CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '50px'  // You can adjust this height as needed
                    }}
                >
                    <Link to="/privacyPolicy"> {/* Assuming you want it to be a placeholder link */}
                        Privacy Policy &nbsp;|
                    </Link>
                    <Link to="/termsCondition"> {/* Assuming you want it to be a placeholder link */}
                        &nbsp; Terms
                    </Link>
                </Box>
            </Card>
        </Container>
    );
}

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100vw',
};
const cardStyle = {
    width: '500px',
    padding: '16px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
};
const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
};

const labelStyles = {
    transform: 'translate(14px, -6px)', // Adjust the position of the label inside the outlined border
};

const inputOutlineStyles = {
    borderColor: '#279d85', // Border color when focused
};

