import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'; // Updated import path
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import RightSidebar from './RightSidebar';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Updated import path
import Button from '@mui/material/Button';
import axios from 'axios';
import dayjs from "dayjs";
import TelegramIcon from "@mui/icons-material/Telegram";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { localeOptions, timeZones, timeZoneSecond } from './data/campaignData';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import Input from '@mui/material/Input'; // Updated import path
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import toast, { Toaster } from "react-hot-toast";
import InputLabel from "@mui/material/InputLabel";
import backendUrl from '../../../../../src/configmain';

const theme = createTheme({
    overrides: {
        MuiInputBase: {
            root: {
                fontSize: '16px',
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '16px',
            },
        },
    },
});


//-------------------------timeZone array-------------------------------------//


function CampaignMain() {
    const [gender, setgender] = useState('');
    const [isScheduledTime, setIsScheduledTime] = useState(false);
    const [selectedMessageType, setSelectedMessageType] = useState('Text')
    const [pageOptions, setPageOptions] = useState([]);
    const [pages, setPages] = useState([])
    const [selectedTimeZone, setSelectedTimeZone] = useState('');
    const [selectedLocale, setSelectedLocale] = useState('');
    const [selectedDateTime, setSelectedDateTime] = useState(dayjs("2022-04-17T15:30"));
    const [selectTimeZoneSecond, setselectTimeZoneSecond] = useState('')
    const [messageContent, setMessageContent] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [includeLabels, setIncludeLabels] = useState('');
    const [excludeLabels, setExcludeLabels] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [buttonType, setButtonType] = useState('');
    const [mediaUrl, setMediaUrl] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [title, setTitle] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileLink, setFileLink] = useState('');

    // console.log(selectedFile)

    const handleGoBack = () => {
        window.history.back();
    };
    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }

    const handleSubtitleChange = (event) => {
        setSubtitle(event.target.value)
    }

    const handleMediaUrlChange = (event) => {
        setMediaUrl(event.target.value)
    }
    const handleButtonTextChange = (event) => {
        setButtonText(event.target.value);
    }

    const handleButtonTypeChange = (event) => {
        setButtonType(event.target.value)
    }

    const handleIncludeLabelsChange = event => {
        setIncludeLabels(event.target.value);
    };

    const handleExcludeLabelsChange = event => {
        setExcludeLabels(event.target.value);
    };

    const showSuccessDialog = () => {
        setSuccessDialogOpen(true);
    };

    const closeSuccessDialog = () => {
        setSuccessDialogOpen(false);
        window.location.reload()
    };

    const handleCampaignNameChange = (event) => {
        setCampaignName(event.target.value);
    };

    const handleContentChange = (event) => {
        setMessageContent(event.target.value);
    };
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const tokens = localStorage.getItem('authorization');

    const headerss = {
        'Authorization': `${tokens}`,
        'Content-Type': 'application/json',
    };
    const handleUpload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios.post(`${backendUrl}/api/user/upload`, formData)
            .then(response => {
                const uploadedLink = response.data.link;
                // console.log(response.data)
                setFileLink(uploadedLink);
                // console.log(fileLink)
                console.log(uploadedLink)
            })
            .catch(error => {
                // console.error('Error uploading file:', error);
            });
    };
    const renderMessageContent = () => {
        if (selectedMessageType === 'Text') {
            return (
                <TextareaAutosize
                    rowsMin={5}
                    placeholder="Write your message here..."
                    value={messageContent}
                    onChange={handleContentChange}
                    style={{
                        marginTop: '2%',
                        width: '100%',
                        border: '1px solid gray',
                        borderRadius: '4px',
                        padding: '8px',
                    }}
                />
            );
        } else if (selectedMessageType === 'Quick Reply' || selectedMessageType === 'Button') {
            return (
                <>
                    <TextareaAutosize
                        rowsMin={5}
                        placeholder="Write your message here..."
                        value={messageContent}
                        onChange={handleContentChange}
                        style={{
                            marginTop: '2%',
                            width: '100%',
                            border: '1px solid gray',
                            borderRadius: '4px',
                            padding: '8px',
                        }} />
                    <TextField
                        label="Button Text"
                        value={buttonText}
                        onChange={handleButtonTextChange}
                        variant="outlined"
                        style={{ width: '100%', marginTop: '2%' }}
                    />
                    <FormControl variant="outlined" style={{ width: '100%', marginTop: '2%' }}>
                        <InputLabel>Button Type</InputLabel>
                        <Select
                            value={buttonType}
                            onChange={handleButtonTypeChange}
                            fullWidth
                            label="Button Type"
                        >
                            <MenuItem value="">
                                <em>Button Type</em>
                            </MenuItem>
                            <MenuItem value="User phone number">User phone number</MenuItem>
                            <MenuItem value="post back">post back</MenuItem>
                            <MenuItem value="User email">User email</MenuItem>
                        </Select>
                    </FormControl>
                </>
            );
        } else if (selectedMessageType === 'Image' || selectedMessageType === 'Video' || selectedMessageType === 'Mp3' || selectedMessageType === 'File') {
            return (
                <Box>
                    <Input type="file" onChange={handleFileChange} />
                    <Button variant="outlined" color="success" onClick={handleUpload}>Upload</Button>
                    {fileLink && <p>Uploaded File Link: {fileLink}</p>}
                </Box>
            );
        } else if (selectedMessageType === 'Generic Template' || selectedMessageType === 'Carousel') {
            return (
                <>
                    <Box>
                        <Input type="file" onChange={handleFileChange} />
                        <Button variant="contained" color="secondary" onClick={handleUpload}>Upload</Button>
                        {fileLink && <p>Uploaded File Link: {fileLink}</p>}
                    </Box>
                    <TextField
                        label="Title"
                        value={title}
                        onChange={handleTitleChange}
                        variant="outlined"
                        style={{ width: '100%', marginTop: '2%' }}
                    />
                    <TextField
                        label="Subtitle"
                        value={subtitle}
                        onChange={handleSubtitleChange}
                        variant="outlined"
                        style={{ width: '100%', marginTop: '2%' }}
                    />
                    <TextField
                        label="Button Text"
                        value={buttonText}
                        onChange={handleButtonTextChange}
                        variant="outlined"
                        style={{ width: '100%', marginTop: '2%' }}
                    />
                    <FormControl variant="outlined" style={{ width: '100%', marginTop: '2%' }}>
                        <InputLabel>Button Type</InputLabel>
                        <Select
                            value={buttonType}
                            onChange={handleButtonTypeChange}
                            fullWidth
                            label="Button Type"
                        >
                            <MenuItem value="">
                                <em>Button Type</em>
                            </MenuItem>
                            <MenuItem value="User phone number">User phone number</MenuItem>
                            <MenuItem value="post back">post back</MenuItem>
                            <MenuItem value="User email">User email</MenuItem>
                        </Select>
                    </FormControl>
                </>
            );
        } else if (selectedMessageType === 'Media') {
            return (
                <>
                    <TextField
                        label="Media URL"
                        value={mediaUrl}
                        onChange={handleMediaUrlChange}
                        variant="outlined"
                        style={{ width: '100%', marginTop: '2%' }}
                    />
                    <TextField
                        label="Button Text"
                        value={buttonText}
                        onChange={handleButtonTextChange}
                        variant="outlined"
                        style={{ width: '100%', marginTop: '2%' }}
                    />
                    <FormControl variant="outlined" style={{ width: '100%', marginTop: '2%' }}>
                        <InputLabel>Button Type</InputLabel>
                        <Select
                            value={buttonType}
                            onChange={handleButtonTypeChange}
                            fullWidth
                            label="Button Type"
                        >
                            <MenuItem value="">
                                <em>Button Type</em>
                            </MenuItem>
                            <MenuItem value="User phone number">User phone number</MenuItem>
                            <MenuItem value="post back">post back</MenuItem>
                            <MenuItem value="User email">User email</MenuItem>
                        </Select>
                    </FormControl>
                </>
            );
        }
    };



    //---------------------------------------------- handle timezone 2 change ----------------------------------//
    const handleTimeZoneChangetwo = (event) => {
        setselectTimeZoneSecond(event.target.value);
    };
    // -------------------------------------------handle date and time selection--------------------------------//

    const handleDateTimeChange = (newDateTime) => {
        setSelectedDateTime(newDateTime);
    };
    //-------------------------------------------------handle locale change --------------------------------//

    const handleLocaleChange = (event) => {
        setSelectedLocale(event.target.value);
    };

    //--------------------------------------------- handle switch change--------------------------------//

    const handleSwitchChange = (event) => {
        setIsScheduledTime(event.target.checked);

    };

    //------------------------------------handle pages-------------------------------------------//

    const handlePages = (event) => {
        setPages(event.target.value);

    }

    //--------------------------------------- handle timeZone change-------------------------------//
    const handleTimeZoneChange = (event) => {
        setSelectedTimeZone(event.target.value);
    };



    //--------------------axios call for getPages to show on selectpages scroll----------------//

    const token = localStorage.getItem('authorization');
    const url = `${backendUrl}/api/user/getPages `
    const headers = {
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
    };
    useEffect(() => {
        axios.get(url, {
            headers
        })
            .then(response => {
                setPageOptions([response.data.getPage]);
                console.log(response.data.getPage)
            })
            .catch(error => {
                // console.error('Error fetching page options:', error);
            });
    }, []);



    // this is for gender 
    const handleGenderChange = (event) => {
        setgender(event.target.value);

    }



    // --------------------------------- this is the event to change images in right side bar------------------------//
    const handleChange = (event) => {
        // console.log(event)
        setSelectedMessageType(event.target.value);
    };



    //------------------------------ this is the post axios call for form submission of campaign-----------------//

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = {
            campaignName: campaignName,
            pageName: pages,
            gender: gender,
            timezone: selectedTimeZone,
            locale: selectedLocale,
            ...(isScheduledTime ? {
                secheduledTime: selectedDateTime,
                timezoneOption: selectTimeZoneSecond,
            } : {}),
            mediaType: selectedMessageType
        };

        // Add media type specific fields using a switch
        switch (selectedMessageType) {
            case "Text":
                formData.typeMediaText = {
                    textMessege: messageContent,
                };
                break;

            case "Image":
                formData.typeMediaImage = {
                    fileUrl: fileLink,
                };
                break;

            case "Audio":
                formData.typeMediaAudio = {
                    fileUrl: fileLink,
                };
                break;

            case "Video":
                formData.typeMediaVideo = {
                    fileUrl: fileLink,
                };
                break;

            case "Generic Template":
                formData.typeGenericTemplate = {
                    title: title,
                    subtitle: subtitle,
                    fileUrl: fileLink,
                    buttons: [{
                        buttontext: buttonText,
                        buttonType: buttonType,
                    }],
                };
                break;

            case "Carousel":
                formData.typeCarousel = [{
                    title: title,
                    subtitle: subtitle,
                    fileUrl: fileLink,
                    buttons: [{
                        buttontext: buttonText,
                        buttonType: buttonType,
                    }],
                }];
                break;

            case "media":
                formData.typeMedia = {
                    mediaUrl: mediaUrl,
                    buttons: [{
                        buttontext: buttonText,
                        buttonType: buttonType,
                    }],
                };
                break;

            case "Quick Reply":
                formData.typeQuickReply = {
                    textMessege: messageContent,
                    buttons: [{
                        buttontext: buttonText,
                        buttonType: buttonType,
                    }],
                };
                break;

            case "Generic Template":
                formData.typeGenericTemplate = {
                    title: title,
                    subtitle: subtitle,
                    fileUrl: fileLink,
                    buttons: [{
                        buttontext: buttonText,
                        buttonType: buttonType,
                    }],
                }

            case "Button":
                formData.typeButtons = {
                    textMessege: messageContent,
                    buttons: [{
                        buttontext: buttonText,
                        buttonType: buttonType,
                    }],
                }

            default:
                break;
        }








        const urlCampaign = `${backendUrl}/api/campaign/create`
        axios.post(urlCampaign, formData, {
            headers
        })
            .then(response => {
                // console.log(response.data)
                showSuccessDialog();
            })

            .catch(error => {
                // console.error('Error posting data:', error);
                 console.log(error.response.data.error)
                 const err=error.response.data.error
                 toast.error(`error : ${err} `)
            });
    };
    //-------------------------------------------------------------------------------------------------------------//
    return (
        <div style={{ display: 'flex', marginTop: "30px", backgroundColor: "white", padding: "20px" }}>
     <Toaster/>         
            <div style={{ flex: 1 }}>
                <ThemeProvider theme={theme}>
                    <Box component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off" />
                    <Typography className="text-24 md:text-27 py-40 font-bold tracking-tight leading-none px-4 sm:pl-20" style={{ fontSize: "25px", fontWeight: "semiBold" }}>
                        Campaign details
                    </Typography>
                    <Box p={1}>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                            <Box sx={{ width: "50%" }}>
                                <Box >
                                    <Typography >Campaign Name</Typography>
                                    <Box sx={{ mt: 1 }}>
                                        <FormControl sx={{
                                            width: "100%", '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: "#279d85",
                                            },
                                        }}>
                                            <TextField
                                                fullWidth
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={campaignName}
                                                onChange={handleCampaignNameChange}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                                <FormControl sx={{
                                    width: "100%", '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}>
                                    <Typography>Select page</Typography>
                                    <Select sx={{ mt: 1 }} value={pages} onChange={handlePages} >
                                        <MenuItem value="">Select</MenuItem>
                                        {pageOptions.map(option => (
                                            <MenuItem key={option._id} value={option.name}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Typography variant="h6" gutterBottom style={{ fontSize: "25px", marginTop: "20px" }}>
                            Targeted Options
                        </Typography>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" style={{ width: '100%', height: '30%' }} sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}>
                                    <Typography className='md-2' style={{ marginBottom: '8px' }}>Target by labels</Typography>
                                    <TextField
                                        id="include-labels"
                                        value={includeLabels}
                                        onChange={handleIncludeLabelsChange}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" style={{ width: '100%', height: '30%' }} sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}>
                                    <Typography className='md-2' style={{ marginBottom: '8px' }}>Exclude labels</Typography>
                                    <TextField
                                        id="exclude-labels"
                                        value={excludeLabels}
                                        onChange={handleExcludeLabelsChange}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" style={{ width: '100%', height: '30%', marginTop: "5px" }} sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}>
                                    <Typography>Gender</Typography>
                                    <Select labelId="select-label-1" id="select-1" value={gender} onChange={handleGenderChange}>
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" style={{ width: '100%', height: '30%', marginTop: "5px" }} sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}>
                                    <Typography className="px-2">Timezone</Typography>
                                    <Select
                                        labelId="select-label-timezone"
                                        id="select-timezone"
                                        value={selectedTimeZone}
                                        onChange={handleTimeZoneChange}
                                    >
                                        {timeZones.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" style={{ width: '100%', height: '30%' }} sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}>
                                    <Typography className="px-2">Locale</Typography>
                                    <Select
                                        labelId="select-label-locale"
                                        id="select-locale"
                                        value={selectedLocale}
                                        onChange={handleLocaleChange}
                                    >
                                        {localeOptions.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Typography style={{ fontSize: "25px", marginTop: "20px" }}>
                            Message Template
                        </Typography>
                        <Grid item xs={12} sm={6}>
                            <Typography style={{ marginTop: "20px" }}>Select message type</Typography>
                            <FormControl variant="outlined" style={{ width: '100%', mt: 1 }} sx={{
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#279d85",
                                },
                            }}>
                                <Select
                                    labelId="select-label-1"
                                    id="select-1"
                                    value={selectedMessageType}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="Text">Text</MenuItem>
                                    <MenuItem value="Image">Image</MenuItem>
                                    <MenuItem value="File">File</MenuItem>
                                    <MenuItem value="Button">Button</MenuItem>
                                    <MenuItem value="Video">Video</MenuItem>
                                    <MenuItem value="Quick Reply">Quick Reply</MenuItem>
                                    <MenuItem value="Mp3">Mp3</MenuItem>
                                    <MenuItem value="Media">Media</MenuItem>
                                    <MenuItem value="Generic Template">Generic Template</MenuItem>
                                    <MenuItem value="Carousel">Carousel</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                        <div className='mt-4'>
                            <Grid>

                                {renderMessageContent()}
                            </Grid>
                        </div>
                        <FormControlLabel
                            value="top"
                            style={{ marginTop: '5%', marginBottom: '2%' }}
                            control={<Switch style={{ color: "#279d85" }} checked={isScheduledTime} onChange={handleSwitchChange} />}
                            label="Sending Time"
                            labelPlacement="top"
                        />
                        {isScheduledTime && (
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={6}>
                                    <Typography>Scheduled time</Typography>
                                    <FormControl size="small" sx={{
                                        width: "100%", '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: "#279d85",
                                        },
                                    }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    "DateTimePicker",
                                                    "MobileDateTimePicker",
                                                    "DesktopDateTimePicker",
                                                    "StaticDateTimePicker",
                                                ]}
                                            >
                                                <DemoItem>
                                                    <MobileDateTimePicker
                                                        size="small"
                                                        value={selectedDateTime}
                                                        onChange={handleDateTimeChange}
                                                        renderInput={(params) => <TextField {...params} variant="outlined" style={{ width: '100%', marginTop: '15px' }} />}
                                                    />
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" style={{ width: '100%', height: '30%' }} sx={{
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: "#279d85",
                                        },
                                    }} >
                                        <Typography className="px-2">Timezone</Typography>
                                        <Select mt={1}
                                            labelId="select-label-timezone"
                                            id="select-timezone"
                                            value={selectTimeZoneSecond}
                                            onChange={handleTimeZoneChangetwo}
                                        >
                                            {timeZoneSecond.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                        <div className="mt-4" style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', marginTop: "20px" }}>
                            <Button
                                variant="outlined"
                                color="success"
                                startIcon={<TelegramIcon />}
                                onClick={handleSubmit}>
                                Submit
                            </Button>
                            <Button
                                variant="outlined"
                                color="error"

                                onClick={handleGoBack}>
                                <CancelIcon style={{ marginRight: "5px", fontSize: '20px' }} />
                                Cancel
                            </Button>
                        </div>
                    </Box>
                </ThemeProvider>
            </div>
            <div style={{ width: '1px', backgroundColor: 'lightgrey' }} />
            <div className="col-md-3 d-none d-md-block">
                <RightSidebar selectedMessageType={selectedMessageType} />
            </div>
            <Dialog open={successDialogOpen} onClose={closeSuccessDialog} aria-labelledby="success-dialog-title">
                <DialogTitle id="success-dialog-title">Success</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Campaign Created Successfully
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeSuccessDialog} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default CampaignMain;
