import { createSlice  } from '@reduxjs/toolkit';
const initialState = {
  isSidebarOpen: false,
  type: 'CONTACT',
  messages: [],
};
const appSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setTypeContact: (state) => {
      state.type = 'CONTACT';
    },
    setTypeShared: (state) => {
      state.type = 'SHARED';
    },
    setTypeStar: (state) => {
      state.type = 'STARRED';
    },
    sendMessage: (state, action) => {
      state.messages.push(action.payload);
    },
  },
});
export const { toggleSidebar, setTypeContact, setTypeShared, setTypeStar, sendMessage } = appSlice.actions;
export default appSlice.reducer;
