import { Box, Stack, Divider, Typography, IconButton, } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { DownloadSimple, Image } from "phosphor-react"
const DocMsg = ({ el }) => {
    return (
        <Stack direction="row" justifyContent={el.incoming ? "start" : "end"}>
            <Box sx={{ backgroundColor: el.incoming ? "#ede7f6" : "#e3f2fd", borderRadius: 1.5, width: "max-content" }}>
                <Stack spacing={2}>
                    <Stack p={2} direction="row" spacing={3} alignItems="center" >
                        <Image size={48} />
                        <Typography variant="caption">Abstract.png</Typography>
                        <IconButton>
                            <DownloadSimple />
                        </IconButton>
                    </Stack>
                    <Typography p={2} variant="body2" color={el.incoming ? "black" : "green"}>{el.message} </Typography>

                </Stack>
            </Box>
        </Stack>
    )
}
const LinkMsg = ({ el }) => {
    return (
        <Stack direction="row" justifyContent={el.incoming ? "start" : "end"}>
            <Box sx={{ backgroundColor: el.incoming ? "#ede7f6" : "#e3f2fd", borderRadius: 1.5, width: "max-content" }}>
                <Stack spacing={2}>
                    <Stack p={2} alignItems="center" sx={{ borderRadius: 1, background: "#ede7f6" }}>
                        <img src={el.preview} alt={el.message} style={{ maxHeight: "100px", maxWidth: '100px', borderRadius: "10px" }} />
                        <Stack spacing={2}>
                            <Typography variant='subtitle2'>creating chat app lorem100</Typography>
                            <Typography to="https://www.google.com/" variant='subtitle2' component={Link}>www.google.com</Typography>
                        </Stack>
                        <Typography variant='body2' color={el.incoming ? "black" : "green"} >
                            {el.message}
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    )
}
const ReplyMsg = ({ el }) => {
    return (
        <Stack direction="row" justifyContent={el.incoming ? "start" : "end"}>
            <Box sx={{ backgroundColor: el.incoming ? "#ede7f6" : "#e3f2fd", borderRadius: 1.5, width: "max-content" }}>
                <Stack spacing={2}>
                    <Stack spacing={3} p={2} direction="column" alignItems="center" sx={{ backgroundColor: "#ede7f6", borderRadius: 1 }}>
                        <Typography variant='body2' color={el.incoming ? "black" : "green"}>
                            {el.message}
                        </Typography>
                    </Stack>
                    <Typography variant='body2' px={2}>
                        {el.reply}
                    </Typography>
                </Stack>
            </Box>
        </Stack>
    )
}

const MediaMsg = ({ el }) => {
    return (
        <Stack direction="row" justifyContent={el.incoming ? "start" : "end"}>
            <Box sx={{ backgroundColor: el.incoming ? "#ede7f6" : "#e3f2fd", borderRadius: 1.5, width: "max-content" }}>
                <Stack spacing={1}>
                    <img src={el.img} alt={el.message} style={{ maxHeight: 100, maxWidth: 100, borderRadius: "10px" }} />
                    <Typography variant='body2' color={el.incoming ? "black" : "green"}>{el.message}</Typography>
                </Stack>
            </Box>
        </Stack>
    )
}
const TextMsg = ({ el }) => {
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Box p={1.5} sx={{ width: '50%', backgroundColor: "#ede7f6", borderRadius: 1.5, wordWrap: 'break-word' }}>
                    <Typography variant='body2' color="black">
                        {el.incoming ? el.message : ''}
                    </Typography>
                </Box>
            </Stack>
        </>
    )
}
const Timeline = ({ el }) => {
    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Divider width="46%" />
                <Typography variant='caption'>{el.text}</Typography>
                <Divider width="46%" />
            </Stack>
        </>
    )
}
export { Timeline, TextMsg, MediaMsg, ReplyMsg, LinkMsg, DocMsg }