import React from 'react'
import { Divider, Stack, Typography, Box, Link, IconButton, Menu, MenuItem, Button, } from "@mui/material"
import { DotsThreeVertical, DownloadSimple, Image } from 'phosphor-react'
import { Message_options } from './newData/NewData'
import { useSelector } from 'react-redux';
const NewDocMsg = ({ el }) => {
    return (
        <>
            <Stack direction="row" justifyContent={el.incoming ? "start" : "end"} alignItems={"center"} >
                <Box p={1.5} sx={{ backgroundColor: el.incoming ? "#d1d7db" : "#128c7e", borderRadius: 1.5, width: 'max-content' }}>
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={3} alignItems="center" sx={{ borderRadius: 1 }}>
                            <Image size={48} />
                            <Typography variant="caption" sx={{ fontSize: "14px" }}>Abstract.pn</Typography>
                            <IconButton>
                                <DownloadSimple size={20} />
                            </IconButton>
                        </Stack>
                        <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "bold", background: "#d1d7db" }}>
                            {el.message}
                        </Typography>
                    </Stack>
                </Box>
                <MessageOptions />


            </Stack>
        </>
    )
}
const NewLinkMsg = ({ el }) => {
    return (
        <>

            <Stack direction="row" justifyContent={el.incoming ? "start" : "end"} alignItems={"center"} >
                <Box p={.5} sx={{ backgroundColor: el.incoming ? "#d1d7db" : "#128c7e", width: 'max-content', borderRadius: 1.5, }}>
                    <Stack >
                        <Stack p={2} alignItems="center" sx={{ borderRadius: 1 }}>
                            <img src={el.preview} alt={el.message} style={{ maxHeight: 210, borderRadius: "10px" }} />
                            <Stack spacing={2}>
                                <Typography variant="subtitle2">Create Chat app</Typography>
                                <Typography variant="subtitle2" component={Link} >www.google.com</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
                <MessageOptions />
            </Stack>
        </>
    )
}
const NewReplyMsg = ({ el }) => {
    return (
        <>
            <Stack direction="row" justifyContent={el.incoming ? "start" : "end"} alignItems={"center"} >
                <Box p={1.5} sx={{ background: el.incoming ? "#d1d7db" : "#128c7e", borderRadius: 1.5, width: "max-content" }}>
                    <Stack spacing={2}>
                        <Stack direction="column" spacing={3} alignItems="center" >
                            <Typography p={2} variant="body2" sx={{ fontSize: "14px", fontWeight: "bold", background: "#d1d7db" }}>
                                {el.message}
                            </Typography>
                        </Stack>
                        <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "bold" }}>
                            {el.reply}
                        </Typography>
                    </Stack>
                </Box>
                <MessageOptions />
            </Stack>
        </>
    )
}
const NewMediaMsg = ({ el }) => {
    return (
        <>
            <Stack direction="row" justifyContent={el.incoming ? "start" : "end"} alignItems={"center"} >
                <Box p={1.5} sx={{ background: el.incoming ? "#d1d7db" : "#128c7e", borderRadius: 1.5, width: "max-content" }}>
                    <Stack spacing={1}>
                        <img src={el.img} alt={el.message} style={{ maxHeight: "210px", borderRadius: "10px" }} />
                        <Typography variant="body2" color={el.incoming ? "#black" : "#fff"} sx={{ fontWeight: "bold", fontSize: "14px" }}>{el.message}</Typography>
                    </Stack>
                </Box>
                <MessageOptions />

            </Stack>

        </>
    )
}
const NewTextMsg = ({ el }) => {
    return (
        <>
            <Stack direction="row" justifyContent={el.incoming ? "start" : "end"} alignItems={"center"} >
                <Box p={1.5} sx={{ background: el.incoming ? "#d1d7db" : "#128c7e", borderRadius: 1.5, width: "max-content" }}>
                    <Typography variant="body2" color={el.incoming ? "#black" : "#fff"} sx={{ fontWeight: "bold", fontSize: "14px" }}>{el.message}</Typography>
                </Box>
                <MessageOptions />
            </Stack>

        </>
    )
}

const NewTimeline = ({ el }) => {
    const messages = useSelector((state) => state.sidebar.messages);

    return (
        <>
            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                <Divider width="46%" />
                <Typography variant="caption"> {el.text}</Typography>
                <Typography variant="caption"> {el.text}</Typography>
                {messages.map((message) => (
                    <p key={message.id}>{message.content}</p> // Make sure 'id' and 'content' matches the keys used when you dispatched the message
                ))}
                <Divider width="46%" />
            </Stack>
        </>
    )
}

const MessageOptions = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                <DotsThreeVertical size={24}
                />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Stack spacing={1} px={1} >
                    {
                        Message_options.map((el) => {
                            return (
                                <Box>

                                    <MenuItem >{el.title}</MenuItem>

                                </Box>
                            )
                        })
                    }
                </Stack>
            </Menu>
        </>
    )
}

export { NewTimeline, NewTextMsg, NewMediaMsg, NewReplyMsg, NewLinkMsg, NewDocMsg }
