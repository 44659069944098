import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios'
import { Add } from "@mui/icons-material";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import GroupsIcon from '@mui/icons-material/Groups';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import backendUrl from "../../configmain";
import toast, { Toaster } from 'react-hot-toast';

const token = localStorage.getItem('authorization');


function GroupHeader() {

    const [open, setOpen] = React.useState(false);
    const [age, setAge] = React.useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate();


    const handleChange = (event) => {
        setAge(event.target.value);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);

    };


    const handleSubmit = async (e) => {
        e.preventDefault();



        const formData = {
            firstName: firstName,
            lastName: lastName,
            mobile: mobile,
            email: email,
            role: selectedValue,


        };

        try {
            const response = await axios.post(`${backendUrl}/api/teamManager`, formData, {
                headers: {
                    Authorization: token,
                },
            });
            if (response.statusText === "OK") {

                setOpen(false);
                console.log("dihduiowd")
            }
            toast.success('Successfully created!');
            setFirstName("");
            setLastName("");
            setMobile("");
            setEmail("");
            setSelectedValue("");

        } catch (error) {
            console.error(' error:', error);
        }
    };


    return (
        <>
            <div className="p-24 sm:p-32 w-full">
                <Box style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "1rem", paddingLeft: "10px" }}>
                    <div>
                        < GroupsIcon style={{ fontSize: "25px", marginTop: "3px" }} />
                    </div>
                    <Typography variant="h5" sx={{ fontWeight: "900" }}>
                        Team Setting
                    </Typography>
                </Box>
                <Toaster />
                <Box sx={{ display: "flex", gap: "1rem", marginBottom: '1.5rem', paddingRight: "10px", paddingLeft: "10px" }}>
                    <TextField id="" label=""
                        sx={{
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: "#279d85",
                            },


                        }}
                        InputProps={{


                            placeholder: "Search Group",
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        fullWidth
                        size="small"
                    />
                    <Button
                        style={{
                            minWidth: "fit-content",
                            backgroundColor: "#279d85",
                            // borderRadius: '3rem',
                            color: "#fff",


                        }}
                        className="mx-8"
                        variant="contained"
                        // color="#fff"
                        component={Link}
                        onClick={handleClickOpen}
                    >
                        <Add />
                        <span style={{ color: "#fff" }} className="mx-8">Add Member</span>
                    </Button>
                </Box>


                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle style={{ backgroundColor: "#279d85", display: "flex", alignItems: "center", textAlign: "center" }}>
                        <span style={{ color: "#fff", fontSize: "20px" }} > <Add /></span>
                        <span style={{ color: "#fff", fontSize: "20px" }} >Add Member</span>
                    </DialogTitle>
                    <DialogContent sx={{ paddingTop: "30px" }}>

                        <TextField
                            sx={{
                                width: "265px", marginRight: "10px", marginTop: "20px"
                            }}

                            InputProps={{
                                sx: {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#279d85",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    "&.Mui-focused": {
                                        color: "#279d85",
                                    },
                                },
                            }}

                            id="outlined-name-input"
                            label="First Name"
                            type="text"
                            autoComplete="first-name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <TextField
                            sx={{
                                width: "265px", marginLeft: "10px", marginTop: "20px"
                            }}
                            InputProps={{
                                sx: {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#279d85",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    "&.Mui-focused": {
                                        color: "#279d85",
                                    },
                                },
                            }}
                            id="outlined-lastname-input"
                            label="Last Name"
                            type="text"
                            autoComplete="last-name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        <TextField
                            sx={{
                                marginTop: "20px"
                            }}
                            InputProps={{
                                sx: {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#279d85",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    "&.Mui-focused": {
                                        color: "#279d85",
                                    },
                                },
                            }}
                            fullWidth
                            id="outlined-email-input"
                            label="Email"
                            type="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <FormControl fullWidth sx={{
                            marginTop: "20px",
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: "#279d85",
                            },
                        }}>
                            <InputLabel sx={{
                                "&.Mui-focused": {
                                    color: "#279d85", // Change the label color when focused
                                },
                            }}>Select </InputLabel>
                            <Select label="Status" value={selectedValue} onChange={handleSelectChange} sx={{ height: '50px' }}>
                                <MenuItem value="Owner" >Owner</MenuItem>
                                <MenuItem value="Admin" >Admin</MenuItem>
                                <MenuItem value="Teammates" >Teammates</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{
                            width: "150px", marginTop: "20px", marginRight: "10px", "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: "#279d85",
                            },
                        }}>
                            <InputLabel id="demo-simple-select-label" sx={{
                                "&.Mui-focused": {
                                    color: "#279d85", // Change the label color when focused
                                },

                            }}>Country Code</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Country Code"
                                onChange={handleChange}
                            >
                                <MenuItem value={+91}>+91</MenuItem>

                            </Select>
                        </FormControl>
                        <TextField
                            sx={{
                                marginTop: "20px", marginLeft: "10px", width: "382px"
                            }}
                            InputProps={{
                                sx: {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#279d85",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                sx: {
                                    "&.Mui-focused": {
                                        color: "#279d85",
                                    },
                                },
                            }}
                            id="outlined-mobile"
                            label="Mobile Number"
                            type="text"
                            autoComplete="mobile-number"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "space-between", paddingLeft: "24px", paddingRight: "24px" }}>

                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleClose}
                        >

                            <CancelIcon style={{ marginRight: "7px", fontSize: '20px' }} />
                            cancel
                        </Button>
                        <Button
                            variant="outlined"
                            color="success"
                            startIcon={<Add />}
                            onClick={handleSubmit}

                        >
                            Add Member
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

export default GroupHeader;
