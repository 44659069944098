import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';

import { Box } from '@mui/system';
import { Menu, MenuItem, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { styled } from "@mui/material/styles";

import { Table, TableBody, TableCell, TablePagination, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import backendUrl from '../../../../../src/configmain';



const useStyles = styled((theme) => ({
  button: {
    borderRadius: "5px",
    border: "1px solid black",

    backgroundColor: "#111827",
    color: "white",
    padding: "6px 16px",
    "& .MuiSvgIcon-root": {
      marginRight: "8px",
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "black"
    },
  },
}));



const CsvMesseges = () => {
  // const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(0);
  // const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [templates, setTemplates] = useState([]);
  const [selectedValue, setSelectedValue] = useState('Status');
  // const [templateName, setTemplateName] = useState('');
  const [status, setStatus] = useState('');
  const [errorStatus, setErrorStatus] = useState(null);



  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);



  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    setSelectedValue(value);
    setStatus(value);
    setAnchorEl(null);
  };

  function createData(name, createdby, category, State, Attempted, Delivered, Read, Replied, DataSetLive) {
    return { name, createdby, category, State, Attempted, Delivered, Read, Replied, DataSetLive };
  }
  const columns = [
    { id: 'name', label: 'Name', align: 'center' },
    { id: 'createdby', label: 'Created By', align: 'center' },
    {
      id: 'category',
      label: 'Category',
      // minWidth: 170,
      align: 'center',
      format: (value) => value.toLocaleString('en-US'),


    },
    {
      id: 'State',
      label: 'State',
      // minWidth: 170,
      align: 'center',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'Attempted',
      label: 'Attempted',
      // minWidth: 170,
      align: 'center',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'Delivered',
      label: 'Delivered',
      // minWidth: 170,
      align: 'center',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'Read',
      label: 'Read',
      // minWidth: 170,
      align: 'center',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'Replied',
      label: 'Replied',
      // minWidth: 170,
      align: 'center',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'DataSetLive',
      label: 'Data set live',
      // minWidth: 170,
      align: 'center',
      format: (value) => value.toFixed(2),
    },

  ];

  const url = `${backendUrl}/api/whatsapp/getMesseges`;

  useEffect(() => {
    const token = localStorage.getItem('authorization');

    const queryParams = new URLSearchParams();
    // if (name) {
    //   queryParams.append('name', name);
    // }
    if (status) {
      queryParams.append('status', status);

    }

    const requestUrl = `${url}${queryParams.toString() ? '?' + queryParams.toString() : ''}`;
    console.log(requestUrl)

    axios.get(requestUrl, {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then(response => {
        console.log(response)
        const transformedData = response.data.data.map(item =>
          createData(item.campaignName, item.createdBy, item.category, item.preview, item.language, item.status, item.action)
        );
        setTemplates(transformedData);
        console.log(transformedData);

      })


      .catch(error => {
        console.error('Error fetching campaigns posts:', error);
        if (error.response) {
          setErrorStatus(error.response.status);
        } else {
          setErrorStatus(500);
        }
      });
  }, [url,
    // name,
    status]);


  function handleChangePage(event, value) {
    setPage(value);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }


  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  return (
    <>
      <Box sx={{ mt: 1, p: 2 }}>
        <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
          <Button
            className={classes.button}
            variant="outlined"
            endIcon={<ArrowDropDownIcon />}
            onClick={handleButtonClick}
            style={{ paddingRight: "5px", backgroundColor: "#111827", color: "white" }}
          >
            {selectedValue !== null ? selectedValue : 'Select Status'}
          </Button>
          <Menu
            id="selector-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}

          >
            <MenuItem sx={{ margin: "3.5px" }} onClick={() => handleMenuItemClick("Approved")}>Approved</MenuItem>
            <MenuItem sx={{ margin: "3.5px" }} onClick={() => handleMenuItemClick("Pending")}>Pending</MenuItem>
            <MenuItem sx={{ margin: "3.5px" }} onClick={() => handleMenuItemClick("Rejected")}>Rejected</MenuItem>
          </Menu>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden', mt: 3}}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead  >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      // align={column.align}
                      style={{  backgroundColor: "#111827", color: "#fff" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {errorStatus === 404 || errorStatus === 500 ? (
                  // Show error message for 404 or 500 errors
                  <TableRow>
                    <TableCell colSpan={columns.length + 1} align="center">
                      <Typography variant="h6" color="textSecondary">
                        {errorStatus === 404
                          ? "Campaign not found."
                          : "No Campaign found Or Kindly login to see the posts"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  templates && templates
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === 'delete' ? (
                                column.format(value, row)
                              ) : (
                                column.format && typeof value === 'number'
                                  ? column.format(value, row)
                                  : value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                )}
              </TableBody>

            </Table>
          </TableContainer>
          {/* {templates.length === 0 && (
            <Box sx={{ p: 2 }}>
              <Typography variant="body1" align="center">
                No templates are currently matching the conditions
              </Typography>
            </Box>
          )} */}

          {templates.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={templates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}

        </Paper>
      </Box>

    </>
  );
};

export default CsvMesseges;



