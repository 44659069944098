import { Box, Stack } from '@mui/material'
import React from 'react'
import NewFooter from './NewFooter'
import NewMessages from './NewMessages'
import NewProfileHeader from './NewProfileHeader'

const NewConversation = () => {
    return (
        <>
            <Stack height={"100%"} maxHeight={"100vh"} >
                {/* chat header */}
                <Box sx={{ width: "100%" }}>
                    <NewProfileHeader />
                </Box>
                {/* msg */}
                <Box sx={{  height: "100%", overflowY: "scroll",  }}>
                    <NewMessages />
                </Box>
                {/* chat footer  */}
                <Box >
                    <NewFooter />
                </Box>
            </Stack>
        </>
    )
}

export default NewConversation
