import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'; import { Box } from '@mui/system';
import "./ChatBox.css"
import { TextField, } from '@mui/material';
import Conversation from './Conversation';
import Chating from './Chating';
const Root = styled('div')(({ theme, opened }) => ({
  position: 'sticky',
  display: 'flex',
  top: 0,
  width: 70,
  maxWidth: 70,
  minWidth: 70,
  height: '100vh',
  zIndex: 1000,

}));
export default function ChatBox() {
  const [data, setData] = useState([])
  const [isOpen, setIsOpen] = useState(false);
const [open, setOpen] = useState(false);



  const toggleChat = () => {
    console.log(isOpen)
    setIsOpen(true);
    console.log(isOpen)
  };
  const getUserData = async () => {
    const response = await axios.get("https://reqres.in/api/users");
    const data = await response.data.data;
    console.log(data)
    setData(data)
  };
  const chatBoxStyles = {
    position: 'fixed',
    overflowY: "auto",
    right: isOpen ? 0 : '',
    width: isOpen ? "500px" : 70,
    height: '100%',
    backgroundColor: '#fff',
    transition: 'right 0.3s ease-in-out',
  };
  const buttonStyle = {
    position: 'fixed',
    top: 0,
    height:70,
   
    // zIndex: 25,
    backgroundColor: "rgb(9, 31, 60)",
    right: isOpen ? 0 : '',
    width: isOpen ? "500px" : 70,
    transition: 'right 0.3s ease-in-out',
    zIndex: 9999,
  }



  const ref = useRef();

  useEffect(() => {
    getUserData()
  }, [])


  return (
    <>
      <Root  >
        <div    >
          <AppBar style={buttonStyle}>
            <Toolbar >
              <div
                style={{ display: isOpen ? "flex" : "", justifyContent: isOpen ? "space-between" : "", alignItems: isOpen ? "center" : "", width: isOpen ? "500px" : 70 }}
              >
                <IconButton onClick={toggleChat} style={{ color: "#fff" }}>
                  <ForumOutlinedIcon />
                </IconButton>
                {
                  isOpen ? (<div style={{ cursor: "pointer"}} onClick={() => setIsOpen(false)} >Close</div>) : ("")
                }
              </div>
            </Toolbar>
          </AppBar>

          <div style={chatBoxStyles} >
            <Paper style={{
              height: "100%",
              alignItems: "center",
             
              // background: "red"
            }} >
              <Box sx={{ display: "flex", height: "100%", gap: "10px", }}>
                <Box sx={{
                  padding: "10px", height: "100%",
                  //  background: "gray"
                  background: "RGB(217 240 240)",
                 
                  
                }}>
                  <div style={{ height: "100%", overflowY: "auto", background: "RGB(217 240 240)", width:55, display:"grid", justifyContent:'center', alignItems:"center"}}>

                  {
                    data.map((el) => (
                      <Box style={{ marginTop: "10px", width: "max-content", }} key={el.id}>
                        <Avatar onClick={toggleChat} style={{ cursor: "pointer", width: "40px", height: "40px" }} src={el.avatar} />
                      </Box>
                    ))
                  }
                  {
                    data.map((el) => (
                      <Box style={{ marginTop: "10px", width: "max-content", }} key={el.id}>
                        <Avatar onClick={toggleChat} style={{ cursor: "pointer", width: "40px", height: "40px" }} src={el.avatar} />
                      </Box>
                    ))
                  }
                  {
                    data.map((el) => (
                      <Box style={{ marginTop: "10px", width: "max-content", }} key={el.id}>
                        <Avatar onClick={toggleChat} style={{ cursor: "pointer", width: "40px", height: "40px" }} src={el.avatar} />
                      </Box>
                    ))
                  }
                  {
                    data.map((el) => (
                      <Box style={{ marginTop: "10px", width: "max-content", }} key={el.id}>
                        <Avatar onClick={toggleChat} style={{ cursor: "pointer", width: "40px", height: "40px" }} src={el.avatar} />
                      </Box>
                    ))
                  }
                  {
                    data.map((el) => (
                      <Box style={{ marginTop: "10px", width: "max-content", }} key={el.id}>
                        <Avatar onClick={toggleChat} style={{ cursor: "pointer", width: "40px", height: "40px" }} src={el.avatar} />
                      </Box>
                    ))
                  }
                  </div>


                </Box>
                {

                  isOpen ? (

                    <Box sx={{ width: "100%" }}>
                      <Conversation />
                    </Box>
                  ) : null
                }

              </Box>
            </Paper>
          </div>
        </div>
      </Root>
    </>
  );
}