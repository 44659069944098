import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    InputAdornment,
    FormControlLabel,
    Typography,
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import TelegramIcon from "@mui/icons-material/Telegram";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import axios from "axios";
import backendUrl from "../../../../../src/configmain";
import { styled } from "@mui/material/styles";

const timeZones = [
    { value: '', label: 'None' },
    { value: 'Etc/GMT+1', label: 'GMT +1:00' },
    { value: 'Etc/GMT+2', label: 'GMT +2:00' },
    { value: 'Etc/GMT+3', label: 'GMT +3:00' },
    { value: 'Etc/GMT+4', label: 'GMT +4:00' },
    { value: 'Etc/GMT+5', label: 'GMT +5:00' },
    { value: 'Etc/GMT+6', label: 'GMT +6:00' },
    { value: 'Etc/GMT+7', label: 'GMT +7:00' },
    { value: 'Etc/GMT+8', label: 'GMT +8:00' },
    { value: 'Etc/GMT+9', label: 'GMT +9:00' },
    { value: 'Etc/GMT+10', label: 'GMT +10:00' },
    { value: 'Etc/GMT+11', label: 'GMT +11:00' },
    { value: 'Etc/GMT+12', label: 'GMT +12:00' },
    { value: 'Etc/GMT+13', label: 'GMT +13:00' },

];

const useStyles = styled((theme) => ({
    customTextField: {
        '& .MuiInputBase-root.Mui-focused': {
            borderColor: '#279d85',
        },
    },
}));

const CreateVideoSlideForm = ({ selectedTab }) => {
    const [carousels, setCarousels] = useState([
        // Initial carousel data
        { sliderLink: "", textMessage: "" },
    ]);
    const [isSwitchOn, setIsSwitchOn] = React.useState(true);
    const [selectedTimeZone, setSelectedTimeZone] = useState('');
    const [selectedDateTime, setSelectedDateTime] = useState(dayjs('2022-04-17T15:30'));
    const [timeInterval, setTimeInterval] = useState("")
    const [postReport, setPostReport] = useState('');
    const [apiResponse, setApiResponse] = useState('');
    const [pageOptions, setPageOptions] = useState([]);
    const [textMessage, setTextMessage] = useState('');
    const [imageDurationtime, setImageDurationTime] = useState("")
    const [pages, setPages] = useState([])
    const [campaignName, setCampaignName] = useState('');
    const [transitionDuration, setTransitionDuration] = useState("")
    const classes = useStyles();

    const [carousel, setCarousel] = useState("")
    const handlePages = (event) => {
        setPages(event.target.value);
    }
    const handleGoBack = () => {
        window.history.back();
    };


    const handleDateTimeChange = (newDateTime) => {
        setSelectedDateTime(newDateTime);
        // console.log('Selected DateTime:', newDateTime.format()); // Log the selected date and time
    };

    const handleTimeIntervalChange = (event) => {
        setTimeInterval(event.target.value)
    }


    const handleCampaignNameChange = (event) => {
        setCampaignName(event.target.value)
    }

    const handleTextMessege = (event) => {
        setTextMessage(event.target.value)
    }
    const handleImageChange = (event) => {
        setImageDurationTime(event.target.value)
    }
    const handleDurationChange = (event) => {
        setTransitionDuration(event.target.value)
    }
    const handleCarouselChange = (event) => {
        setCarousel(event.target.value)
    }
    const handlePostReportChange = (event) => {
        setPostReport(event.target.value)
    };
    const token = localStorage.getItem('authorization');
    let headers = {
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
    };
    useEffect(() => {
        axios.get(`${backendUrl}/api/user/getPages`, {
            headers
        })
            .then(response => {
                setPageOptions([response.data.getPage]);

                // console.log(response.data.getPage)
            })
            .catch(error => {
                console.error('Error fetching page options:', error);
            });
    }, []);



    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = {
            campaignName: campaignName,
            postToPages: pages,
            messege: textMessage,
            imageDuration: imageDurationtime,
            transitionDuration: transitionDuration,
            // postType: selectedTab === 0 ? "Carousel" : "Video Slide",
            // carousel: carousel,
            // videoSlide,
            // repostThisPost,
            // postingTime,
            // secheduledTime,
            // timeZone,
            // timeInterval,
            ...(!isSwitchOn
                ? {} // If switch is off, no additional fields are included in the payload
                : {
                    repostThisPost: postReport,
                    secheduledTime: selectedDateTime.toISOString(),
                    timeZone: selectedTimeZone,
                    timeInterval: timeInterval,
                }),
        };


        axios.post(`${backendUrl}/api/carousel/`, formData, {
            headers
        })
            .then(response => {
                setApiResponse(response.data);
            })
            .catch(error => {
                console.error('Error posting data:', error);
            });
    };


    const handleTimeZoneChange = (event) => {
        const newTimeZone = event.target.value;
        setSelectedTimeZone(newTimeZone);

        // Find the selected time zone object
        const selectedTimeZoneObj = timeZones.find((zone) => zone.value === newTimeZone);

        // Log the selected time zone's data
        if (selectedTimeZoneObj) {
            // console.log('Selected Time Zone:', selectedTimeZoneObj.label);
            // console.log('Offset:', selectedTimeZoneObj.offset);
        }
    };

    const handleSwitchChange = (event) => {
        setIsSwitchOn(!isSwitchOn);

    };
    const addCarousel = () => {
        setCarousels([...carousels, { sliderLink: "", textMessage: "" }]);
    };

    // const handleCarouselChange = (index, field, value) => {
    //     const updatedCarousels = [...carousels];
    //     updatedCarousels[index][field] = value;
    //     setCarousels(updatedCarousels);
    // };
    return (
        <Box>
            <Typography variant="h6">Video Slide</Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
                <Box sx={{ width: "50%" }}>

                    <Typography>Campaign Name</Typography>
                    <TextField sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: "#279d85" } }} className={classes.customTextField} value={campaignName} onChange={handleCampaignNameChange} fullWidth mt={1} />
                </Box>

                <Box sx={{ width: "50%" }}>
                    <FormControl fullWidth>
                        <Typography>Post to Pages</Typography>
                        <FormControl sx={{
                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#279d85"
                            }
                        }} variant="outlined" style={{ width: '100%', mt: 1 }}>
                            <Select

                                value={pages}
                                onChange={handlePages}

                            >
                                <MenuItem value="">Select</MenuItem>
                                {pageOptions.map(option => (
                                    <MenuItem key={option._id} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormControl>
                </Box>

            </Box>
            <Box>
                <TextField
                    label="Type your message here..."
                    fullWidth
                    multiline
                    rows={4}
                    // sx={{  }}
                    value={textMessage}
                    onChange={handleTextMessege}
                    sx={{
                        mt: 2,
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#279d85",

                        },
                    }}

                    InputLabelProps={{
                        sx: {
                            '&.Mui-focused': {
                                color: '#279d85',

                            },
                        },
                    }}
                />
            </Box>
            <Box style={{ marginTop: "1%" }} display="flex" flexDirection="row">
                <FormControl fullWidth sx={{
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: "#279d85",

                    }
                }}>
                    <Typography>Image Duration</Typography>
                    <Select mt={1} value={imageDurationtime} onChange={handleImageChange}>
                        <MenuItem value="1 second">1 second</MenuItem>
                        <MenuItem value="2 second">2 second</MenuItem>
                        <MenuItem value="3 second">3 second</MenuItem>
                        <MenuItem value="4 second">4 second</MenuItem>
                        <MenuItem value="5 second">5 second</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{
                    marginLeft: "8px", '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: "#279d85",

                    },
                }}>
                    <Typography>Transition Duration</Typography>
                    <Select value={transitionDuration} onChange={handleDurationChange} >
                        <MenuItem value="1 second">1 second</MenuItem>
                        <MenuItem value="2 second">2 second</MenuItem>
                        <MenuItem value="3 second">3 second</MenuItem>
                        <MenuItem value="4 second">4 second</MenuItem>
                        <MenuItem value="5 second">5 second</MenuItem>
                    </Select>
                </FormControl>
            </Box>




            <Button
                variant="outlined"
                onClick={addCarousel}
                sx={{
                    mt: 2,
                    marginLeft: "80%",
                }}
                style={{
                    color: '#279d85',
                    borderColor: '#279d85',
                }}
            >
                + Add More Image
            </Button>

            <Box mt={2}>
                <Typography>Posting time</Typography>

                <FormControlLabel
                    control={
                        <Switch
                            checked={isSwitchOn}
                            onChange={handleSwitchChange}
                            style={{ color: "#279d85" }}
                        />
                    }
                    label="Post now"
                />
                {isSwitchOn && (
                    <Box mt={2}>
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <Box sx={{ width: "50%" }}>
                                <Typography>Schedule time</Typography>
                                <FormControl sx={{
                                    width: "100%",
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                "DateTimePicker",
                                                "MobileDateTimePicker",
                                                "DesktopDateTimePicker",
                                                "StaticDateTimePicker",
                                            ]}
                                        >
                                            <DemoItem>
                                                <MobileDateTimePicker
                                                    value={selectedDateTime}
                                                    onChange={handleDateTimeChange}
                                                />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                                <Typography>Time zone</Typography>



                                <FormControl sx={{
                                    width: '100%', mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}>
                                    <Select value={selectedTimeZone} onChange={handleTimeZoneChange}>
                                        {timeZones.map((item, index) => (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                            <Box sx={{ width: "50%" }}>
                                <Typography>Repost this post</Typography>

                                <FormControl

                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        width: "100%", mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: "#279d85",
                                        },
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        type="number"
                                        variant="outlined"
                                        value={postReport}
                                        onChange={handlePostReportChange}
                                        inputProps={{
                                            step: 1,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    Times
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ width: "50%" }}>
                                <Typography>Time interval</Typography>
                                <FormControl
                                    size="small"

                                    sx={{
                                        width: "100%", mt: 1, '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: "#279d85",
                                        },
                                    }}
                                >
                                    <Select value={timeInterval} onChange={handleTimeIntervalChange}>
                                        <MenuItem value="every 1 months">every 1 months</MenuItem>
                                        <MenuItem value="every 2 months">every 2 months</MenuItem>
                                        <MenuItem value="every 6 months">every 6 months</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>

            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', marginTop: "20px" }}>
                <Button
                    variant="outlined"
                    color="success"
                    startIcon={<TelegramIcon />}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                   
                    style={{ color: 'red' }}
                    onClick={handleGoBack}
                >
                    <CancelIcon  style={{ marginRight:"7px", fontSize:'20px'}}/>
                    Cancel
                </Button>
            </div>
        </Box>
    );
};

export default CreateVideoSlideForm;
