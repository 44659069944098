import React from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Add } from "@mui/icons-material";
import { Box, Button, InputAdornment, TextField, Typography} from "@mui/material";
import { Link } from "react-router-dom";

function ContactHeader() {
  return (
    <>
      <div className="p-24 sm:p-32 w-full">
        <Box>
          <Typography variant="h4" sx={{ fontWeight: "900", marginBottom: '1rem' }}>
            Add New Group
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "1rem", marginBottom: '1.5rem' }}>
            <TextField id="" label=""
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#279d85",
                },

                
            }}
             InputProps={{
                style: {
                    borderRadius: "50px",
                },

                

                placeholder: "Search Group",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
              size="small"
            />
            <Button
              style={{
                minWidth: "fit-content",
                backgroundColor: "#279d85",
                borderRadius: '3rem',
                color:"#fff",
                

              }}
              className="mx-8"
              variant="contained"
              // color="#fff"
              component={Link}
              to="new/editGroup"
            >
              <Add />
              <span style={{color:"#fff"}} className="mx-8">Add New Group</span>
            </Button>
          </Box>
      </div>
    </>
  );
}

export default ContactHeader;
