import React from "react";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import {Link} from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
export default function TemplatesHeader() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          mt: 2,
        }}
      >
        <Box 
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LibraryBooksOutlinedIcon
            sx={{ fontSize: 24, marginRight: 2, }}
          />
         <Typography variant="h6" >
          WhatsApp Templates
         </Typography>
        </Box>
         <Link to="/createTemplate">
        <Button
        style={{
            background:"#279d85",
            // borderRadius:"30px"
        }}
          variant="contained"
          // component={Link}
          color="primary"
          
        >
        + Create New Templates
        </Button>
         </Link>
      </Box>
    </>
  );
}
