import { Box, Typography, Stack, IconButton, Avatar, TextField, Button, Divider, InputAdornment, Paper, Menu, MenuItem, Drawer, } from '@mui/material'
import { CircleDashed } from 'phosphor-react'
import p1 from "../../../assets/p1.jpg"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Profile_Menu } from './newData/NewData';
import React from 'react'
import NewUsers from './NewUsers';
import NewConversation from './NewConversation';
import NewContact from './NewContact';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { connect } from 'react-redux';
import { toggleSidebar, setTypeContact } from '../../../redux/slices/app';
import { faker } from '@faker-js/faker'
import NewSharedMssages from './NewSharedMssages';
import { Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import NewStarredMessages from './NewStarredMessages';
import { useState } from 'react';
const ProfileOption = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEditProfileClick = () => {
        // Open the modal for editing the profile
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        // Close the modal
        setModalOpen(false);
    };
    return (
        <>
            <IconButton id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                <KeyboardArrowDownIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <Stack spacing={1} px={1} >
                    {Profile_Menu.map((el, index) => (
                        <Box key={index}>
                            <Stack flexDirection={'row'}>
                                <MenuItem onClick={index === 0 ? handleEditProfileClick : undefined}>
                                    {el.icon}
                                </MenuItem>
                                <MenuItem onClick={index === 0 ? handleEditProfileClick : undefined}>
                                    {el.title}
                                </MenuItem>
                            </Stack>
                        </Box>
                    ))}
                </Stack>
                {/* Modal for editing profile */}
                <Dialog open={isModalOpen} onClose={handleCloseModal}
                    maxWidth="xs"
                    fullWidth>
                    <DialogTitle>Edit Profile</DialogTitle>
                    <DialogContent>
                        <Stack
                            spacing={2} alignItems="center" >
                            <Avatar
                                src={p1}
                                alt={faker.name.firstName()}
                                sx={{ height: 64, width: 64, cursor: 'pointer' }}>
                            </Avatar>
                            <IconButton
                                color="primary"
                                component="label"
                                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <PhotoCameraIcon />
                                <input type="file" hidden />
                            </IconButton>
                        </Stack>
                        <Stack>
                            <TextField
                                label="Name"
                                fullWidth
                                margin="normal" />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} color="primary">
                            Close
                        </Button>
                        <Button onClick={handleCloseModal} color="primary">
                            save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Menu>
        </>
    )
}
const NewChatBot = ({ isSidebarOpen, type }) => {
    return (
        <>
            <Stack direction="row" gap={1}  >
                <Box sx={{ background: "#fff", height: "90vh", overflow: "scroll", width: 'max-content' }}  >
                    <Stack p={3}>
                        <Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: "pointer", }}  >
                            <Stack
                                direction="row" spacing={2} alignItems="center" >
                                <Avatar src={p1} alt={faker.name.firstName()} sx={{ height: 64, width: 64 }} />
                                <Typography sx={{ fontWeight: 600, fontSize: "18px", my: "auto" }}>
                                    John
                                </Typography>
                            </Stack>
                            <Box flexGrow={1} />
                            <ProfileOption />
                        </Stack>
                        <Stack mt={2} >
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Search..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>),
                                }} />
                        </Stack>
                        <Stack mt={2} spacing={1}  >
                            <Stack direction="row" alignItems="center" spacing={1.2}>
                                <ArchiveIcon size={24} />
                                <Button sx={{ textTransform: "none" }}> Archived</Button>
                            </Stack>
                            <Divider />
                        </Stack>
                        <Stack sx={{ height: "100%", overflowY: "scroll", }}>
                            <NewUsers />
                        </Stack>
                    </Stack>
                </Box>
                <Box sx={{ background: "#fff", height: "90vh", width: "100%" }} p={3}>
                    <NewConversation />
                </Box>
                {isSidebarOpen && (
                    // <Drawer
                    <Drawer
                        anchor="right" // or 'left', 'top', 'bottom', as per your design
                        variant="temporary"
                        open={isSidebarOpen}
                        onClose={() => {
                            /* function to set isSidebarOpen to false */
                        }}
                    >
                        {type === "SHARED" ? (
                            <Box sx={{ background: "#fff", width: 400 }} p={3}>
                                <NewSharedMssages />
                            </Box>
                        ) : type === "CONTACT" ? (
                            <Box sx={{ background: "#fff", width: 400 }} p={3}>
                                <NewContact />
                            </Box>
                        ) : type === "STARRED" ? (
                            <Box sx={{ background: "#fff", width: 400 }} p={3}>
                                <NewStarredMessages />
                            </Box>
                        ) : null}
                    </Drawer>
                )}
            </Stack>

        </>
    )
}

const mapStateToProps = (state) => ({
    isSidebarOpen: state.sidebar.isSidebarOpen,
    type: state.sidebar.type,
});
const mapDispatchToProps = {
    toggleSidebar,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewChatBot)
