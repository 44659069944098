const timeZones = [
    { value: '', label: 'None' },
    { value: 'Etc/GMT+1', label: 'GMT +1:00' },
    { value: 'Etc/GMT+2', label: 'GMT +2:00' },
    { value: 'Etc/GMT+3', label: 'GMT +3:00' },
    { value: 'Etc/GMT+4', label: 'GMT +4:00' },
    { value: 'Etc/GMT+5', label: 'GMT +5:00' },
    { value: 'Etc/GMT+6', label: 'GMT +6:00' },
    { value: 'Etc/GMT+7', label: 'GMT +7:00' },
    { value: 'Etc/GMT+8', label: 'GMT +8:00' },
    { value: 'Etc/GMT+9', label: 'GMT +9:00' },
    { value: 'Etc/GMT+10', label: 'GMT +10:00' },
    { value: 'Etc/GMT+11', label: 'GMT +11:00' },
    { value: 'Etc/GMT+12', label: 'GMT +12:00' },
    { value: 'Etc/GMT+13', label: 'GMT +13:00' },
];


const timeZoneSecond=[
    { value: 'Antarctica/Casey', label: '(GMT+8:00) Antarctica/Casey (Western Standard Time (Australia))' },
    { value: 'Australia/Perth', label: '(GMT+8:00) Australia/Perth (Western Standard Time)' },
    { value: 'Asia/Ulaanbaatar', label: '(GMT+8:00) Asia/Ulaanbaatar (Ulaanbaatar Time)' },
    { value: 'Asia/Choibalsan', label: '(GMT+8:00) Asia/Choibalsan (Choibalsan Time)' },
    { value: 'Asia/Kuala_Lumpur', label: '(GMT+8:00) Asia/Kuala_Lumpur (Malaysia Time)' },
]

//---------------------------locale-------------------------------------------//


const localeOptions = [
    { value: '', label: 'None' },
    { value: 'en-US', label: 'English (United States)' },
    { value: 'en-GB', label: 'English (United Kingdom)' },
    { value: 'en-AU', label: 'English (Australia)' },
    { value: 'en-CA', label: 'English (Canada)' },
    { value: 'fr-FR', label: 'French (France)' },
    { value: 'fr-CA', label: 'French (Canada)' },
    { value: 'es-ES', label: 'Spanish (Spain)' },
    { value: 'es-MX', label: 'Spanish (Mexico)' },
    { value: 'de-DE', label: 'German (Germany)' },
    { value: 'it-IT', label: 'Italian (Italy)' },
    { value: 'pt-BR', label: 'Portuguese (Brazil)' },
    { value: 'pt-PT', label: 'Portuguese (Portugal)' },
    { value: 'nl-NL', label: 'Dutch (Netherlands)' },
    { value: 'sv-SE', label: 'Swedish (Sweden)' },
    { value: 'da-DK', label: 'Danish (Denmark)' },
    { value: 'no-NO', label: 'Norwegian (Norway)' },
    { value: 'fi-FI', label: 'Finnish (Finland)' },
    { value: 'ru-RU', label: 'Russian (Russia)' },
    { value: 'zh-CN', label: 'Chinese (Simplified)' },
    { value: 'zh-TW', label: 'Chinese (Traditional)' },
    { value: 'ja-JP', label: 'Japanese (Japan)' },
    { value: 'ko-KR', label: 'Korean (South Korea)' },
    { value: 'ar-SA', label: 'Arabic (Saudi Arabia)' },
    { value: 'hi-IN', label: 'Hindi (India)' },
    { value: 'tr-TR', label: 'Turkish (Turkey)' },
    { value: 'pl-PL', label: 'Polish (Poland)' },
    { value: 'cs-CZ', label: 'Czech (Czech Republic)' },
    { value: 'th-TH', label: 'Thai (Thailand)' },
    { value: 'el-GR', label: 'Greek (Greece)' },
    { value: 'ta-IN', label: 'Tamil (India)' },
    { value: 'te-IN', label: 'Telugu (India)' },
    { value: 'bn-BD', label: 'Bengali (Bangladesh)' },  ];
  

export  {localeOptions,timeZones,timeZoneSecond}