import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import KeyIcon from "@mui/icons-material/Key";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import CancelIcon from '@mui/icons-material/Cancel';
import TelegramIcon from "@mui/icons-material/Telegram";
import {
  Paper,
  Typography,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import toast, { Toaster } from "react-hot-toast";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/system";
import DialogContent from "@mui/material/DialogContent";
import { RadioGroup, FormControlLabel } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import chat from "../icons/chat.png";

import EmojiPicker from "emoji-picker-react";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link, useNavigate } from "react-router-dom";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import FormatBoldOutlinedIcon from "@mui/icons-material/FormatBoldOutlined";
import FormatItalicOutlinedIcon from "@mui/icons-material/FormatItalicOutlined";
import CallIcon from "@mui/icons-material/Call";
import { Document, Page } from "react-pdf";
import imgs from "../icons/imgs.png";
import dox from "../icons/dox.png";

import media from "../icons/media.png";

import axios from "axios";

import { TextField, Select, MenuItem, InputLabel } from "@mui/material";
import languageOptions from "./language";
// import { toast } from 'react-toastify';

import { useRef } from "react";
import backendUrl from "../../../configmain";
export default function CreateTemplates() {
  const navigate = useNavigate();
  const [templateName, setTemplateName] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false); // this state manages the success dialog
  const [isTempError, setIsTempError] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLangError, setIsLangError] = useState(false)
  const [isMsgError, setIsMsgError] = useState(false)
  const [pdfPages, setPdfPages] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const showSuccessDialog = () => {
    setSuccessDialogOpen(true);
  };
  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);
    navigate(-1)
  };

  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
    if (!templateName.includes(' ') || templateName == templateName.toLowerCase()) {
      setIsTempErrorSpace(false)
    }
    setIsTempError(false)

  };
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    setIsLangError(false)
  };
  const handleCancelClick = () => {
    navigate(-1);
  };
  // this is edit page
  const [text, setText] = useState("");
  const [message, setMessage] = useState("");
  const [footerMessage, setFooterMessage] = useState("");
  const [selectedButton, setSelectedButton] = useState("");
  const [inputFields, setInputFields] = useState([{ id: 1 }]);
  const [quickReplyValue, setQuickReplyValue] = useState([""]);
  const [textFields, setTextFields] = useState([{ id: 1 }]);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const inputRef = useRef();
  const [bodyText, setBodyText] = useState([]);
  const [extraInputCount, setExtraInputCount] = useState(0);
  const hiddenFileInputRef = useRef(null);
  const [selectedMedia, setSelectedMedia] = useState("");
  const [mediaName, setMediaName] = useState("");
  const [mobileText, setmobileText] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [websiteText, setwebsiteText] = useState("");
  const [website, setWebsite] = useState("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(['']);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [isTempErrorSpace, setIsTempErrorSpace] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null);
  const [isTempErrorUpperCase, setIsTempErrorUpperCase] = useState(false)
  const handleSelect = (cardValue) => {
    setSelectedCard(cardValue);
    setIsError(false)
    // console.log("Selected Card:", cardValue);
  };
  const isCardSelected = (cardValue) => {
    // console.log(cardValue)
    return selectedCard === cardValue;
  };
  const handleEmojiClick = (event) => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };
  const handleEmojiSelect = (emoji) => {
    setMessage((prevBodyMessage) => prevBodyMessage + emoji.emoji);
    setIsEmojiPickerOpen(false);
  };
  const handleTextChange = (e) => {
    setText(e.target.value);
  };
  const handleBodyChange = (e) => {
    const newValue = e.target.value;
    setMessage(newValue);
    setIsMsgError(false)
    const pattern = /\{\{\d\}\}/g;
    const matches = newValue.match(pattern);
    if (matches) {
      setExtraInputCount(Math.min(matches.length, 4));
    } else {
      setExtraInputCount(0);
    }
  };
  const handleMediaChange = (event) => {
    setMediaName(event.target.value);
  };
  const handleExtraInputChange = (event, index) => {
    const newValues = [...bodyText];
    newValues[index] = event.target.value;
    setBodyText(newValues);
  };
  const handleRemoveExtraInput = (index) => {
    const newValues = [...bodyText];
    newValues.splice(index, 1);
    setBodyText(newValues);
    setExtraInputCount(extraInputCount - 1);
  };
  const handleBoldClick = () => {
    const selectedText = window.getSelection().toString();
    const updatedMessage = message.replace(selectedText, `*${selectedText}*`);
    setMessage(updatedMessage);
  };
  const handleItalicClick = () => {
    const selectedText = window.getSelection().toString();
    const updatedMessage = message.replace(selectedText, `_${selectedText}_`);
    setMessage(updatedMessage);
  };
  const handleFooterChange = (e) => {
    setFooterMessage(e.target.value);
  };
  const handleButtonChange = (event) => {
    setSelectedButton(event.target.value);
  };
  const handleAddMore = () => {
    if (inputFields.length < 3) {
      setInputFields([...inputFields, { id: inputFields.length + 1 }]);
      setQuickReplyValue([...quickReplyValue, ""]);
    }
    setTextFields([...textFields, { id: textFields.length + 1 }]);
  };
  const handleDelete = (id) => {
    const updatedInputFields = inputFields.filter((field) => field.id !== id);
    setInputFields(updatedInputFields);
    const updatedInputValues = quickReplyValue.filter(
      (value, index) => index !== id - 1
    );
    setQuickReplyValue(updatedInputValues);
  };
  const handleInputChange = (index, value) => {
    const updatedInputValues = [...quickReplyValue];
    updatedInputValues[index] = value;
    setQuickReplyValue(updatedInputValues);
  };
  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const handleSubmit = () => {
    if (!selectedCard) {
      setIsError(true)
      return toast.error(`Error: Please Select the card`);
    }
    if (!templateName) {
      // console.log(templateName)
      setIsTempError(true)
      return toast.error(`Error: Please Write Template name`);
    }
    if (templateName.includes(' ') || templateName !== templateName.toLowerCase()) {
      setIsTempErrorSpace(true);
      return toast.error(`"Template name cannot contain spaces or uppercase letters"`)
    }



    if (!selectedLanguage) {
      setIsLangError(true)
      return toast.error(`Error: Please select the language !`);
    }


    let formData = new FormData();
    formData.append("file", selectedMedia);
    let token = localStorage.getItem("authorization");
    let headers = {
      Authorization: token,
    };

    const callToAction = [];
    let header = {};
    if (mediaName === "IMAGE") {
      header = {
        mediaType: "IMAGE",
      };
    } else if (mediaName === "TEXT") {
      header = {
        mediaType: "TEXT",
        text: text,
      };
    }

    // Append fields to formData
    formData.append("category", selectedCard);
    formData.append("templateName", templateName);
    formData.append("language", selectedLanguage);
    formData.append("header", JSON.stringify(header));
    formData.append(
      "body",
      JSON.stringify({ body_text: bodyText, messege: message })
    );

    if (!message) {
      setIsMsgError(true)
      return toast.error(`Error: Please Write message`);
    }
    formData.append("footer", JSON.stringify({ footerMessege: footerMessage }));
    formData.append("buttonType", selectedButton);
    // console.log(formData);

    // Handle call to actions and button type payloads
    if (selectedButton === "QUICK_REPLY") {
      formData.append("quickReply", JSON.stringify({ text: quickReplyValue }));
    }
    if (mobileNumber && mobileText) {
      callToAction.push({
        type: "PHONE_NUMBER",
        phoneNumber: mobileNumber,
        text: mobileText,
      });
    }
    if (website && websiteText) {
      callToAction.push({
        type: "URL",
        websiteUrl: website,
        text: websiteText,
      });
    }
    if (callToAction.length > 0) {
      formData.append("callToAction", JSON.stringify(callToAction));
    }
    // let payloads = formData
    // console.log(formData);
    axios
      .post(`${backendUrl}/api/whatsapp`, formData, { headers })
      .then((res) => {

        if (res.status === 201) {
          showSuccessDialog();
          toast.success(`Success: ${res.status} - ${res.message}`);
        } else {
          console.error("An error occurred: Unexpected response");
        }
      })
      .catch((error) => {

        handleError(error);
        console.log(error.response.status);

      });
    // console.log(formData);
  };
  const handleError = (error) => {
    if (error.response) {
      const { status, data } = error.response;
      if (status >= 400 && status < 500) {
        setErrorMessage(data.message);
        setIsErrorDialogOpen(true);
      } else if (status === 500) {
        setErrorMessage(data.message);
        setIsErrorDialogOpen(true);
      } else if (status === 401) {
        console.log("This triggers for unauthorized error");
        setErrorMessage(data.message);
        setIsErrorDialogOpen(true);
      }

    } else {
      // Network error
      setErrorMessage('Network Error: Unable to connect to the server.');
      setIsErrorDialogOpen(true);
    }
  };

  const closeErrorDialog = () => {
    setIsErrorDialogOpen(false);
  };

  const handleCardClick = (mediaType) => {
    hiddenFileInputRef.current.click();
  };
  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    setSelectedMedia(e.target.files[0]);
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setNumPages(null); // Reset numPages
      setPageNumber(1); // Reset pageNumber
    } else {
      // Handle non-PDF files or no file selected
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // console.log("dsfjds", selectedMedia)


  return (
    <>
      <Box p={2}>
        <Toaster />
        <Box mt={2}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              sx={{
                maxHeight: "100vh",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  borderRadius: "0px",
                  p: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <h2>New message template</h2>
                </Box>
              </Paper>
              <Box
                sx={{
                  mt: 1,
                }}
              >
                <Paper sx={{ borderRadius: "0px", p: 1 }}>
                  <Typography sx={{ fontWeight: "bold" }}> Category</Typography>
                  <Typography>
                    Choose a category that best describes your message.{" "}
                    <span style={{ color: "blue" }}>
                      learn more about categories
                    </span>{" "}
                  </Typography>
                  <div>
                    <Card
                      sx={{
                        mt: 2,
                        backgroundColor: isCardSelected("MARKETING")
                          ? "RGB(217 240 240)"
                          : "white",
                        cursor: "pointer",
                        "&:hover": {
                          background: "RGB(217 240 240)",
                        },
                      }}
                      variant={
                        isCardSelected("MARKETING") ? "elevation" : "outlined"
                      }
                      onClick={() => handleSelect("MARKETING")}
                    >
                      <CardContent>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                          <Box>
                            <VolumeUpIcon style={{ color: "#279d85" }} />
                          </Box>
                          <Box>
                            <Typography fontWeight="bold">Marketing</Typography>
                            <Typography variant="body2" color="text.secondary">
                              Promotion or information about your business,
                              products, or services or any message that is not
                              utility or authentication
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card
                      sx={{
                        mt: 2,
                        backgroundColor: isCardSelected("UTILITY")
                          ? "RGB(217 240 240)"
                          : "white",
                        cursor: "pointer",
                        "&:hover": {
                          background: "RGB(217 240 240)",
                        },
                      }}
                      variant={
                        isCardSelected("UTILITY") ? "elevation" : "outlined"
                      }
                      onClick={() => handleSelect("UTILITY")}
                    >
                      <CardContent>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <Box>
                            <NotificationsActiveIcon
                              style={{ color: "#279d85" }}
                            />
                          </Box>
                          <Box>
                            <Typography fontWeight="bold">Utility</Typography>
                            <Typography variant="body2" color="text.secondary">
                              Message about a specific transaction, account
                              order, or customer request.
                            </Typography>

                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card
                      sx={{
                        mt: 2,
                        backgroundColor: isCardSelected("AUTHENTICATION")
                          ? "RGB(217 240 240)"
                          : "white",
                        cursor: "pointer",
                        "&:hover": {
                          background: "RGB(217 240 240)",
                        },
                      }}
                      variant={
                        isCardSelected("AUTHENTICATION")
                          ? "elevation"
                          : "outlined"
                      }
                      onClick={() => handleSelect("AUTHENTICATION")}
                    >
                      <CardContent>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <Box>
                            <KeyIcon style={{ color: "#279d85" }} />
                          </Box>
                          <Box>
                            <Typography fontWeight="bold">
                              Authentication
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              One-time passwords your customers use to
                              authenticate a transaction or login.
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                    {
                      isError ? (<Typography sx={{ color: "red", marginTop: "5px", fontSize: "12px" }} >Please select the Card!</Typography>) : ("")
                    }
                  </div>
                  <Box sx={{ mt: 1 }}>
                    <Typography fontWeight="bold" component="div">
                      Name
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Enter message template name
                    </Typography>
                    <Box mt={1}>
                      <TextField
                        placeholder="Enter name"
                        variant="outlined"
                        fullWidth
                        value={templateName}
                        onChange={handleTemplateNameChange}

                        sx={{
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#279d85",
                          },
                        }}


                      />
                      {
                        isTempError ? (<Typography sx={{ color: "red", marginTop: "5px", fontSize: "12px" }} >Please Enter the Template Name!</Typography>) : ("")
                      }
                      {
                        isTempErrorSpace ? (<Typography sx={{ color: "red", marginTop: "5px", fontSize: "12px" }} >"Template name cannot contain spaces or uppercase letters"</Typography>) : ("")
                      }

                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Typography fontWeight="bold" component="div">
                        Languages
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Choose language for your message template you can delete
                        or add more language later
                      </Typography>
                      <Box>
                        <Box mt={1}>
                          <TextField
                            select
                            label="Select language"
                            variant="outlined"
                            fullWidth
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                            sx={{
                              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#279d85",
                              },
                            }}

                            InputLabelProps={{
                              sx: {
                                '&.Mui-focused': {
                                  color: '#279d85',

                                },
                              },
                            }}
                          >

                            {languageOptions.map((option) => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {
                            isLangError ? (<Typography sx={{ color: "red", marginTop: "5px", fontSize: "12px" }} >Please Select the Language!</Typography>) : ("")
                          }
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Box>

              <Paper elevation={0} sx={{ borderRadius: "0px", p: 2, mt: 2 }}>
                <Box sx={{ justifyContent: "space-between", display: "flex" }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Edit Template{" "}
                  </Typography>
                </Box>
                <Divider
                  sx={{ height: "2px", backgroundColor: "gray", mt: 2 }}
                />
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Header (optional)
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Add a title or choose which type of media you will use for
                    this header.
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <FormControl sx={{
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#279d85",
                      },
                    }} variant="outlined" fullWidth>
                      <InputLabel sx={{
                        "&.Mui-focused": {
                          color: "#279d85", // Change the label color when focused
                        },
                      }}>Media Name</InputLabel>
                      <Select
                        size="small"
                        value={mediaName}
                        onChange={handleMediaChange}
                        label="Media Name"
                      >
                        {nameOptions.map((option, index) => (
                          <MenuItem key={index} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    {mediaName && mediaName === "TEXT" ? (
                      <FormControl sx={{ mt: 2 }} fullWidth>
                        <TextField
                          multiline
                          placeholder="Enter Text"
                          variant="outlined"
                          fullWidth
                          value={text}
                          onChange={handleTextChange}
                          sx={{
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#279d85",
                            },
                          }}
                        />
                      </FormControl>
                    ) : mediaName && mediaName === "IMAGE" ? (
                      <Box>
                        <Card onClick={() => handleCardClick("IMAGE")}>
                          <CardContent>
                            <Box>
                              <input
                                type="file"
                                accept="image/*,video/*,.pdf"
                                style={{ display: "none" }}
                                ref={hiddenFileInputRef}
                                onChange={handleFileChange}
                              />
                            </Box>
                            <Box style={centerMediaStyle}>
                              <img src={imgs} alt="Your Image" />
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    ) : mediaName && mediaName === "DOCUMENT" ? (
                      <Card onClick={() => handleCardClick("DOCUMENT")}>
                        <CardContent>
                          <Box>
                            <input
                              type="file"
                              accept="image/*,video/*,.pdf"
                              style={{ display: "none" }}
                              ref={hiddenFileInputRef}
                              onChange={(e) => handleFileChange(e)}
                            />
                          </Box>
                          <Box style={centerMediaStyle}>
                            <img src={dox} alt="Media" />
                          </Box>
                        </CardContent>
                      </Card>
                    ) : mediaName && mediaName === "VIDEO" ? (
                      <Card onClick={() => handleCardClick("VIDEO")}>
                        <CardContent>
                          <Box>
                            <input
                              type="file"
                              accept="image/*,video/*,.pdf"
                              style={{ display: "none" }}
                              ref={hiddenFileInputRef}
                              onChange={handleFileChange}
                            />
                          </Box>
                          <Box style={centerMediaStyle}>
                            <img src={media} alt="Your Image" />
                          </Box>
                        </CardContent>
                      </Card>
                    ) : null}
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ fontWeight: "bold" }}>Body</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Enter the text for your message in the language you have
                    selelcted
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      id="yourTextArea"
                      multiline
                      rows={6}
                      placeholder="Enter Text"
                      variant="outlined"
                      fullWidth
                      value={message}
                      onChange={handleBodyChange}
                      inputRef={inputRef}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#279d85",
                        },
                      }}
                    />
                    {
                      isMsgError ? (<Typography sx={{ color: "red", marginTop: "5px", fontSize: "12px" }} >Please Enter the message!</Typography>) : ("")
                    }
                    <Box sx={{ mt: 2 }}>
                      {Array.from({ length: extraInputCount }).map(
                        (_, index) => (
                          <div
                            key={index}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <TextField
                              style={{ marginTop: "10px" }}
                              label={`Extra Input ${index + 1}`}
                              variant="outlined"
                              fullWidth
                              value={bodyText[index] || ""}
                              onChange={(event) =>
                                handleExtraInputChange(event, index)
                              }
                            />
                            <IconButton
                              onClick={() => handleRemoveExtraInput(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )
                      )}
                    </Box>
                    <div>
                      <IconButton onClick={handleBoldClick}>
                        <FormatBoldOutlinedIcon style={{ fontSize: "24px" }} />
                      </IconButton>
                      <IconButton onClick={handleEmojiClick}>
                        <EmojiEmotionsOutlinedIcon
                          style={{ fontSize: "24px" }}
                        />
                      </IconButton>
                      <IconButton onClick={handleItalicClick}>
                        <FormatItalicOutlinedIcon
                          style={{ fontSize: "24px" }}
                        />
                      </IconButton>
                      {isEmojiPickerOpen && (
                        <EmojiPicker
                          onEmojiClick={handleEmojiSelect}
                          disableAutoFocus={true}
                          groupVisibility={{ recently_used: false }}
                        />
                      )}
                    </div>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Footer (optional)
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Add a short line of text to the bottom of your message
                    template
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={footerMessage}
                      onChange={handleFooterChange}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#279d85",
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Buttons (optional)
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Add a button
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={selectedButton}
                      onChange={handleButtonChange}
                    >
                      <FormControlLabel
                        value="QUICK_REPLY"
                        control={<Radio sx={{
                          '&.Mui-checked': {
                            color: '#279d85', // Change the color when selected (clicked)
                          },
                        }} />}
                        label="Quick reply"
                      />
                      <FormControlLabel
                        value="CALL_TO_ACTION"
                        control={<Radio sx={{
                          '&.Mui-checked': {
                            color: '#279d85', // Change the color when selected (clicked)
                          },
                        }} />}
                        label="Call to action"


                      />
                    </RadioGroup >
                  </FormControl>
                  {selectedButton === "QUICK_REPLY" && (
                    <Box>
                      {inputFields.map((field, index) => (
                        <div key={field.id}>
                          <Box display="flex" alignItems="center" >
                            <TextField
                              size="small"
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 12 }}
                              value={quickReplyValue[index]}
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#279d85",
                                },
                              }}
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                            />
                            <IconButton onClick={() => handleDelete(field.id)}>
                              <DeleteIcon sx={{ color: "#279d85", }} />
                            </IconButton>
                          </Box>
                        </div>
                      ))}
                      <Button
                        variant="outlined"
                        // color="progress"
                        onClick={handleAddMore}
                        disabled={inputFields.length >= 3}
                        sx={{ backgroundColor: "#279d85", color: "#fff" }}
                      >
                        Add More
                      </Button>
                    </Box>
                  )}
                  {selectedButton === "CALL_TO_ACTION" && (
                    <Box>
                      {textFields.map((field, index) => (
                        <div key={field.id}>
                          <Box
                            display="flex"
                            alignItems="center"
                            gap={1}
                            flexDirection="column"
                            sx={{ width: "100%" }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              gap={1}
                              sx={{ width: "100%" }}
                            >
                              <TextField
                                size="small"
                                fullWidth
                                margin="normal"
                                value={mobileText}
                                onChange={(e) => setmobileText(e.target.value)}
                                inputProps={{ maxLength: 12 }}
                                sx={{
                                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#279d85",
                                  },
                                }}
                              />
                              <TextField
                                size="small"
                                fullWidth
                                margin="normal"
                                value={mobileNumber}
                                onChange={(e) =>
                                  setMobileNumber(e.target.value)
                                }
                                label="Enter Mobile Number"
                                sx={{
                                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#279d85",
                                  },
                                }}
                              />
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              gap={1}
                              sx={{ width: "100%" }}
                            >
                              <TextField

                                size="small"
                                fullWidth
                                margin="normal"
                                value={websiteText}
                                onChange={(e) => setwebsiteText(e.target.value)}
                                inputProps={{ maxLength: 12 }}
                                sx={{
                                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#279d85",
                                  },
                                }}
                              />
                              {index === 0 && (
                                <TextField
                                  size="small"
                                  fullWidth
                                  margin="normal"
                                  value={website}
                                  onChange={(e) => setWebsite(e.target.value)}
                                  label="Website"
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#279d85",
                                    },
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </div>
                      ))}
                    </Box>
                  )}
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCancelClick}
                  >
                    <CancelIcon style={{ marginRight: "7px", fontSize: '20px' }} />
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={handleSubmit}
                    startIcon={<TelegramIcon />}

                  >
                    Submit
                  </Button>
                </Box>
              </Paper>


            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={0} sx={{ borderRadius: "0px", p: 2 }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Message preview
                </Typography>
              </Paper>
              <Box sx={{ backgroundImage: `url(${chat})`, p: 1 }}>
                <MessageBubblePaper
                  elevation={1}
                  sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                >
                  <Box>
                    <div style={centerMediaStyle}>
                      {selectedMedia && (
                        <div>
                          {selectedMedia.type.startsWith("video/") ? (
                            <video controls style={{ maxWidth: "100%" }}>
                              <source
                                src={URL.createObjectURL(selectedMedia)}
                                type={selectedMedia.type}
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : selectedMedia.type === "application/pdf" ? (
                            <div>
                              <object
                                data={URL.createObjectURL(selectedMedia)}
                                type="application/pdf"
                                width="100%"
                               
                              >
                                <p>It appears you don't have a PDF plugin for this browser.</p>
                              </object>

                              <div>
                                <Button onClick={() => window.open(URL.createObjectURL(selectedMedia), '_blank')}>
                                  Open PDF
                                </Button>

                                <a
                                  href={URL.createObjectURL(selectedMedia)}
                                  download={selectedMedia.name}
                                >
                                  <Button  style={{}} >
                                    Download PDF
                                  </Button>
                                </a>
                              </div>
                            </div>
                          ) : (
                            <img
                              src={URL.createObjectURL(selectedMedia)}
                              alt="Selected Media"
                              style={{ maxWidth: "100%" }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </Box>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {text}
                  </Typography>
                  <Typography>{message}</Typography>
                  <Typography>{footerMessage}</Typography>
                </MessageBubblePaper>
                {selectedButton === "QUICK_REPLY" ? (
                  <Box>
                    <Box sx={{ display: "flex", gap: "1%" }}>
                      <Paper
                        elevation={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "37%",
                          borderRadius: "2px",
                          fontSize: "10px",
                          mt: 1,
                        }}
                      >
                        <Typography
                          component={Link}
                          role="button"
                          sx={{
                            P: 2,
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            width: "100%",
                            borderRadius: "5px",
                            color: "blue",
                            fontSize: "12px",
                          }}
                        >
                          {quickReplyValue[0]}
                        </Typography>
                      </Paper>
                      <Paper
                        elevation={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "37%",
                          borderRadius: "2px",
                          fontSize: "10px",
                          mt: 1,
                        }}
                      >
                        <Typography
                          component={Link}
                          role="button"
                          sx={{
                            P: 2,
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            width: "100%",
                            borderRadius: "5px",
                            color: "blue",
                            fontSize: "12px",
                          }}
                        >
                          {quickReplyValue[1]}
                        </Typography>
                      </Paper>
                    </Box>
                    <Box>
                      <Paper
                        elevation={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "75%",
                          borderRadius: "2px",
                          fontSize: "10px",
                          mt: 1,
                        }}
                      >
                        <Typography
                          component={Link}
                          role="button"
                          sx={{
                            P: 2,
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            width: "100%",
                            borderRadius: "5px",
                            color: "blue",
                            fontSize: "12px",
                          }}
                        >
                          {quickReplyValue[2]}
                        </Typography>
                      </Paper>
                    </Box>
                  </Box>
                ) : selectedButton === "CALL_TO_ACTION" ? (
                  <Paper
                    elevation={2}
                    sx={{
                      alignItems: "center",
                      width: "75%",
                      borderRadius: "2px",
                      mt: 1,
                    }}
                  >
                    <Divider mt={1} />
                    <Box
                      sx={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component={Link}
                        role="button"
                        sx={{
                          P: 2,
                          display: "flex",

                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          width: "100%",
                          borderRadius: "5px",
                          color: "blue",
                          fontSize: "12px",
                        }}
                      >
                        <CallIcon />
                        {mobileText}
                      </Typography>
                    </Box>
                    <Divider mt={1} />
                    <Box
                      sx={{
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component={Link}
                        role="button"
                        sx={{
                          P: 2,
                          display: "flex",


                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                          width: "100%",
                          borderRadius: "5px",
                          color: "blue",
                          fontSize: "12px",
                        }}
                      >
                        <LaunchIcon />
                        {websiteText}
                      </Typography>
                    </Box>
                  </Paper>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog open={isErrorDialogOpen} onClose={closeErrorDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={successDialogOpen}
        onClose={closeSuccessDialog}
        aria-labelledby="success-dialog-title"
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>Your template is in Preview</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSuccessDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
const nameOptions = [
  { label: "NONE" },
  { label: "TEXT" },
  { label: "IMAGE" },
  { label: "VIDEO" },
  { label: "DOCUMENT" },
];
const MessageBubblePaper = styled(Paper)(({ theme }) => ({
  maxWidth: "75%",
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  borderRadius: "5px",
  position: "relative",
  "&::after": {
    // content: '""',
    position: "absolute",
    top: "50%",
    left: "10px",
    borderWidth: "10px",
    borderStyle: "solid",
    borderColor: "transparent transparent transparent #dcf8c6",
    // transform: 'translateY(-50%)',
  },
}));

const centerMediaStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  cursor: "pointer",
};
