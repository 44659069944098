import { Avatar, Box, Stack, TextField } from '@mui/material'
import React from 'react'
import SendIcon from '@mui/icons-material/Send';
import Message from './Message';
import Footer from './Footer';


export default function Conversation() {
    return (
        <>
            <Stack height={"80%"} 
            // maxHeight={"100vh"}
             width={"auto"}>
  {/* chat header */}
  <Box sx={{ width: "100%", background: "#0000" }}>
    <Stack alignItems={"center"} direction="row" justifyContent={"space-between"} sx={{ width: "100%", height: "100%" }}>
      <Stack direction={"row"} spacing={2}>
        <Box>
          {/* <Avatar /> */}
        </Box>
      </Stack>
    </Stack>
  </Box>
  {/* msg */}
  <Box width={"100%"} sx={{ flexGrow: 1, height: "100%", overflow: "scroll", width: "100%", }}>
    <Message />
  </Box>
  {/* chat footer  */}
  <Footer />
</Stack>


        </>
    )
}