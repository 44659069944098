import React from 'react';
import { AppBar, Container, Toolbar, Typography, Grid } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import CampaignHeader from './CampaignHeader';
import CampaignMain from './CampaignMain';
import RightSidebar from './RightSidebar';


function CampaignPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  
    return (
      <div>
       
  
        <Container style={{ marginTop:"20px"}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={15}>
              <CampaignHeader />


              <CampaignMain />
            </Grid>
  
            {isMobile ? (
              <Grid item xs={12} md={3}>
                <RightSidebar />
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </div>
    );
  }
  
  export default CampaignPage;
