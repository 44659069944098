import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Fab, Grid, OutlinedInput, TextField, Typography, Switch, FormControlLabel, FormControl, InputLabel, Select } from "@mui/material";
import TelegramIcon from '@mui/icons-material/Telegram';
import axios from "axios";
import backendUrl from "../../configmain";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { styled, ThemeProvider } from "@mui/material/styles";
import { createTheme } from '@mui/material/styles';



import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from "@mui/icons-material/Add";

const addUserApi = `${backendUrl}/api/auth/register`
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const theme = createTheme();

const HeaderStyles = styled(({ theme }) => ({
  fab: {
    margin: theme.spacing(2),
    marginLeft: "2px",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function AddUser() {
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const token = localStorage.getItem('authorization');

    const navigate = useNavigate();
    const handleCancelClick = () => {
        navigate(-1);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value,
        }));

    };
    const [errors, setErrors] = useState({});
    const handleSaveClick = async () => {

        if (!validateForm()) {
            return;
        }
        const headers = {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
        };

        try {
            const response = await axios.post(addUserApi, userData, {
                headers
            });

            if (response) {
                toast.success(`You are registered : ${response.data.saveUser.name}`)
                // console.log(response)
                setIsSuccessDialogOpen(true);
                setTimeout(() => {
                    navigate(-1);
                }, 2000)
            }
        } catch (error) {
            // console.log(toast)
            toast.error(`Request Failed : ${error}`);
            console.error("Error while adding the user:", error);
        }
    };


    const [showComponentA, setShowComponentA] = useState(true);
    const toggleComponent = () => {
        setShowComponentA((prevShowComponentA) => !prevShowComponentA);
    };
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        mobile: "",
        password: "",
        confirmPassword: "",
        address: "",
    });

    const validateForm = () => {
        let hasErrors = false;
        const newErrors = {};

        if (!userData.name.trim()) {
            newErrors.name = "Name is required";
            hasErrors = true;
        }

        if (!userData.email.trim()) {
            newErrors.email = "Email is required";
            hasErrors = true;
        }

        if (
            userData.email.trim() &&
            !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(userData.email)
        ) {
            newErrors.email = "Invalid email format";
            hasErrors = true;
        }

        // if (!userData.mobile.trim()) {
        //     newErrors.mobile = "Mobile is required";
        //     hasErrors = true;
        // }

        if (!userData.password.trim()) {
            newErrors.password = "Password is required";
            hasErrors = true;
        }

        if (!userData.confirmPassword.trim()) {
            newErrors.confirmPassword = "Confirm Password is required";
            hasErrors = true;
        }

        if (userData.password !== userData.confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match";
            hasErrors = true;
        }

        // if (!userData.address.trim()) {
        //     newErrors.address = "Address is required";
        //     hasErrors = true;
        // }

        setErrors(newErrors);

        return !hasErrors;
    };

    return (
        <>
            <Toaster />
            <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: '2rem', marginTop:"2%"}}>
            <ThemeProvider theme={theme} className={HeaderStyles.fab} sx={{display:"flex"}} >
              <Fab size="small" style={{backgroundColor: '#279d85', color: '#fff', '&:hover': {backgroundColor: '#17C1E8'}}} aria-label="add">
                <AddIcon />
              </Fab>
              <Typography variant="h6" className="font-bold tracking-tight">
                Add User
              </Typography>
            </ThemeProvider>
            </Box>

            <Box sx={{ p: 2 }}>
                <Box>
                    <Box>
                        <Box >
                            <TextField
                                size="small"
                                fullWidth
                                name="name"
                                value={userData.name}
                                onChange={handleChange}
                                label="Full Name"
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                                sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}

                                InputLabelProps={{
                                    sx: {
                                        '&.Mui-focused': {
                                            color: '#279d85',

                                        },
                                    },
                                }}
                            />
                        </Box>

                        <Box sx={{ display: "flex", mt: 2 }}>
                            <TextField
                                size="small"
                                style={{ flex: 1 }}
                                fullWidth
                                name="email"
                                value={userData.email}
                                onChange={handleChange}
                                label="Email"
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                                sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}

                                InputLabelProps={{
                                    sx: {
                                        '&.Mui-focused': {
                                            color: '#279d85',

                                        },
                                    },
                                }}
                            />
                            <Box sx={{ width: 20, }} />
                            <TextField
                                size="small"
                                style={{ flex: 1 }}
                                fullWidth
                                name="mobile"
                                value={userData.mobile}
                                onChange={handleChange}
                                label="Mobile"
                                error={Boolean(errors.mobile)}
                                helperText={errors.mobile}
                                sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}

                                InputLabelProps={{
                                    sx: {
                                        '&.Mui-focused': {
                                            color: '#279d85',

                                        },
                                    },
                                }}
                            />
                        </Box>

                        <Box sx={{ display: "flex", mt: 2 }}>
                            <TextField
                                size="small"
                                style={{ flex: 1 }}
                                fullWidth
                                name="password"
                                value={userData.password}
                                onChange={handleChange}
                                label="Password"
                                type="password"
                                error={Boolean(errors.password)}
                                helperText={errors.password}
                                sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}

                                InputLabelProps={{
                                    sx: {
                                        '&.Mui-focused': {
                                            color: '#279d85',

                                        },
                                    },
                                }}
                            />
                            <Box sx={{ width: 20 }} />
                            <TextField
                                size="small"
                                style={{ flex: 1 }}
                                fullWidth
                                name="confirmPassword"
                                value={userData.confirmPassword}
                                onChange={handleChange}
                                label="Confirm Password"
                                type="password"
                                error={Boolean(errors.confirmPassword)}
                                helperText={errors.confirmPassword} sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}

                                InputLabelProps={{
                                    sx: {
                                        '&.Mui-focused': {
                                            color: '#279d85',

                                        },
                                    },
                                }}

                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                name="address"
                                value={userData.address}
                                onChange={handleChange}
                                label="Address"
                                multiline
                                rows={5}
                                error={Boolean(errors.address)}
                                helperText={errors.address}
                                sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: "#279d85",
                                    },
                                }}

                                InputLabelProps={{
                                    sx: {
                                        '&.Mui-focused': {
                                            color: '#279d85',

                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Typography> User Type </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showComponentA}
                                            onChange={toggleComponent}
                                            defaultChecked
                                            size="medium"
                                            name="Member"
                                        />
                                    }
                                    label="Member"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!showComponentA}
                                            onChange={toggleComponent}
                                            defaultChecked
                                            size="medium"
                                            name="Admin"
                                        />
                                    }
                                    label="Admin"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={<Switch defaultChecked size="medium" name="Active" />}
                                    label="Active"
                                />
                            </Grid>
                        </Grid>

                    </Box>
                    {showComponentA ? (
                        <Box>
                            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)", justifyContent: "space-between", alignItems: "center", gap: "10px" }}  >
                                <Box>
                                    <FormControl style={{ width: "100%", marginTop: "6px" }} sx={{
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: "#279d85",
                                        },
                                    }}

                                    >
                                        <InputLabel sx={{
                                            "&.Mui-focused": {
                                                color: "#279d85", // Change the label color when focused
                                            },
                                        }} >
                                            Package
                                        </InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            multiple
                                            input={<OutlinedInput label="Name" />}
                                            MenuProps={MenuProps}


                                        >

                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={["DatePicker", "DatePicker", "DatePicker"]}
                                        >
                                            <DatePicker sx={{
                                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: "#279d85",
                                                },
                                                '& .MuiFormLabel-root.Mui-focused': {
                                                    color: '#279d85',
                                                  },
                                            }}
                                            
                                                label="Picker with helper text" />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        null
                    )}
                    <div className="mt-4" style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", gap: '1rem', marginTop: '1rem' }}>

                        <Button
                            // sx={{backgroundColor: '#17C1E8', '&:hover': {backgroundColor: '#17C1E8'}}}
                            startIcon={<TelegramIcon />}
                            variant="outlined"
                            color="success"
                            onClick={handleSaveClick}
                        >
                            save
                        </Button>

                        <Button
                            // sx={{backgroundColor: '#17C1E8', '&:hover': {backgroundColor: '#17C1E8'}}}
                            variant="outlined"
                            color="error"
                            onClick={handleCancelClick}
                        >
                            <CancelIcon style={{ marginRight: "7px", fontSize: '20px' }} />

                            Cancel
                        </Button>
                    </div>
                </Box>
                <Dialog open={isSuccessDialogOpen} onClose={() => setIsSuccessDialogOpen(false)}>
                    <DialogTitle>Success</DialogTitle>
                    <DialogContent>
                        <DialogContentText>User created successfully</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsSuccessDialogOpen(false)} color="primary">OK</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}