import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { Link } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Stack, MenuItem, Menu, Grid, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import backendUrl from '../../../configmain';
import {
    Box,
    Typography,
    Button

} from '@mui/material';
import { CaretDown, Export, CalendarBlank } from 'phosphor-react';

export default function Campaignheader() {

    // Calculate the start and end dates
    const startOfMonth = dayjs().startOf('month');
    const today = dayjs();
    const [anchorEl, setAnchorEl] = useState(null);
    const [fileName, setFileName] = useState('');
    const [analyticsData, setAnalyticsData] = useState([]); // state for analytics data
    const [startDate, setStartDate] = useState(startOfMonth); // State for start date
    const [endDate, setEndDate] = useState(today);     // State for end date
    const [selectedDateOption, setSelectedDateOption] = useState('DAY'); // default value



    // Handle the date change
    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);

    };

    const handleEndDateChange = (newValue) => {
        setEndDate(newValue);

    };


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (optionKey) => {
        setAnchorEl(null);
        if (optionKey) {
            console.log('Selected date option:', optionKey);
            setSelectedDateOption(optionKey);

        }
    };
    const dateOptions = {
        'HALF_HOUR': 'HALF_HOUR',
        'DAY': 'DAY',
        'MONTH': 'MONTH',
    };

    const hiddenFileInput = useRef(null);

    const handleUploadClick = () => {
        hiddenFileInput.current.click();
    };

    const handleFileChange = (event) => {
        const fileUploaded = event.target.files[0];
        setFileName(fileUploaded.name);
        // Handle the file upload event further here (e.g., upload to a server or parse it)
    };



    // Function to fetch analytics data
    const fetchAnalyticsData = async (startDate, endDate, selectedDateOption) => {
        const token = localStorage.getItem('authorization');
        const startTimestamp = startDate ? startDate.unix() : null;
        const endTimestamp = endDate ? endDate.unix() : null;

        try {
            console.log(`${backendUrl}/api/whatsapp/analytics`)

            const response = await axios.get(`http://127.0.0.1:5000/api/whatsapp/analytics`, {
                params: {
                    start: startTimestamp,
                    end: endTimestamp,
                    granularity: selectedDateOption
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${token}`,

                }
            });
            if (response) {
                console.log(response.data.data_points)
                setAnalyticsData(response.data.data_points);

            }
        } catch (error) {
            console.log(error.response.data.error.message)
            // if (error.response) {
            //     toast.error(error.response.data.error.message)
            // }
        }
    };
    // Example usage of fetchAnalyticsData in useEffect
    useEffect(() => {
        fetchAnalyticsData(startDate, endDate, selectedDateOption);
    }, []);


    const handleApplyClick = () => {
        fetchAnalyticsData(startDate, endDate, selectedDateOption);
    };
    return (
        <>
            <ToastContainer />

            <Box sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ alignItems: "center" }}>
                    <LibraryBooksOutlinedIcon sx={{ fontSize: 24, marginRight: 2 }} />
                    Messages
                </Typography>
                <Stack direction={"row"} mt={2} spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                    <Paper >
                        <Stack direction="row" spacing={2} sx={{ p: 2 }}>
                            <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
                                <Box>

                                    <Typography variant="h6" mb={2}>
                                        Date  from
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box
                                    sx={{
                                        width: '1px',
                                        bgcolor: 'rgba(0, 0, 0, 0.12)'
                                    }}
                                />
                                <Box>

                                    <Typography variant="h6" mb={2}>
                                        Date to
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />

                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                            <Box
                                sx={{
                                    width: '1px',
                                    bgcolor: 'rgba(0, 0, 0, 0.12)'
                                }}
                            />

                            <Stack>
                                <Typography variant="h6" component="div" >
                                    Period
                                </Typography>
                                <Stack direction="row" justifyContent={"space-between"} alignItems="center" >
                                    <Stack>
                                        {selectedDateOption}
                                    </Stack>
                                    <Stack >
                                        <Button
                                            aria-controls={open ? 'customized-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}>
                                            <CaretDown size={20} />
                                        </Button>
                                        <Menu
                                            id="customized-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={() => handleClose(null)}
                                            MenuListProps={{
                                                'aria-labelledby': 'customized-button',
                                            }}>
                                            {Object.entries(dateOptions).map(([key, value]) => (
                                                <MenuItem key={key} onClick={() => handleClose(key)}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Stack>
                                    <Stack spacing={1}>
                        <Button
                            variant="outlined"
                            onClick={handleApplyClick}
                            style={{
                                color: '#279d85',          // Text color
                                borderColor: '#279d85'      // Border color
                            }}>
                            Apply now
                        </Button>
                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>
                   
                    <Stack spacing={1}>
                        {/* <Button
                            variant="outlined"
                            startIcon={<Export />}
                            onClick={handleUploadClick}
                        >
                            Export
                        </Button> */}
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        {/* {fileName && <div style={{ marginTop: '10px' }}>{fileName}</div>} */}
                    </Stack>
                    <Stack>
                        <Link to="/whatsApp/CSVmain">
                            <Button
                                variant="contained"
                                onClick={handleClick}
                                style={{ backgroundColor: "#279d85" }}
                            >
                                + New Campaign
                            </Button>
                        </Link>
                    </Stack>
                </Stack>
            </Box>
            <Grid container spacing={1}>
            {analyticsData.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <SimpleCard data={item} /> {/* Pass item to SimpleCard */}
                </Grid>
            ))}
        </Grid>


        </>
    )
}


const SimpleCard = ({ data }) => (
    <Card>
        <CardContent>
            <Typography variant="h6">
                Analytics {/* You can customize this title */}
            </Typography>

            {/* Display 'sent' and 'delivered' properties */}
            <Typography variant="body2" component="p" sx={{"mt":2}}>
                Sent: {data.sent}
            </Typography>
            <Typography variant="body2" component="p">
                Delivered: {data.delivered}
            </Typography>
        </CardContent>

    </Card>
);



