import React from 'react'
import { Bell, CaretRight, Prohibit, Star, Trash, X } from 'phosphor-react'
import { Box, Stack, Typography, IconButton } from '@mui/material'
import { connect, useDispatch } from 'react-redux';
import { setTypeShared } from '../../../redux/slices/app';
import { toggleSidebar } from '../../../redux/slices/app';

const NewStarredMssages = ({ isSidebarOpen, toggleSidebar }) => {
    const dispatch = useDispatch();

    const handleToggleSidebar = () => {
        toggleSidebar();
        // console.log("Before Toggling: isSidebarOpen", isSidebarOpen);
        // console.log("After Toggling: isSidebarOpen", !isSidebarOpen);
    };

    return (
        <>
            <Box sx={{ boxShadow: " 0px 0px 2px rgba(0,0,0 0.25)", width: "100%", }}>
                <Stack sx={{ height: "100%", }} direction={"row"} alignItems={"center"} justifyContent="space-between" spacing={3}>
                    <Typography variant='subtitle' sx={{ fontWeight: "bold", fontSize: "18px" }}>
                      Starred Messages
                    </Typography>
                    <IconButton onClick={handleToggleSidebar}>
                        <X />
                    </IconButton>
                </Stack>
            </Box>
        </>
    )
}
const mapStateToProps = (state) => ({
    isSidebarOpen: state.sidebar.isSidebarOpen,
});
const mapDispatchToProps = {
    toggleSidebar,
}
export default connect(mapStateToProps, mapDispatchToProps)(NewStarredMssages)
