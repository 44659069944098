const moduleName = [
    {
        "moduleName": "Comment Automation : Auto Comment Campaign",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Comment Automation : Auto Reply Posts",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Comment Reply Enhancers : Bulk Comment Reply Campaign",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Comment Reply Enhancers : Comment & Bulk Tag Campaign",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Comment Reply Enhancers : Comment Hide/Delete and Reply with multimedia content",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Comment Reply Enhancers : Full Page Auto Like/Share",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Comment Reply Enhancers : Full Page Auto Reply",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Conversation Promo Broadcast Send",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "E-commerce Digital Product",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "E-commerce Product Price Variation",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "E-commerce Related Products",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "mail Broadcast - Email Send",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Email Broadcast - Sequence Campaign",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Email Phone Opt-in Form Builder",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },

    {
        "moduleName": "Facebook Accounts",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Facebook Live Streaming - Campaigns",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Facebook Live Streaming - Crossposting/Auto Share/Comment",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Facebook Pages",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Facebook Pages - Subscribers/Page",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Facebook Posting : Text/Image/Link/Video Post",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Facebook Posting : Carousel/Slider Post",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Facebook Posting : CTA Post",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Flowbuilder Conditional Reply",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Flowbuilder Insignt",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Google My Business: Account Import",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Google My Business: Answer To Questions",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Google My Business: Media Upload To Locations",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Google My Business: Post To Locations",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Google My Business: Reply To Reviews",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Google My Business: RSS Auto Posting",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Inbox Conversation Manager",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Instagram Auto Comment Reply Enable Post",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Instagram Bot month",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Instagram Posting : Image/Video Post",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Instagram Reply Enhancers - Campaigns",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot Unlimited",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Connectivity : JSON API",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Connectivity : Webview Builder",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Email Auto Responder",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Enhancers : Broadcast : Subscriber Bulk Message Send",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Enhancers : Engagement : Checkbox Plugin",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Enhancers : Engagement : Customer Chat Plugin",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Enhancers : Engagement : m.me Links",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Enhancers : Engagement : Send to Messenger",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Enhancers : Sequence Messaging : Message Send",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Enhancers : Sequence Messaging Campaign",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Persistent Menu",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot - Persistent Menu : Copyright Enabled",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger Bot : Export, Import & Tree View",
        "monthlyLimit": 50,
        "bulkLimit": 100
    },
    {
        "moduleName": "Messenger E-commerce",
        "monthlyLimit": 50,
        "bulkLimit": 100
    }
]



export default moduleName;
