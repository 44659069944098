import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
  Button,
  FormControl,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import backendUrl from "../../configmain";
import axios from "axios";
const timeZones = [
  { value: "None", label: "None" },
  { value: "Etc/GMT+1", label: "GMT +1:00" },
  { value: "Etc/GMT+2", label: "GMT +2:00" },
  { value: "Etc/GMT+3", label: "GMT +3:00" },
  { value: "Etc/GMT+4", label: "GMT +4:00" },
  { value: "Etc/GMT+5", label: "GMT +5:00" },
  { value: "Etc/GMT+6", label: "GMT +6:00" },
  { value: "Etc/GMT+7", label: "GMT +7:00" },
  { value: "Etc/GMT+8", label: "GMT +8:00" },
  { value: "Etc/GMT+9", label: "GMT +9:00" },
  { value: "Etc/GMT+10", label: "GMT +10:00" },
  { value: "Etc/GMT+11", label: "GMT +11:00" },
  { value: "Etc/GMT+12", label: "GMT +12:00" },
  { value: "Etc/GMT+13", label: "GMT +13:00" },
];

const EditProfile = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    message: "",
  });

  const userFields = [
    { label: "Name" },
    { label: "Email" },
    { label: "Address" },
    { label: "Role" },
  ];
  const [id, setId] = useState("");
  const [userData, setUserData] = useState("");
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    address: "",
    timezone: "",
  });
  const token = localStorage.getItem("authorization");
  let url = `${backendUrl}/api/user/`;

  //   console.log(id)

  const fetchUserData = () => {
    axios
      .get(`${url}/me`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setUserData(response.data);
        setId(response.data.data._id);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const openDialog = (title, message) => {
    setDialogContent({ title, message });
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const handleDialogClose = () => {
    fetchUserData();
    closeDialog();
  };

  useEffect(() => {
    axios
      .get(`${url}/me`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setUserData(response.data);
        setId(response.data.data._id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const handleSaveChanges = () => {
    axios
      .put(`${url}/update/${id}`, profileData, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          openDialog("Success", "User updated successfully!");
          fetchUserData(); // Fetch updated user data
          console.log("Profile updated successfully");
        } else {
          openDialog(
            "Error",
            "Error updating profile. Please try again later."
          );
          console.error("Error updating profile:", response.data);
        }
      })
      .catch((error) => {
        openDialog("Error", "Error updating profile. Please try again later.");
        console.error("Error updating profile:", error);
      });
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 4, p: 2 }}>
        <Grid item xs={6}>
          <Paper
            elevation={3}
            style={{ padding: "20px", position: "relative" }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Avatar
                alt="User Avatar"
                sx={{
                  width: 80,
                  height: 80,
                  position: "absolute",
                  top: "-50px",
                  left: "10%",
                  transform: "translateX(-50%)",
                  border: " 3px solid #279d85",
                }}
              />
            </Box>

            <Box mt={3}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {userFields.map((field) => (
                      <TableRow key={field.label}>
                        <TableCell
                          variant="head"
                          style={{ fontWeight: "bold" }}
                        >
                          {field.label}
                        </TableCell>
                        <TableCell>
                          {userData?.data?.[field.label.toLowerCase()]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Box display="flex" alignItems="center">
              <Typography
                variant="h6"
                sx={{
                  marginRight: "10px",
                  display: "flex",
                  gap: 1,
                  color: "#279d85",
                }}
              >
                <Icon color="primary">
                  <EditRoundedIcon style={{ color: "#279d85" }} />
                </Icon>
                Edit profile
              </Typography>
            </Box>
            <Box>
              <Box>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  margin="normal"
                  value={profileData.name}
                  onChange={(e) =>
                    setProfileData({ ...profileData, name: e.target.value })
                  }
                  className="focused"
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#279d85",
                      },
                  }}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "#279d85",
                      },
                    },
                  }}
                />

                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#279d85", // Change the border color when the input is focused
                      },
                  }}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "#279d85",
                      },
                    },
                  }}
                  value={profileData.email}
                  onChange={(e) =>
                    setProfileData({ ...profileData, email: e.target.value })
                  }
                />

                <TextField
                  fullWidth
                  label="Address"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#279d85", // Change the border color when the input is focused
                      },
                  }}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "#279d85",
                      },
                    },
                  }}
                  value={profileData.address}
                  onChange={(e) =>
                    setProfileData({ ...profileData, address: e.target.value })
                  }
                />

                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#279d85",
                      },
                  }}
                >
                  <InputLabel
                    sx={{
                      "&.Mui-focused": {
                        color: "#279d85", // Change the label color when focused
                      },
                    }}
                  >
                    Time Zone
                  </InputLabel>
                  <Select
                    label="Time Zone"
                    value={profileData.timezone}
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        timezone: e.target.value,
                      })
                    }
                  >
                    {timeZones.map((timezone) => (
                      <MenuItem key={timezone.value} value={timezone.value}>
                        {timezone.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleSaveChanges}
                style={{ background: "#279d85" }}
              >
                Save Changes
              </Button>
            </Box>
          </Paper>
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {dialogContent.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogContent.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
};

export default EditProfile;
