import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Input,
  ListItem,
  ListItemText,
  Modal,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Papa from "papaparse";
import axios from "axios";
import backendUrl from "../../../configmain.js";
import FileOpenIcon from "@mui/icons-material/FileOpen";

function ContactListItem(props) {
  const { contact, onDeleteSuccess } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [contactsData, setContactsData] = useState([]);

  console.log("contact", contact.contacts);

  useEffect(() => {
    setContactsData(contact.contacts);
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOpenAddModal = () => {
    setAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
  };

  const downloadSample = () => {
    const sampleData = "name,phone,param1,param2,param3\n";
    const blob = new Blob([sampleData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "sample.csv");
    document.body.appendChild(link);
    link.click();

    handleCloseAddModal();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        Papa.parse(file, {
          header: true,
          complete: (result) => {
            const headers = result.meta.fields;

            // Check if name is first and phone is second
            if (headers[0] !== "name" || headers[1] !== "phone") {
              setErrorDialogOpen(true);
              return;
            }

            const jsonData = result.data;
            axios
              .post(
                `${backendUrl}/api/contactGroup/addUser/${contact._id}`,
                jsonData,
                {
                  headers: {
                    Authorization: `${token}`,
                  },
                }
              )
              .then((response) => {
                console.log(response.data);
                handleCloseAddModal();
                setSnackbarOpen(true);
              })
              .catch((error) => {
                console.error(error);
              });
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  let token = localStorage.getItem("authorization");
  let headers = {
    Authorization: `${token}`,
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    handleCloseDialog();

    try {
      await axios.delete(`${backendUrl}/api/contactGroup/${contact._id}`, {
        headers,
      });

      if (onDeleteSuccess) {
        onDeleteSuccess(contact._id);
      }
    } catch (error) {
      console.error("Failed to delete contact:", error);
    }
  };

  const tableHeaderStyle = {
    border: "1px solid gray",
    padding: "8px",
    fontWeight: "bold",
    backgroundColor: "#222",
    color: "#fff",
  };

  const tableCellStyle = {
    border: "1px solid gray",
    padding: "5px",
  };

  const tableRowStyleEven = {
    backgroundColor: "#F0FCEE",
    color: "black",
  };

  const tableRowStyleOdd = {
    backgroundColor: "#ffffff",
    color: "black",
  };

  return (
    <Box>
      <ListItem
        className="px-32 py-16"
        sx={{ bgcolor: "background.paper" }}
        to={`/apps/contacts/${contact._id}`}
      >
        <GroupsOutlinedIcon style={{ color: "#279d85" }} />

        <ListItemText
          sx={{ ml: 5 }}
          classes={{ root: "m-0", primary: "font-medium leading-5 truncate" }}
          primary={contact.groupName}
        />

        <Tooltip title="Add Contacts Manually">
          <Button onClick={openModal}>
            <FileOpenIcon style={{ color: "#279d85" }} />
          </Button>
        </Tooltip>
        <Tooltip title="Upload Csv">
          <Button onClick={handleOpenAddModal}>
            <UploadFileOutlinedIcon style={{ color: "#222" }} />
          </Button>
        </Tooltip>

        {/* <Tooltip title="Edit Group">
          <Button>
            <FuseSvgIcon size={20}>heroicons-outline:pencil</FuseSvgIcon>
          </Button>
        </Tooltip> */}
        <Tooltip title="Delete Group">
          <Button onClick={handleOpenDialog}>
            <DeleteOutlinedIcon style={{ color: "#d32f2f" }} />
          </Button>
        </Tooltip>
      </ListItem>
      <Divider />

      <Modal
        open={addModalOpen}
        onClose={handleCloseAddModal}
        aria-labelledby="add-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <Typography id="add-modal-title" variant="h6" component="h2">
            Upload
          </Typography>

          <Box mt={2}>
            <Button onClick={downloadSample}>Download sample file</Button>
          </Box>

          <Box mt={2}>
            <Button component="label">
              Upload CSV
              <Input
                type="file"
                accept=".csv"
                hidden
                onChange={handleFileChange}
                sx={{ display: "none" }}
              />
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete confirmation dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Users Details</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this contact?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Invalid Excel format! Please download the sample file to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialogOpen(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
        >
          Contacts added successfully!
        </Alert>
      </Snackbar>

      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        aaria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm" // Set the desired width, e.g., "md", "lg", "xl", or a number (e.g., 600)
        fullWidth
        maxHeight="md"
      >
        <DialogTitle id="alert-dialog-title">{contact.groupName}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <table
                style={{
                  width: "100%",
                  border: "1px solid black",
                  borderCollapse: "collapse",
                }}
              >
                <thead style={{ border: "1px solid black" }}>
                  <tr>
                    <th style={tableHeaderStyle}>ID</th>
                    <th style={tableHeaderStyle}>Name</th>
                    <th style={tableHeaderStyle}>Number</th>
                    <th style={tableHeaderStyle}>Param1</th>
                    <th style={tableHeaderStyle}>Param2</th>
                    <th style={tableHeaderStyle}>Param3</th>
                  </tr>
                </thead>
                <tbody>
                  {contactsData.map((row, index) => (
                    <tr
                      key={row.id}
                      style={
                        index % 2 === 0 ? tableRowStyleEven : tableRowStyleOdd
                      }
                    >
                      <td align="center" style={tableCellStyle}>
                        {index + 1}
                      </td>
                      <td align="center" style={tableCellStyle}>
                        {row.name}
                      </td>
                      <td align="center" style={tableCellStyle}>
                        {row.phone}
                      </td>
                      <td align="center" style={tableCellStyle}>
                        {row.param1}
                      </td>
                      <td align="center" style={tableCellStyle}>
                        {row.param2}
                      </td>
                      <td align="center" style={tableCellStyle}>
                        {row.param3}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ContactListItem;
