import React, { useState } from 'react';
import {
    Paper,
    Typography,
    TextField,
    Button,
    Box,

} from '@mui/material';
// import { styled } from "@mui/material/styles";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';

import { Grid, } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SyncIcon from '@mui/icons-material/Sync';
import DisableIcon from '@mui/icons-material/Block';
import ManageIcon from '@mui/icons-material/Settings';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Link } from 'react-router-dom';
import DisconnectIcon from '@mui/icons-material/LinkOff';
import backendUrl from '../../../configmain';
import EnableIcon from '@mui/icons-material/CheckCircle';
import toast, { Toaster } from 'react-hot-toast';


export default function BusinessWhatsApp() {
    const [businessAccount, setbusinessAccount] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showResponseBox, setShowResponseBox] = useState(false);
    const [isDisconnectDialogOpen, setIsDisconnectDialogOpen] = useState(false);
    const [selectedBusinessAccount, setSelectedBusinessAccount] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [responseData, setResponseData] = useState([]);
    const handleBuisnessAccount = (e) => {
        setbusinessAccount(e.target.value);
    };
    const handleDisconnect = (businessAccountId) => {
        setSelectedBusinessAccount(businessAccountId);
        setIsDisconnectDialogOpen(true);
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbarOpen(false);
    };
    const handleSync = async () => {
        try {
            const response = await axios.put(`${backendUrl}/api/user/refresh`, {}, { headers });
            if (response.status === 200) {
                setSnackbarMessage('Sync successful');
                setSnackbarSeverity('success');
                setIsSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error syncing account:", error);
            alert("Failed to sync the account. Please try again.");
        }
    };
    const handleDisableConfirm = async () => {
        try {
            const response = await axios.put(`${backendUrl}/api/user/disable`, {
                disable: !isDisabled
            }, { headers });

            if (response) {
                setIsDisabled(prevState => !prevState);
                setIsDisableDialogOpen(false);
            }

        } catch (error) {
            console.error("Error updating disable status:", error);
            alert(`Failed to ${isDisabled ? 'enable' : 'disable'} the account. Please try again.`);
        }
    };
    const handleAccessToken = (e) => {
        setAccessToken(e.target.value);
    };

    let token = localStorage.getItem('authorization');
    let headers = {
        'Authorization': `${token}`,
    };
    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${backendUrl}/api/whatsapp/login`, {
                wabaId: businessAccount,
                accessToken: accessToken
            },
                { headers }
            );
            if (response.status === 200) {
                setResponseData(response.data.data);
                setIsDialogOpen(false);
                setShowResponseBox(true);
            }
        } catch (error) {
            console.log('dvsdvsv',error)
            if( error.response.data.error.status>=400 &&  error.response.data.error.status < 500){
                 console.log("dvdvdvsvw",error.response.data) 
                 toast.error(error.response.data.error.message)
            }else if (error.response.status == 500){
                toast.error("Internal Server Error")

            }
        }
    };
    const handleDisconnectConfirm = async () => {
        try {
            await axios.delete(`${backendUrl}/api/user/disconnect/${selectedBusinessAccount}`, { headers });
            setIsDisconnectDialogOpen(false);
            window.location.reload()

        } catch (error) {
            console.error("Error disconnecting account:", error);
            alert("Failed to disconnect the account. Please try again.");
        }
    };
    const handleCloseDisconnectDialog = () => {
        setIsDisconnectDialogOpen(false);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };
    return (
        <>
            <Box >
                <Toaster/>
                <Box sx={{ p: 2 }}>
                    <Paper elevation={3} style={{ padding: "20px", borderRadius: '8px', }}>
                        <Box sx={{ m: 2, textAlign: 'center', justifyContent: "center" }}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
                                Connect Business WhatsApp
                            </Typography>
                        </Box>
                        <Typography sx={{ color: '#25d366', fontSize: "20px", fontWeight: "bold" }}> WhatsApp</Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group">
                                <FormControlLabel
                                    color='#25d366'
                                    checked
                                    value="disabled"
                                    disabled
                                    control={< Radio />}
                                    label={<span style={{ fontSize: "18px" }}> On click account connection  </span>}
                                />
                            </RadioGroup>
                        </FormControl>
                        <Box sx={{ p: 3, display: "flex", gap: 3, width: "100%", }}>
                            <Box display="flex" alignItems="center" justifyContent="center" >
                                <WhatsAppIcon style={{ fontSize: '100px', color: '#25d366' }} />
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: "black" }}>Business Account ID + Access Token </Typography>
                                <TextField
                                    label="WhatsApp business account "
                                    variant="outlined"
                                    fullWidth
                                    value={businessAccount}
                                    onChange={handleBuisnessAccount}
                                    margin="normal"
                                />
                                <TextField
                                    label="Access token "
                                    variant="outlined"
                                    fullWidth
                                    value={accessToken}
                                    onChange={handleAccessToken}
                                    margin="normal"
                                />
                            </Box>
                        </Box>
                        <Grid container >
                            <Grid item xs={12} sm={6} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                <Box >
                                    <Box sx={{ mt: 2 }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>Webhook Callback URL</Typography>

                                        <Link to="https://ab.teleobi.com/webhook" target="_blank">
                                            https://ab.teleobi.com/webhook
                                        </Link>
                                    </Box>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>Privacy Policy URL</Typography>
                                        <Link to="https://new.teleobi.com/privacyPolicy" target="_blank">https://new.teleobi.com/privacyPolicy</Link>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                <Box >
                                    <Box sx={{ mt: 2 }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>Verification Token</Typography>
                                        <Link >wtf.digital</Link>
                                  
                                    </Box>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>Terms of Service URL</Typography>
                                        <Link to="https://new.teleobi.com/termsCondition">https://new.teleobi.com/termsCondition</Link>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 4, textAlign: 'center', alignItem: "center", justifyContent: 'center' }}>
                            <Button
                                onClick={handleSubmit}
                                type="submit"
                                variant="contained"
                                style={{
                                    borderRadius: '5px',
                                    backgroundColor: '#25d366',
                                    color: '#fff',
                                    transition: 'background-color 0.3s, color 0.3s',
                                    '&:hover': {
                                        backgroundColor: 'black',
                                        color: 'black',
                                    },
                                }}>
                                <IconButton size="small" color="inherit">
                                    <LinkIcon />
                                </IconButton>
                                Connect
                            </Button>
                        </Box>
                    </Paper>
                </Box>
                {showResponseBox && (
                    <Box p={2} display="flex" justifyContent="center" alignItems="center">
                        <Paper elevation={3} style={{ padding: '40px', borderRadius: '8px', width: "100%" }}>
                            {responseData.map((dataItem, index) => (
                                <React.Fragment key={index}>
                                    <Typography sx={{ color: '#25d366', fontSize: "20px", fontWeight: "bold" }}>{dataItem.verified_name
                                    }</Typography>
                                    <Box sx={{ display: "flex", mt: 2, marginBottom: 2 }}>
                                        <Button variant="contained" startIcon={<SyncIcon sx={{ fontSize: 16 }} />} sx={{ margin: '0 4px', backgroundColor: "gray" }} onClick={() => handleSync()}>
                                            Sync
                                        </Button>
                                        <Button variant="contained" color='warning' startIcon={
                                            isDisabled ?
                                                <EnableIcon sx={{ fontSize: 16 }} /> :
                                                <DisableIcon sx={{ fontSize: 16 }} />
                                        }
                                            sx={{ margin: '0 12px', backgroundColor: isDisabled ? 'green' : 'warning' }}
                                            onClick={() => setIsDisableDialogOpen(true)}>
                                            {isDisabled ? 'Enable' : 'Disable'}
                                        </Button>
                                        <Button variant="contained" startIcon={<ManageIcon sx={{ fontSize: 16 }} />} sx={{ margin: '0 12px' }}>
                                            Manage
                                        </Button>
                                        <Button variant="contained" color="error" startIcon={<DisconnectIcon sx={{ fontSize: 16 }} />} sx={{ margin: '0 12px' }} onClick={() => handleDisconnect(dataItem._id)}>
                                            Disconnect
                                        </Button>
                                    </Box>

                                    <Box sx={{ display: "flex", backgroundColor: '#f1f5f9' }}>
                                        <Box sx={{ ml: 1, width: "50%", mt: 2, }}>
                                            <Typography  >Business Account ID :</Typography>
                                        </Box>
                                        <Box sx={{ width: "50%", textAlign: "center", ml: 1, mt: 2 }}>
                                            <Typography>
                                                {dataItem.wabaId}
                                            </Typography>
                                        </Box>

                                    </Box>

                                    <Box sx={{ display: "flex", backgroundColor: '#f1f5f9' }}>
                                        <Box sx={{ ml: 1, width: "50%", }}>
                                            <Typography >Number :</Typography>
                                        </Box>
                                        <Box sx={{ width: "50%", textAlign: "center", ml: 1 }}>
                                            <Typography >{dataItem.display_phone_number
                                            }</Typography>
                                        </Box>

                                    </Box>
                                    <Box sx={{ display: "flex", backgroundColor: '#f1f5f9' }}>
                                        <Box sx={{ ml: 1, width: "50%", }}>
                                            <Typography >NumberId :</Typography>
                                        </Box>
                                        <Box sx={{ width: "50%", textAlign: "center", ml: 1 }}>
                                            <Typography >{dataItem.phoneNumberId}</Typography>
                                        </Box>

                                    </Box>
                                    <Box sx={{ display: "flex", backgroundColor: '#f1f5f9' }}>
                                        <Box sx={{ ml: 1, width: "50%", }}>
                                            <Typography >Quality :</Typography>
                                        </Box>
                                        <Box sx={{ width: "50%", textAlign: "center", justifyContent: "center", display: 'flex' }}>
                                            <Box
                                                sx={{
                                                    marginTop: "8px",
                                                    width: '12px',
                                                    height: '12px',
                                                    borderRadius: '50%',
                                                    backgroundColor:
                                                        dataItem.quality_rating === 'GREEN' ? 'GREEN' :
                                                            dataItem.quality_rating === 'YELLOW' ? 'YELLOW' :
                                                                dataItem.quality_rating === 'RED' ? 'RED' : 'transparent',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            ></Box>

                                        </Box>

                                    </Box>
                                    <Box sx={{ display: "flex", backgroundColor: '#f1f5f9' }}>
                                        <Box sx={{ ml: 1, width: "50%", marginBottom: 2 }}>
                                            <Typography >Status :</Typography>
                                        </Box>
                                        <Box sx={{ width: "50%", textAlign: "center", ml: 1, marginBottom: 2 }}>
                                            <Typography >Approved</Typography>
                                        </Box>

                                    </Box>
                                </React.Fragment>
                            ))}
                        </Paper>
                    </Box>
                )}
                <Dialog
                    open={isDisconnectDialogOpen}
                    onClose={handleCloseDisconnectDialog}
                >
                    <DialogTitle>Confirm Disconnect</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to disconnect this account?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDisconnectDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDisconnectConfirm} color="primary">
                            Disconnect
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isDialogOpen}
                    onClose={handleCloseDialog}>
                    <DialogTitle>Success</DialogTitle>
                    <DialogContent>
                        Connected Successfully!
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={isDisableDialogOpen}
                    onClose={() => setIsDisableDialogOpen(false)}
                >
                    <DialogTitle>{isDisabled ? 'Enable Account' : 'Disable Account'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to {isDisabled ? 'enable' : 'disable'} this account?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDisableDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDisableConfirm} color="primary">
                            {isDisabled ? 'Enable' : 'Disable'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'down', horizontal: 'right' }}
                // className={classes.customSnackbar}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity={snackbarSeverity}
                        onClose={handleCloseSnackbar}
                    >
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </Box >
        </>
    )
}