import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { AccountCircle, InsertEmoticon, MoreHoriz, Send, VideoCall } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { faker } from '@faker-js/faker'
import { connect } from 'react-redux';
import p2 from "../../../assets/p2.jpg"
import { toggleSidebar, setTypeContact } from '../../../redux/slices/app';
import NewSharedMssages from './NewSharedMssages';
import NewContact from './NewContact';
import NewStarredMessages from './NewStarredMessages';
const NewProfileHeader = ({ isSidebarOpen, toggleSidebar, type }) => {
    const handleToggleSidebar = () => {
        console.log("Before Toggling: isSidebarOpen", isSidebarOpen);
        toggleSidebar();
        console.log("After Toggling: isSidebarOpen", !isSidebarOpen);
    };
    return (
        <>
            <Box >
                <Stack direction="row" sx={{ justifyContent: 'space-between', cursor: "pointer" }}
                    onClick={handleToggleSidebar}
                >
                    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}
                    >
                        <Avatar src={p2} alt={faker.name.firstName()} sx={{ height: 64, width: 64 }} />
                        <Box>
                            <Typography variant="h6">Brandi Little</Typography>
                            <Typography variant="span">Last seen 2h ago</Typography>
                        </Box>
                    </Stack>
                </Stack>
                <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
        </>
    )
}
const mapStateToProps = (state) => ({
    isSidebarOpen: state.sidebar.isSidebarOpen,
    type: state.sidebar.type,
});
const mapDispatchToProps = {
    toggleSidebar,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewProfileHeader)
