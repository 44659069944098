import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
// import { makeStyles } from '@mui/styles';
import Active from "./Active";
import Deleted from "./Deleted";
import  TemplateLibrary  from "./TemplateLibrary";


import TemplatesHeader from "./TemplateHeader";
// import TemplatesHeader from "./TemplatesHeader";

function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;
  // console.log(props)
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const TemplateMain = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
   setValue(newValue);
  };


  const handleMouseOver = (event) => {
    event.currentTarget.style.backgroundColor = 'transparent';
  };

  const handleMouseOut = (event) => {
    event.currentTarget.style.backgroundColor = '';
  };
  return (
    <>

      <Box p={2}>
      <TemplatesHeader/>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {backgroundColor: '#279d85'},
              }}
             
            >
              
              <Tab label="Active"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
               style={{
                fontWeight: value === 0 ? 'bold' : 'normal',
                color: value === 0 ? '#279d85' : '',

              }} />
              <Tab label="Deleted"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
               style={{
                fontWeight: value === 1 ? 'bold' : 'normal',
                color: value === 1 ? '#279d85' : '',

              }} />
              <Tab label="Template library"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
               style={{
                fontWeight: value === 2 ? 'bold' : 'normal',
                color: value === 2 ? '#279d85' : '',
      
               

              }} />
            </Tabs>
          </Box>
          {value === 0 && (
            <Active />
          )}
          {value === 1 && (
           <Deleted /> 
          )}
        </Box>
      </Box>

    </>
  );
};

export default TemplateMain;