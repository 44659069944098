import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, MenuItem, Table, TableBody, TableCell, TablePagination, TableRow, TextField, Typography, Switch, Select, Checkbox, Fab } from "@mui/material";
import { styled, ThemeProvider } from "@mui/material/styles";
import React, { useState } from 'react';
import moduleName from './moduleData';
import TelegramIcon from "@mui/icons-material/Telegram";
import axios from "axios";
import backendUrl from '../../configmain';
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { createTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

const theme = createTheme();

const HeaderStyles = styled(({ theme }) => ({
  fab: {
    margin: theme.spacing(2),
    marginLeft: "2px",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export default function PackageMain() {
  // const classes = useStyles();
  const [page, setPage] = useState(0);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [usageLimits, setUsageLimits] = useState({});
  const [formState, setFormState] = useState({
    packageName: '',
    price: '',
    validity: '',
    days: 'Day',
    isAvailable: false,
    isHighlighted: false,
    modules: [],
  });

  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);
    window.location.reload()
  };

  const handleCheckboxChange = (event, index) => {
    const updatedModules = [...formState.modules];

    if (event.target.checked) {
      updatedModules.push(index);
    } else {
      const indexToRemove = updatedModules.indexOf(index);
      if (indexToRemove !== -1) {
        updatedModules.splice(indexToRemove, 1);
      }
    }

    setFormState({
      ...formState,
      modules: updatedModules,
    });
  };



  const token = localStorage.getItem('authorization');
  const handleSubmit = (event) => {
    event.preventDefault();
    const selectedModuleData = formState.modules.map(index => ({
      ...moduleName[index],
      usageLimit: usageLimits[index] || 0
    }));

    // Prepare data for POST request
    const postData = {
      ...formState,
      modules: selectedModuleData,
    };
    const headers = {
      'Authorization': `${token}`,
      'Content-Type': 'application/json',
    };
    // Make the POST request using Axios
    axios.post(`${backendUrl}/api/package/`, postData, {
      headers
    })
      .then(() => {
        setSuccessDialogOpen(true);

      })
      .catch((error) => {
        console.error('Error:', error);
      });

    // Reset the form after submission
    setFormState({
      packageName: '',
      price: '',
      validity: '',
      days: 'Day',
      isAvailable: false,
      isHighlighted: false,
      modules: [],
    });
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    if (name.startsWith("usageLimit-")) {
      const index = parseInt(name.split("-")[1], 10);
      setUsageLimits(prev => ({ ...prev, [index]: newValue }));
    } else {
      setFormState((prevState) => ({ ...prevState, [name]: newValue }));
    }
  };
  return (<Box sx={{ display: 'flex', m: 2 }}>
    <Box sx={{ flex: 1, p: 2 }}>
      <form className="px-20 mt-4" onSubmit={handleSubmit}>
          <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: '2rem'}}>
            <ThemeProvider theme={theme} className={HeaderStyles.fab}>
              <Fab size="small" style={{backgroundColor: '#279d85', color: '#fff', '&:hover': {backgroundColor: '#17C1E8'}}} aria-label="add">
                <AddIcon />
              </Fab>
              <Typography variant="h6" className="font-bold tracking-tight">
                Add Package
              </Typography>
            </ThemeProvider>
          </Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" style={{ width: '100%', height: '50px' }}>
              <Typography>Package name</Typography>
              <TextField
                id="packageName"
                variant="outlined"
                style={{ width: '100%', marginTop: '2%' }}
                name="packageName"
                value={formState.packageName}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#279d85",
                    },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" style={{ width: '100%', height: '50px' }}>
              <Typography>Price</Typography>
              <TextField
                id="price"
                variant="outlined"
                style={{ width: '100%', marginTop: '2%' }}
                name="price"
                value={formState.price}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#279d85",
                    },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" style={{ marginTop: '5%' }}>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <Typography>Validity</Typography>
              <TextField
                id="validity"
                variant="outlined"
                style={{ width: '100%', marginTop: '2%' }}
                name="validity"
                value={formState.validity}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#279d85",
                    },
                }}  
              />
              <FormControlLabel className="mt-4" control={<Switch name="isAvailable" checked={formState.isAvailable} onChange={handleChange} />} label="Available to purchase" />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl sx={{
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: "#279d85",
                                        },
                                    }}
 variant="outlined" style={{ width: '100%' }}>
              <Typography>Days</Typography>
              <Select labelId="select-label-1" id="select-1" style={{ width: '100%',marginTop: '2%' }} name="days" value={formState.days} onChange={handleChange}>
                <MenuItem value="Day">Day</MenuItem>
                <MenuItem value="Week">Week</MenuItem>
                <MenuItem value="Month">Month</MenuItem>
                <MenuItem value="Year">Year</MenuItem>
              </Select>
              <FormControlLabel className="mt-4" control={<Switch name="isHighlighted" checked={formState.isHighlighted} onChange={handleChange} />} label="Highlighted package" />
            </FormControl>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px', backgroundColor: 'black' }} />
        <Typography variant="h6" className="font-bold tracking-tight">
          Modules
        </Typography>
        <Table sx={{ textAlign: "center", justifyContent: "center", }} stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
          <TableBody>
            <TableRow className="h-72 cursor-pointer" hover tabIndex={-1} style={{ backgroundColor: 'rgb(238, 238, 238)' }}>
              <TableCell className="p-4 sm:p-2 md:p-4 lg:p-8 flex-grow" component="th" scope="row" align="left">
                <Typography variant="body1" fontWeight="bold">Select</Typography>
              </TableCell>
              <TableCell className="p-4 sm:p-2 md:p-4 lg:p-8 flex-grow" component="th" scope="row" align="left">
                <Typography variant="body1" fontWeight="bold">Modules</Typography>
              </TableCell>
              <TableCell className="p-4 sm:p-2 md:p-4 lg:p-8 flex-grow" component="th" scope="row">
                <Typography variant="body1" fontWeight="bold">Usage limit</Typography>
              </TableCell>
              <TableCell className="p-4 sm:p-2 md:p-4 lg:p-8 flex-grow" component="th" scope="row" align="center">
                <Typography variant="body1" fontWeight="bold">Bulk limit</Typography>
              </TableCell>
            </TableRow>
            {moduleName.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((module, index) => (
              <TableRow key={index} className="h-72 cursor-pointer" hover tabIndex={-1}>
                <TableCell className="p-4 sm:p-2 md:p-4 lg:p-8 flex-grow" component="th" scope="row">
                  <Checkbox
                    checked={formState.modules.includes(index)}
                    onChange={(event) => handleCheckboxChange(event, index)}
                  />
                </TableCell>
                <TableCell className="p-4 sm:p-2 md:p-4 lg:p-8 flex-grow" component="th" scope="row">
                  {module.moduleName}
                </TableCell>
                <TableCell className="p-4 sm:p-2 md:p-4 lg:p-8 flex-grow" component="th" scope="row">
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    style={{ width: '70px' }}
                    name={`usageLimit-${index}`}
                    value={usageLimits[index] || ''}
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell className="p-4 sm:p-2 md:p-4 lg:p-8 flex-grow" component="th" scope="row">
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    style={{ width: '70px' }}
                    value={module.bulkLimit}

                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[20, 30, 50]}
          component="div"
          count={moduleName.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <div className="mt-4" style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>

          <Button
            // sx={{ backgroundColor: '#17C1E8', '&:hover': {backgroundColor: '#17C1E8'} }}
            // startIcon={<TelegramIcon />}
            variant="outlined"
            color="success"
            onClick={handleSubmit}
          >

            save
          </Button>
          <Button
            variant="outlined"
            color="error"
            // sx={{ backgroundColor: '#17C1E8', '&:hover': {backgroundColor: '#17C1E8'} }}
            onClick={handleCancelClick}
          >
            <CancelIcon  style={{ marginRight:"7px", fontSize:'20px'}}/>
            Cancel
          </Button>
        </div>

      </form>
    </Box>

    <Dialog open={successDialogOpen} onClose={closeSuccessDialog} aria-labelledby="success-dialog-title">
      <DialogTitle id="success-dialog-title">Success</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Package Created Successfully
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeSuccessDialog} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>

  </Box>)
}