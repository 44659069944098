import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'; 
import './App.css';
import SignUp from './Auth/SignUp/SignUp';
import LogIn from './Auth/LogIn/LogIn';
import SideNav from './component/Sidebar/SideNav';
import ResetPassword from './Auth/ResetPassword/ResetPassword';
import PrivacyPolicy from './component/privacyPolicy/PrivacyPolicy';
import Terms from './component/privacyPolicy/Terms';
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('authorization'));
  const onLogin = () => {
    setIsLoggedIn(true);
  };
  useEffect(() => {
    // Whenever local storage changes, update the state
    const handleStorageChange = () => {
      setIsLoggedIn(!!localStorage.getItem('authorization'));
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <BrowserRouter>
      {isLoggedIn ? (
        <>
          <SideNav />
        </>
      ) : (
        <Routes>
          <Route path="/" element={<LogIn onLogin={onLogin} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termsCondition" element={<Terms />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}
export default App;
