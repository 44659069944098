import React, { useState } from 'react';
import { Box, Container, Typography, Grid, Paper, Card, CardContent, } from '@mui/material';
import { styled } from "@mui/material/styles";
import axios from 'axios';
import { Button } from '@mui/material';
import FacebookLogin from "@greatsumini/react-facebook-login";
import facebook from '../../../assets/facebook.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { FacebookLoginButton } from "react-social-login-buttons";
import FacebookIcon from '@mui/icons-material/Facebook';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
// import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import backendUrl from '../../../../src/configmain';


const token = localStorage.getItem('authorization');

const useStyles = styled((theme) => ({
  paper: {
    // background: "#111827",
    // color: "#fff",
    // padding: theme.spacing(2),
    padding: "15px",
    border: '1px solid #D3D3D3',
    display: 'flex',
    textAlign: "center",
    alignItems: "center",
    // transition: 'background 0.3s, border-color 0.3s, color 0.3s',
    '&:hover': {
      borderColor: '#00a68b',
      color: "black",

    },
  },
  boldTypography: {
    fontWeight: 400,
    color: "black",
    fontSize: "18px"
  },
  normalTypography: {
    fontSize: "14px",
    color: '#666666'
    //  color: "#00a68b",

  },
  cardstyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
}));
const firstGridPapers = [
  {
    title: 'Automate WhatsApp replies',
    description: 'Clear common queries with zero effort ',
  },
  {
    title: 'Automate WhatsApp catalog browsing',
    description: 'Convert visitors into prospective buyers',
  },
  {
    title: 'Automate WhatsApp checkout',
    description: 'Turn prospective buyers into paid customers',
  },
  {
    title: 'Automate WhatsApp checkout',
    description: 'Turn prospective buyers into paid customers',
  },
  {
    title: 'Automate WhatsApp checkout',
    description: 'Turn prospective buyers into paid customers',
  },
  {
    title: 'Automate WhatsApp checkout',
    description: 'Turn prospective buyers into paid customers',
  },
];

export default function Onboarding({ onFacebookLogin }) {
  const classes = useStyles();

  const [message, setMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sessionData, setSessionData] = useState({});

  const handlePaperClick = (content) => {
    setMessage(content);
  };

  const sessionInfoListener = (event) => {
    console.log(event)
    if (event.origin !== "https://www.facebook.com") return;
    try {
      const data = JSON.parse(event.data);
      console.log(data)
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        if (data.event === 'FINISH') {
          const { phoneID, wabaID } = data.data;
          setSessionData({ phoneID, wabaID });
        } else {
          const { currentStep } = data.data;
          setSessionData({ currentStep });
        }
      }
    } catch {
      console.log('Non JSON Response', event.data);
    }
  };
  useEffect(() => {
    // Load the Facebook JavaScript SDK
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: '734093828311975', // Replace with your Facebook App ID
            autoLogAppEvents: true,
            cookie:   true, 
            xfbml: true,
            version: 'v18.0',
        });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    window.addEventListener('message', sessionInfoListener);

    return () => {
      // Cleanup the listener on component unmount
      window.removeEventListener('message', sessionInfoListener);
    }
}, []);

console.log("Session Data:", sessionData);

  const handleFacebookLogin = () => {
    
    window.FB.login(function (response) {
        if (response.authResponse) {
            const accessToken = response.authResponse;
            console.log(accessToken)
            handleLoginSuccess({
                accessToken,
            });
        } else {
            console.log('User cancelled login or did not fully authorize.');
        }
    }, {
        config_id: '3115472375251499', 
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          sessionInfoVersion: 2,  
        }
    });
};


  
  


const handleLoginSuccess = async (response) => {
  try {
    console.log(response)
    console.log("Session Data:", sessionData); 
    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    };

    const code = response.accessToken.code
    console.log(code)
    const facebookLoginUrl = `${backendUrl}/api/auth/embeded`;

    // Step 1: Perform Facebook login
    const loginResponse = await axios.post(
      facebookLoginUrl,
      {
        accessToken: code,
      },
      {
        headers,
      }
    );

    if (loginResponse) {
      const userData = loginResponse.data;
      console.log(userData)
      setIsLoggedIn(true);

      // Step 2: Use the access token to initiate WhatsApp Login Configuration
      const whatsappConfigUrl = `${backendUrl}/api/whatsapp/login`;
      const whatsappConfigResponse = await axios.post(
        whatsappConfigUrl,
        {
          facebookAccessToken: response.accessToken,
          // Add other WhatsApp Login Configuration parameters here
        },
        {
          headers,
        }
      );

      if (loginResponse) {
        // const userData = loginResponse.data;
        setIsLoggedIn(true);
        // setData(userData);
        window.location.reload();
      } else {
        // Handle WhatsApp Login Configuration error
        console.log("WhatsApp Login Configuration Error");
      }
      
      window.location.reload();
    } else {
      // Handle Facebook login error
      console.log("Facebook Login Error");
    }
  } catch (error) {
    if (error.response && (error.response.status === 500 || error.response.status === 400)) {
      console.log("Error:", error);
    }
  }
};


  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>


      <Grid spacing={5} container justify="center" alignItems="center">
        <Grid item xs={6} md={6}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <WhatsAppIcon style={{ fontSize: '60px', color: '#25d366' }} />
            <Box>
              <Typography variant="h6" fontWeight="bold">Whatsapp</Typography>
              <Typography sx={{ color: "464d6d", fontWeight: 700 }}>
                Explore wtf using +16086000059 as your demo WhatsApp Business number.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box display="flex" justifyContent="flex-end">
            {isLoggedIn ? (
              <Button
                variant="contained"
                color="secondary"
              >
                Unlink Facebook
              </Button>
            ) : (
            

              <Button
                variant="contained"               
                color="primary"
                onClick={handleFacebookLogin}
              >
                Log in with Facebook
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Paper elevation={0} sx={{ padding: 2, }}>
          <Typography variant="h6" fontWeight="bold">Explore features</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {firstGridPapers.map((paper, index) => (
                  <Paper
                    className={classes.paper}
                    elevation={0}
                    key={index}
                    onClick={() => handlePaperClick(paper.description)}
                  >
                    <Box>
                      <Typography fontWeight="bold">{paper.title}</Typography>
                      <Typography className={classes.normalTypography}>{paper.description}</Typography>
                    </Box>
                    <Box sx={{ marginLeft: 'auto' }}>
                      <ArrowForwardIosIcon sx={{color:"#279d85"}} />
                    </Box>
                  </Paper>
                ))}
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography>{message}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box mt={2} mb={2}>
        <Paper elevation={0} sx={{
          padding: 2,
        }}>
          <Typography variant="h6" fontWeight="bold">Explore more exciting features!</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={3}>
              <Box >
                <Paper elevation={0} sx={{
                  padding: 2, borderRadius: 1, border: '1px solid #D3D3D3', cursor: 'pointer', '&:hover': {
                    borderColor: '#00a68b',
                    color: "black",

                  },
                }}>
                  <Box className={classes.cardstyle} >
                    <Box mr={2}>
                      {/* <FuseSvgIcon className="text-48" size={24} color="action">heroicons-solid:phone-missed-call</FuseSvgIcon> */}
                      <PhoneMissedIcon style={{ color: "#279d85" }} />
                    </Box>
                    <Box>
                      <Typography className={classes.boldTypography}>Add WhatsApp Contacts </Typography>
                      <Typography className={classes.normalTypography}>Manage contacts efficiently </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box >
                <Paper elevation={0} sx={{
                  padding: 2, borderRadius: 1, border: '1px solid #D3D3D3', cursor: 'pointer', '&:hover': {
                    borderColor: '#00a68b',
                    color: "black",
                  },
                }}
                >
                  <Box className={classes.cardstyle} >
                    <Box mr={2}>
                      {/* <FuseSvgIcon className="text-48" size={24} color="action">heroicons-solid:user-group</FuseSvgIcon> */}
                      <PeopleAltIcon style={{ color: "#279d85" }} />
                    </Box>
                    <Box>
                      <Typography className={classes.boldTypography}>Add Team Member</Typography>
                      <Typography className={classes.normalTypography}>Manage member permissions  </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box >
                <Paper elevation={0} sx={{
                  padding: 2, borderRadius: 1, border: '1px solid #D3D3D3', cursor: 'pointer', '&:hover': {
                    borderColor: '#00a68b',
                    color: "black",

                  },
                }}
                >
                  <Box className={classes.cardstyle}>
                    <Box mr={2}>
                      {/* <FuseSvgIcon className="text-48" size={24} color="action">heroicons-solid:arrows-expand</FuseSvgIcon> */}
                      < OpenWithIcon style={{ color: "#279d85" }} />
                    </Box>
                    <Box>

                      <Typography className={classes.boldTypography}>
                        Explore Integrations
                      </Typography>
                      <Typography className={classes.normalTypography}>Link GSheets, FB forms & more   </Typography>
                    </Box>
                  </Box>

                </Paper>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box >
                <Paper className={classes.bottomPaper} elevation={0} sx={{
                  height: "100%", width: "100%",
                  padding: 2, borderRadius: 1, border: '1px solid #D3D3D3', cursor: 'pointer', '&:hover': {
                    borderColor: '#00a68b',
                    color: "black",

                  },
                }}
                >
                  <Box className={classes.cardstyle}>
                    <Box mr={2}>
                      {/* <FuseSvgIcon className="text-48" size={24} color="action">heroicons-solid:finger-print</FuseSvgIcon> */}
                      < FingerprintIcon style={{ color: "#279d85" }} />
                    </Box>
                    <Box>
                      <Typography className={classes.boldTypography}>APIs Webhooks</Typography>
                      <Typography className={classes.normalTypography}>Create custom integrations   </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}
