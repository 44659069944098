import React from 'react'
import { Box, Icon, Paper, Stack, Typography } from '@mui/material';
import { AttachMoney, CurrencyPound , } from '@mui/icons-material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
export default function Analytics() {
  const chartData = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
 
  return (
    <>
      <Stack
      // direction="row"
      sx={{
        "& .MuiPaper-root": {
          flexGrow: 1,
          color: "#fff",
          padding: "1.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        display:"grid",
        gridTemplateColumns:{xs:"repeat(1,1fr)", md:"repeat(2,1fr)", lg:"repeat(4,1fr)"},
        gap:"10px"
      }}
    >
      <Paper
        sx={{
          backgroundColor: "#279d85",
        }}
      >
        <Box>
          <Typography variant="body2" component="p">
            Revenue
          </Typography>
          <Typography variant="h5" fontWeight="bold">
            42,562 +
          </Typography>
        </Box>
        <Icon sx={{ color: "inherit", transform: "scale(2)" }}>
          <FacebookIcon />
        </Icon>
      </Paper>
      <Paper
        sx={{
          backgroundColor: "#2196F3",
        }}
      >
        <Box>
          <Typography variant="body2" component="p">
            Orders Received
          </Typography>
          <Typography variant="h5" fontWeight="bold">
            486 +
          </Typography>
        </Box>
        <Icon sx={{ color: "inherit", transform: "scale(2)" }}>
          <TwitterIcon />
        </Icon>
      </Paper>
      <Paper
        sx={{
          backgroundColor: "#29314F",
        }}
      >
        <Box>
          <Typography variant="body2" component="p">
            Daily User
          </Typography>
          <Typography variant="h5" fontWeight="bold">
            1,650 +
          </Typography>
        </Box>
        <Icon sx={{ color: "inherit", transform: "scale(2)" }}>
          <LinkedInIcon />
        </Icon>
      </Paper>
      <Paper
        sx={{
          backgroundColor: "#F44336",
        }}
      >
        <Box>
          <Typography variant="body2" component="p">
            Daily Page View
          </Typography>
          <Typography variant="h5" fontWeight="bold">
            1K +
          </Typography>
        </Box>
        <Icon sx={{ color: "inherit", transform: "scale(2)" }}>
          <YouTubeIcon />
        </Icon>
      </Paper>
    </Stack>



      <Box  sx={{height: '300px', width:"auto", display:"grid",
      gridTemplateColumns:{xs:"repeat(1,1fr)", md:"repeat(1,1fr)", lg:"repeat(2,1fr)"}
      
    }}>
      <Box sx={{ flexGrow: 1 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={400}
            height={200}
            data={chartData}
            margin={{
              top: 50,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="pv"
              fill="#8884d8"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            <Bar
              dataKey="uv"
              fill="#82ca9d"
              activeBar={<Rectangle fill="gold" stroke="purple" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={chartData}
            margin={{
              top: 50,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#8884d8"
              fill="#8884d8"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
      </Box>
    </>
  );
}
