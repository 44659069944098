import { Avatar, Box, Button, Divider, IconButton, Stack, Switch, Typography } from '@mui/material'
import { Bell, CaretRight, Prohibit, Star, Trash, X } from 'phosphor-react'
import React, { useState } from 'react'
import p1 from "../../../assets/p1.jpg"
import p2 from "../../../assets/p2.jpg"
import p3 from '../../../assets/p3.avif'
import { connect } from 'react-redux';
import { toggleSidebar } from '../../../redux/slices/app';
import { useDispatch } from 'react-redux';
import { faker } from '@faker-js/faker'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { setTypeContact, setTypeShared, setTypeStar } from "../../../redux/slices/app"
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const BlockDialog = ({ open, handleClose }) => {
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Confirm block"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to block this contact?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Yes</Button>
                    <Button onClick={handleClose}>No</Button>
                </DialogActions>
            </Dialog>
        </>
    )

}
const DeleteDialog = ({ open, handleClose }) => {
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this contact?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Yes</Button>
                    <Button onClick={handleClose}>No</Button>
                </DialogActions>
            </Dialog>
        </>
    )

}
const NewContact = ({ isSidebarOpen, toggleSidebar }) => {
    const [openBlock, setOpenBlock] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const handleToggleSidebar = () => {
        toggleSidebar();
        // console.log("Before Toggling: isSidebarOpen", isSidebarOpen);
        // console.log("After Toggling: isSidebarOpen", !isSidebarOpen);
    };
    const dispatch = useDispatch();
    const setType = (type) => {
        switch (type) {
            case 'SHARED':
                dispatch(setTypeShared());
                break;
            case 'STARRED':
                dispatch(setTypeStar());
                break;
            default:
                break;
        }
    }

    const handleCloseBlock = () => {
        setOpenBlock(false)
    }

    const handleCloseDelete = () => {
        setOpenDelete(false)
    }

    return (
        <>
            <Box>
                <Stack sx={{ height: "100%",overflowY: "scroll",  }}>
                        <Stack width={"auto"} maxHeight={"100vh"} height={"100%"} direction={"row"} alignItems={"center"} justifyContent="space-between" spacing={3}>
                            <Typography variant='subtitle' sx={{ fontWeight: "bold", fontSize: "18px" }}>
                                Contact info
                            </Typography>
                            <IconButton onClick={handleToggleSidebar}>
                                <X />
                            </IconButton>
                        </Stack>
                    <Stack sx={{ overflow: "scroll", height: "100%", position: "relative", flexGrow: 1 }} mt={1} spacing={3}>
                        <Stack alignItems={"center"} direction={"row"} spacing={2}>
                            <Avatar src={p2} alt={faker.name.firstName()} sx={{ height: 64, width: 64 }} />
                            <Stack spacing={0.5} >
                                <Typography variant='article' fontWeight={600}> {faker.name.fullName()}</Typography>
                                <Typography variant='article' fontWeight={600}> {"9170412775"}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Divider sx={{ my: 2 }} />
                    <Stack>
                        <Typography >
                            About
                        </Typography>
                        <Typography sx={{ fontWeight: "bold" }}>
                            Hi there i am using teleobi
                        </Typography>
                    </Stack>
                    <Divider sx={{ my: 2 }} />
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                        <Typography variant='subtitle'>
                            Media Links & Docs
                        </Typography>
                        <Button endIcon={<CaretRight />} onClick={() => setType('SHARED')}>
                            401
                        </Button>
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems={"center"} sx={{ my: 2 }}>
                        <img src={p1} alt="" style={{ height: '60px', width: '60px' }} />
                        <img src={p2} alt="" style={{ height: '60px', width: '60px' }} />
                        <img src={p3} alt="" style={{ height: '60px', width: '60px' }} />
                    </Stack>
                    <Divider />
                    <Stack sx={{ my: 2 }} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                            <Star />
                            <Typography> Starred Messages</Typography>
                        </Stack>
                        <IconButton onClick={() => setType('STARRED')}>
                            <CaretRight />
                        </IconButton>
                    </Stack>
                    <Divider />
                    <Stack sx={{ my: 2 }} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                            <Bell />
                            <Typography>Mute notification</Typography>
                        </Stack>
                        <Switch defaultChecked />
                    </Stack>
                    <Divider />
                    <Stack sx={{ my: 2 }}>
                        <Typography> 1 group in common </Typography>
                        <Stack sx={{ my: 2 }} direction={"row"} alignItems={"center"} spacing={2}>
                            <Avatar src={p3} alt={faker.name.fullName()} />
                            <Stack spacing={0.5}>
                                <Typography sx={{ fontWeight: "bold" }}> Family group</Typography>
                                <Typography> You ,  Peter, john</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack sx={{ my: 2 }} direction={"row"} alignItems={"center"} spacing={2}>
                        <Button startIcon={<Prohibit />} fullWidth variant='outlined' onClick={() => setOpenBlock(true)}> Block</Button>
                        <Button startIcon={<Trash />} fullWidth variant='outlined' onClick={() => setOpenDelete(true)}> Delete</Button>
                    </Stack>
                </Stack>
            </Box>

            {openBlock && <BlockDialog open={openBlock} handleClose={handleCloseBlock} />}
            {openDelete && <DeleteDialog open={openDelete} handleClose={handleCloseDelete} />}
        </>
    )
}
const mapStateToProps = (state) => ({
    isSidebarOpen: state.sidebar.isSidebarOpen,
});
const mapDispatchToProps = {
    toggleSidebar,
}
export default connect(mapStateToProps, mapDispatchToProps)(NewContact)
