import React from 'react'
import ContactHeader from './ContactHeader'
import ContactList from './ContactList'

export default function ContactGroup() {
  return (
    <>
    <ContactHeader/>
    <ContactList/>
    </>
  )
}
