import { Box, Stack } from '@mui/material'
import React from 'react'
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {ChatList} from './newData/NewData'
import { styled } from '@mui/material/styles';
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
const NewUsers = () => {
  return (
    <>
      <Box sx={{}}>
        <Box sx={{mt: 2,}}>
          {
            ChatList.filter((el) => el.pinned).map((item, index) => {
              return (
                <Box key={index} >
                  <Stack sx={{ my: 2, cursor: "pointer" }} direction="row" justifyContent="space-between" >
                    <Stack direction="row" spacing={2}>
                      {
                        item.online ? <StyledBadge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          variant="dot"
                        >
                          <Avatar src={item.img} />
                        </StyledBadge> : <Avatar src={item.img} />
                      }
                      <Stack spacing={0.3}>
                        <Typography variant='subtitle2'>{item.name}</Typography>
                        <Typography variant='caption'>{item.msg}</Typography>
                      </Stack>
                    </Stack>
                    <Stack spacing={2} alignItems="center">
                      <Typography variant='caption' sx={{ fontWeight: 600 }} >{item.time}</Typography>
                      <Badge
                        badgeContent={item.unread}
                        sx={{
                          '& .MuiBadge-badge': {
                            backgroundColor: '#2CA58D',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }
                        }}
                      >
                      </Badge>
                    </Stack>
                  </Stack>
                  <Divider />
                </Box>
              )
            })
          }
        </Box>
        <Box sx={{ width: "100%", borderRadius: 1, mt: 2 }}>

          <Typography variant='subtitle2' sx={{}}>
            All
          </Typography>
          {
            ChatList.filter((el) => !el.pinned).map((item, index) => {
              return (
                <Box key={index}>
                  <Stack sx={{ my: 2, cursor: "pointer" }} direction="row" justifyContent="space-between" >
                    <Stack direction="row" spacing={2}>
                      {
                        item.online ? <StyledBadge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          variant="dot"
                        >
                          <Avatar src={item.img} />
                        </StyledBadge> : <Avatar src={item.img} />
                      }
                      <Stack spacing={0.3}>
                        <Typography variant='subtitle2'>{item.name}</Typography>
                        <Typography variant='caption'>{item.msg}</Typography>
                      </Stack>
                    </Stack>
                    <Stack spacing={2} alignItems="center">
                      <Typography variant='caption' sx={{ fontWeight: 600 }} >{item.time}</Typography>
                      <Badge
                        badgeContent={item.unread}
                        sx={{
                          '& .MuiBadge-badge': {
                            backgroundColor: '#2CA58D',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }
                        }}
                      >
                      </Badge>
                    </Stack>
                  </Stack>
                  <Divider />
                </Box>
              )
            })
          }
          {
            ChatList.filter((el) => !el.pinned).map((item, index) => {
              return (
                <Box key={index}>
                  <Stack sx={{ my: 2, cursor: "pointer" }} direction="row" justifyContent="space-between" >
                    <Stack direction="row" spacing={2}>
                      {
                        item.online ? <StyledBadge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          variant="dot"
                        >
                          <Avatar src={item.img} />
                        </StyledBadge> : <Avatar src={item.img} />
                      }
                      <Stack spacing={0.3}>
                        <Typography variant='subtitle2'>{item.name}</Typography>
                        <Typography variant='caption'>{item.msg}</Typography>
                      </Stack>
                    </Stack>
                    <Stack spacing={2} alignItems="center">
                      <Typography variant='caption' sx={{ fontWeight: 600 }} >{item.time}</Typography>
                      <Badge
                        badgeContent={item.unread}
                        sx={{
                          '& .MuiBadge-badge': {
                            backgroundColor: '#2CA58D',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }
                        }}
                      >
                      </Badge>
                    </Stack>
                  </Stack>
                  <Divider />
                </Box>
              )
            })
          }
        
        </Box>
      </Box>
    </>
  )
}
export default NewUsers
