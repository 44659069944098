import * as React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import { Box } from '@mui/system';
// import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
// import CachedSharpIcon from '@material-ui/icons/CachedSharp';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TextField, InputAdornment } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import backendUrl from '../../../configmain';

// const useStyles = styled((theme) => ({
//   button: {
//     borderRadius: "5px",
//     border: "1px solid black",
//     backgroundColor: "#111827",
//     color: "white",
//     padding: "6px 16px",
//     "& .MuiSvgIcon-root": {
//       marginRight: "8px",
//     },
//     "&:hover": {
//       backgroundColor: "transparent",
//       color: "black"
//     },
//   },
// }));

function Deleted(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('authorization');
    const queryParams = new URLSearchParams({ deleted: 'true' });

    if (templateName) {
      queryParams.append('templateName', templateName);
    }
    const url = `${backendUrl}/api/whatsapp/deleted`
    const requestUrl = `${url}?${queryParams.toString()}`;

    axios.get(requestUrl, {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then(response => {
        // console.log(response.data.data)
        const transformedData = response.data.data.map(item =>
          createData(item.name, item.createdBy, item.category, item.preview, item.language)
        );
        setTemplates(transformedData);
        setLoading(false); // Set loading to false when the data is fetched
        // console.log(transformedData);
      })
      .catch(error => {
        console.error('Error fetching deleted templates:', error);
        setLoading(false); // Set loading to false on error as well
      });
  }, [templateName]);

  function handleChangePage(event, value) {
    setPage(value);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  const columns = [
    { id: 'name', label: 'Name', align: 'center', minWidth: 170 },
    { id: 'createdby', label: 'Created By', align: 'center', minWidth: 100 },
    {
      id: 'category',
      label: 'Category',
      minWidth: 170,
      align: 'center',
    },
    {
      id: 'preview',
      label: 'Preview',
      minWidth: 170,
      align: 'center',
    },
    {
      id: 'language',
      label: 'Language',
      minWidth: 170,
      align: 'center',
    },
  ];

  function createData(name, createdby, category, preview, language) {
    return { name, createdby, category, preview, language };
  }

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
          <Box>
            <TextField
              size="small"
              id="search"
              placeholder="Search..."
              fullWidth
              value={templateName}
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#279d85",
                  },
              }}
              onChange={(e) => setTemplateName(e.target.value)}
              InputProps={{
                endAdornment: templateName ? (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setTemplateName('')}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Box>
          <IconButton
            size="small"
            onClick={() => {
              // Perform your sync/refresh action here
              // This could be another function or API call
            }}
          >
            {/* <CachedSharpIcon /> */}
          </IconButton>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden', mt: 3, border: '1px solid grey' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ color: "#fff", background: "#222" }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  // Render an empty row with loading spinner while data is loading
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : templates.length === 0 ? (
                  // Render the message when there is no data
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      <Box sx={{ p: 2 }}>
                        <Typography variant="body1" align="center">
                          No Templates found            
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  templates
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={templates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}

export default Deleted;
